import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor() {}

  private lastErrorMessage = '';

  getLastErrorMessage(): string {
    return this.lastErrorMessage;
  }

  setLastErrorMessage(message: string): void {
    this.lastErrorMessage = message;
  }

  resetLastErrorMessage(): void {
    this.lastErrorMessage = '';
  }
}

import { Directive, ElementRef, HostListener, Inject, Input, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { fromEvent } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { ScrollbarComponent } from './scrollbar.component';

export type TypedMouseEvent<T extends EventTarget> = MouseEvent & { target: T };

@Directive({
  selector: '[appDraggableScrollbar]'
})
export class DraggableScrollDirective {
  constructor(
    @Inject(ScrollbarComponent) private readonly scrollbar: ScrollbarComponent,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(ElementRef) private readonly element: ElementRef<HTMLElement>
  ) {}

  @Input() draggable: 'vertical' | 'horizontal' = 'vertical';

  @Output() dragged = fromEvent<TypedMouseEvent<HTMLElement>>(
    this.element.nativeElement,
    'mousedown'
  ).pipe(
    switchMap((event: MouseEvent) => {
      event.preventDefault();

      const clientRect: DOMRect = (event.target as HTMLElement).getBoundingClientRect();
      const offsetVertical: number = DraggableScrollDirective.getOffsetVertical(event, clientRect);
      const offsetHorizontal: number = DraggableScrollDirective.getOffsetHorizontal(
        event,
        clientRect
      );

      return fromEvent(this.document, 'mousemove').pipe(
        map((event: MouseEvent) => this.getScrolled(event, offsetVertical, offsetHorizontal)),
        takeUntil(fromEvent(this.document, 'mouseup'))
      );
    })
  );

  private getScrolled(
    { clientY, clientX }: MouseEvent,
    offsetVertical: number,
    offsetHorizontal: number
  ): number {
    const { offsetHeight, offsetWidth } = this.element.nativeElement;
    const { nativeElement } = this.scrollbar.element;
    const { top, left, width, height } = nativeElement.getBoundingClientRect();

    const maxTop = nativeElement.scrollHeight - height;
    const maxLeft = nativeElement.scrollWidth - width;
    const scrolledTop = (clientY - top - offsetHeight * offsetVertical) / (height - offsetHeight);
    const scrolledLeft = (clientX - left - offsetWidth * offsetHorizontal) / (width - offsetWidth);

    return this.draggable === 'vertical' ? maxTop * scrolledTop : maxLeft * scrolledLeft;
  }

  private static getOffsetVertical({ clientY }: MouseEvent, { top, height }: ClientRect): number {
    return (clientY - top) / height;
  }

  private static getOffsetHorizontal({ clientX }: MouseEvent, { left, width }: ClientRect): number {
    return (clientX - left) / width;
  }
}

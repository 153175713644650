<app-modal
  [info]="info"
  (closeModal)="closeModal($event)">
  <ng-template #titleContent>
    <h3 class="modal__title">
      <ng-container *ngIf="data.candidateCount > 0; else noCandidates">
        {{ 'FOLDERS.REMOVE_FOLDER_MODAL.TITLE_WITH_CANDIDATES' | translate: { name: data?.name, candidateCount: data.candidateCount, candidateAblative: data.candidateCount | currentNumeric : 'CANDIDATE_ABLATIVE' | translate } }}
      </ng-container>

      <ng-template #noCandidates>
        {{ 'FOLDERS.REMOVE_FOLDER_MODAL.TITLE' | translate: { name: data?.name } }}
      </ng-template>
    </h3>
  </ng-template>
</app-modal>

<div class="candidate-cv__sidebar">
  <div class="candidate-cv__sidebar-content">
    <div
      *ngIf="contactsInfoMap?.size"
      class="contacts">
      <h4 class="section-title">Contacts</h4>

      <div
        *ngFor="let infoItem of contactsInfoMap | keyvalue"
        class="contacts">
        <ng-container *ngIf="infoItem.value">
          <h6 class="section-sm-title contacts__title no-splittable-block">
            {{ infoItem.value.contactUniversalTitle | translate }}:
          </h6>

          <span
            *ngFor="let contact of infoItem.value.values"
            class="contacts__item no-splittable-block">
            {{ contact.value }}
          </span>
        </ng-container>
      </div>
    </div>

    <div *ngIf="links?.length" class="contacts long">
      <h4 class="section-title no-splittable-block">Links</h4>

      <a
        *ngFor="let link of links"
        class="link contacts__item no-splittable-block"
        [href]="link.value | encodeUrl"
        target="_blank">
        {{ link.value | decodeUrl }}
      </a>
    </div>
  </div>

  <div class="footer footer__sidebar">
  </div>
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  Account,
  Credit,
  CreditName,
  Payment,
  PaymentCard,
  PaymentInfo,
  TariffDto
} from '../../models';
import { RestService } from './rest.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  constructor(private restService: RestService) {}

  createPaymentForCardIntegration(): Observable<Payment> {
    return this.restService.Payment().createPaymentForCardIntegration();
  }

  makePaymentByOrgId(): Observable<null> {
    return this.restService.Payment().makePaymentByOrgId();
  }

  getAccount(): Observable<Account> {
    return this.restService.Payment().getAccount();
  }

  getCardList(): Observable<PaymentCard[]> {
    return this.restService.Payment().getCardList();
  }

  makeDefaultPaymentCard(id: number): Observable<PaymentCard[]> {
    return this.restService.Payment().makeDefaultPaymentCard(id);
  }

  deletePaymentCard(id: number): Observable<PaymentCard[]> {
    return this.restService.Payment().deletePaymentCard(id);
  }

  getPayments(page: number, size: number): Observable<PaymentInfo> {
    return this.restService.Payment().getPayments(page, size);
  }

  getAllCreditPacks(): Observable<Credit[]> {
    return this.restService.Payment().getAllCreditPacks();
  }

  buyPayments(name: CreditName): Observable<null> {
    return this.restService.Payment().buyCredits(name);
  }

  changeTariff(months: number, users: number): Observable<Account> {
    return this.restService.Payment().changeTariff(months, users);
  }

  getClientSecret(): Observable<string> {
    return this.restService.Payment().getClientSecret();
  }

  endFreeAndGetClientSecret(): Observable<string> {
    return this.restService.Payment().endFreeAndMakePayment();
  }

  getTariffByPrice(userNumber: number, month: number): Observable<TariffDto> {
    return this.restService
      .Payment()
      .price(userNumber)
      .pipe(
        map((tariffs: TariffDto[]) => {
          return tariffs.find((tariff: TariffDto) => tariff.month === month);
        })
      );
  }
}

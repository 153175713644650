import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalScrollService {
  constructor() {}

  _scrollbar: HTMLElement;

  get scrollbar(): HTMLElement {
    return this._scrollbar;
  }

  get scrolled(): Observable<Event> {
    return fromEvent(this._scrollbar, 'scroll');
  }

  setScrollbar(scrollbarRef: HTMLElement): void {
    this._scrollbar = scrollbarRef;
  }
}

import { Injectable } from '@angular/core';
import { ConfigService } from '../../../core/services';
import { loadStripe, PaymentIntentResult, SetupIntentResult, Stripe } from '@stripe/stripe-js';
import { Observable, of } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { filter, tap } from 'rxjs/operators';
import { Params } from '@angular/router';

@Injectable()
export class StripeService {
  constructor(private configService: ConfigService) {}

  private readonly paymentClientSecretKey = 'payment_intent_client_secret';
  private readonly setupClientSecretKey = 'setup_intent_client_secret';

  private stripe: Stripe;

  getStripe$(): Observable<Stripe> {
    if (this.stripe) return of(this.stripe);

    return fromPromise(this.getStripePromise()).pipe(
      tap((stripe: Stripe) => (this.stripe = stripe)),
      filter((stripe: Stripe) => !!stripe)
    );
  }

  retrievePaymentIntent$(stripe: Stripe, clientSecret: string): Observable<PaymentIntentResult> {
    return fromPromise(stripe?.retrievePaymentIntent(clientSecret));
  }

  retrieveSetupIntent$(stripe: Stripe, clientSecret: string): Observable<SetupIntentResult> {
    return fromPromise(stripe?.retrieveSetupIntent(clientSecret));
  }

  getPaymentClientSecretByQueryParams(queryParams: Params): string {
    if (!queryParams) return '';

    return queryParams[this.paymentClientSecretKey];
  }
  getSetupClientSecretByQueryParams(queryParams: Params): string {
    if (!queryParams) return '';

    return queryParams[this.setupClientSecretKey];
  }

  private async getStripePromise(): Promise<Stripe> {
    const publishableKey: string = this.configService.getStripePublishableKey();

    return await loadStripe(publishableKey);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'max',
  pure: true
})
export class MaxPipe implements PipeTransform {
  transform(number: number, max: number): number {
    return Math.min(number, max);
  }
}

import { Injectable } from '@angular/core';
import { KeyboardEventCode } from '../../enums';

@Injectable({
  providedIn: 'root'
})
export class KeyboardHandlerService {
  // event key handlers:
  enterPressed(event: KeyboardEvent): boolean {
    return event.key === KeyboardEventCode.enter;
  }

  backspaceOrDeletePressed(event: KeyboardEvent): boolean {
    return event.key === KeyboardEventCode.backspace || event.key === KeyboardEventCode.delete;
  }

  backspacePressed(event: KeyboardEvent): boolean {
    return event.key === KeyboardEventCode.backspace;
  }

  arrowPressed(event: KeyboardEvent): boolean {
    const arrowKeys: KeyboardEventCode[] = [
      KeyboardEventCode.arrowUp,
      KeyboardEventCode.arrowDown,
      KeyboardEventCode.arrowLeft,
      KeyboardEventCode.arrowRight
    ];

    return arrowKeys.some((item: KeyboardEventCode) => event.key === item);
  }

  arrowUpPressed(event: KeyboardEvent): boolean {
    return event.key === KeyboardEventCode.arrowUp;
  }

  arrowDownPressed(event: KeyboardEvent): boolean {
    return event.key === KeyboardEventCode.arrowDown;
  }

  // event keyCode handlers:
  isOnlyDigital(event: KeyboardEvent): boolean {
    return (
      (event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)
    );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ScrollbarModule } from '../../scrollbar/scrollbar.module';
import { AngularMaterialModule } from '../../shared/helper-modules';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [],
  imports: [CommonModule, TranslateModule, ScrollbarModule, AngularMaterialModule, RouterModule],
  exports: [
    // modules:
    TranslateModule,
    ScrollbarModule,
    AngularMaterialModule,
    RouterModule
  ]
})
export class CoreModule {}

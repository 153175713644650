import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberWithSpaces'
})
export class NumberWithSpacesPipe implements PipeTransform {
  private readonly space = ' ';
  private readonly spacesRexExp = /(?!^)(?=(?:\d{3})+$)/g;

  transform(value: number): string {
    if (typeof value === 'number' && value >= 0) {
      return String(value).replace(this.spacesRexExp, this.space);
    }

    return '0';
  }
}

<div
  *ngIf="candidate"
  class="candidate-card selectable"
  [class.revealed]="revealed"
  [class.selected]="candidate.isSelected">
  <div
    class="candidate-card__checkbox"
    (click)="onSelectCandidate()">
  </div>

  <div class="candidate-card__content">
    <div class="left-content-block">
      <a
        [routerLink]="['/profile', candidate.profileIds[0]]"
        target="_blank"
        class="profile__media">
        <ng-container *ngIf="picture$ | async as picture; else noPicture">
          <img
            alt=""
            width="80"
            height="80"
            [src]="picture"
            (error)="onPictureError()">

          <img
            alt=""
            width="80"
            height="80"
            [src]="picture"
            class="profile__media-hover">
        </ng-container>

        <ng-template #noPicture>
          <span
            class="media-placeholder">
            <span class="media-placeholder__text">
              <app-candidate-name
                [name]="candidate.fullName"
                [getNameAbbreviation]="true">
              </app-candidate-name>
            </span>
          </span>
        </ng-template>
      </a>

<!--      ************ redesign: temporary hide: ************ -->
<!--      <svg-->
<!--        *ngIf="candidate.viewed"-->
<!--        width="19"-->
<!--        height="14"-->
<!--        class="icon candidate-card__visited-icon">-->
<!--        <use xlink:href="#visited-icon"></use>-->
<!--      </svg>-->

<!--      <svg-->
<!--        *ngIf="!candidate.viewed"-->
<!--        width="20"-->
<!--        height="20"-->
<!--        class="icon candidate-card__visited-icon">-->
<!--        <use xlink:href="#not-visited-icon"></use>-->
<!--      </svg>-->

      <div class="actions-block">
        <div class="actions-block__wrapper">
          <app-candidate-card-actions
            [type]="type"
            [candidate]="candidate"
            [picture]="picture$ | async"
            [index]="index"
            [revealed]="revealed"
            (removeFromFolder)="onRemoveFromFolder()"
            class="candidate-actions">
          </app-candidate-card-actions>
        </div>
      </div>
    </div>

    <div class="candidate-content">
      <div class="candidate-content-wrapper">
        <div
          class="candidate-card__header"
          [ngClass]="{'with-indicator-block': candidate.openToWork}">
          <div class="candidate-card__header-row main-row">
            <div
              *ngIf="type === 'standard' && candidate.openToWork"
              class="candidate-available">
              <span class="candidate-available__text">
                {{ 'CANDIDATE.OPEN_TO_WORK' | translate }}
              </span>
            </div>

            <app-folder-status
              *ngIf="type === 'forFolder' && candidate.folderStatus"
              class="candidate-card__folder-status"
              [status]="candidate.folderStatus"
              [type]="'text'"
              [selectable]="false"
              (clickOnStatus)="onChangeFolderStatus()">
            </app-folder-status>

            <a
              [routerLink]="['/profile', candidate.profileIds[0]]"
              target="_blank"
              class="candidate-name">
              <span class="candidate-name__text">
                <ng-container *ngIf="candidate.fullName">
                  {{ candidate.fullName }}
                </ng-container>

                <ng-container *ngIf="!candidate.fullName">
                  {{ 'CANDIDATE.UNKNOWN_NAME' | translate }}
                </ng-container>
              </span>
            </a>

            <span class="candidate-position">
              {{ candidate.headLine }}
            </span>
          </div>

          <div class="candidate-card__header-row side-row">
            <span class="candidate-location">
              {{ candidate.location | location }}
            </span>

            <ng-container *ngIf="!revealed">
              <ng-container *ngTemplateOutlet="candidateLinks"></ng-container>
            </ng-container>
          </div>
        </div>

        <div class="candidate-card__main-content">
          <ng-container *ngIf="revealed">
            <ng-container *ngTemplateOutlet="candidateLinks"></ng-container>
          </ng-container>

          <app-candidate-card-skills
            *ngIf="candidate.skillGroupsMain?.length"
            [revealed]="revealed"
            [skillGroupsMain]="candidate.skillGroupsMain"
            class="profile-info">
          </app-candidate-card-skills>

          <app-candidate-card-tags
            class="profile-info"
            [docId]="candidate.docId"
            [revealed]="revealed">
          </app-candidate-card-tags>

          <app-candidate-card-experience
            *ngIf="candidate.positions?.length"
            [revealed]="revealed"
            [positions]="candidate.positions"
            class="profile-info">
          </app-candidate-card-experience>

          <app-profile-langs
            *ngIf="revealed && candidate.languages?.length"
            class="profile-info"
            [size]="'small'"
            [revealed]="revealed"
            [languages]="candidate.languages">
          </app-profile-langs>
        </div>
      </div>

      <button
        class="btn btn--action candidate-card__show-more-btn"
        [class.revealed]="revealed"
        (click)="toggleRevealCard()">
        <span class="btn__text">
          <ng-container *ngIf="!revealed">
            {{ 'GENERAL.SHOW_MORE' | translate }}
          </ng-container>

          <ng-container *ngIf="revealed">
            {{ 'GENERAL.SHOW_LESS' | translate }}
          </ng-container>
        </span>

        <svg
          width="20"
          height="20"
          class="icon btn__icon">
          <use xlink:href="#arrow-down">
          </use>
        </svg>
      </button>

      <app-candidate-card-contacts
        class="candidate-card__contacts"
        [profileId]="candidate.profileIds[0]"
        [type]="type"
        [contacts]="candidate.contacts"
        [showSearchContacts]="candidate.showSearchContacts">
      </app-candidate-card-contacts>
    </div>
  </div>
</div>

<ng-template #candidateLinks>
  <app-candidate-card-links
    *ngIf="candidate?.links"
    class="candidate-links profile-info"
    [candidateSources]="candidate.links"
    [revealed]="revealed">
  </app-candidate-card-links>
</ng-template>

import { CompanySizeFilterItem } from '../../../../../shared/models/candidate/companySize';
import { FacetType, SpecificFacetType } from '../../../../../shared/models';
import { FilterParam } from '../../../../../shared/enums';

interface FilterInfo {
  facetType: FacetType;
  filterParams: FilterParam;
  specificFacetType: SpecificFacetType;
  value: string;
  label: string;
}

export class SpotlightsFilterData {
  private static list: FilterInfo[] = [
    {
      facetType: 'openToWork',
      filterParams: FilterParam.byOpenToWork,
      specificFacetType: 'open_to_work_li',
      value: 'true',
      label: 'FILTER.SPOTLIGHTS.FILTERS.OPEN_TO_WORK'
    }
  ];

  static getFilterList(): CompanySizeFilterItem[] {
    return SpotlightsFilterData.list.map((range: FilterInfo, index: number) => {
      const { value, label, facetType, specificFacetType } = range;
      const cssClass: string = SpotlightsFilterData.getItemCssClass(facetType);

      return {
        value,
        label,
        class: cssClass,
        id: index,
        facetType,
        specificFacetType,
        checked: false
      };
    });
  }

  private static getItemCssClass(type: string): string {
    const cssClassPrefix = 'spotlights';
    const separator = '-';

    return `${cssClassPrefix}${separator}${type}`;
  }
}

<app-modal
  [info]="info"
  (closeModal)="closeModal($event)">
  <ng-template #titleContent>
    <h3 class="modal__title">
      <ng-container *ngIf="data?.multipleInfo; else singleCandidate">
        {{
        'FOLDERS.FOLDER_PAGE.CANDIDATE_ACTIONS.MODALS.REMOVE.TITLE.MULTIPLE' | translate:
          { multipleInfo: data.multipleInfo }
        }}
      </ng-container>

      <ng-template #singleCandidate>
        {{ 'FOLDERS.FOLDER_PAGE.CANDIDATE_ACTIONS.MODALS.REMOVE.TITLE.SINGLE' | translate: { name: data.name | translate } }}
      </ng-template>
    </h3>
  </ng-template>
</app-modal>

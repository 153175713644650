<div 
  class="checkbox-area" 
  [class.enabled]="pageSelectionInfo?.total > 0"
  [class.dropdown-opened]="selectionDropdown?.isShown"
  #checkboxArea>
  <div
    [class.checked]="checkboxState === 'check'"
    [class.partially-checked]="checkboxState === 'minus'"
    class="checkbox"
    (click)="onCheckboxClick()">
    <svg width="14" height="12" class="icon icon-check">
      <use xlink:href="#check-icon"></use>
    </svg>

    <svg width="14" height="2" class="icon icon-minus">
      <use xlink:href="#minus-wide"></use>
    </svg>
  </div>

  <div class="dropdown-btn" (click)="toggleDropdown()">
    <svg width="20" height="20" class="icon">
      <use xlink:href="#arrow-down"></use>
    </svg>
  </div>

  <app-custom-dropdown 
      #selectionDropdown 
      [reference]="checkboxArea"
      [withBackdrop]="true">
    <app-profile-mass-selection-dropdown
      *ngIf="selectionDropdown?.isShown"
      [paginationInfo]="paginationInfo"
      [withRange]="withRange"
      [maxSelectableProfiles]="maxSelectableProfiles"
      (close)="closeDropdown()">
    </app-profile-mass-selection-dropdown>
  </app-custom-dropdown>
</div>
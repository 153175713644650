import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { first, takeWhile } from 'rxjs/operators';
import { concat, interval, Observable, Subscription } from 'rxjs';
import { WindowService } from '../../../shared/services';
import { ConfigService } from '../../../core/services';

@Injectable({
  providedIn: 'root'
})
export class VersionCheckService {
  constructor(
    private appRef: ApplicationRef,
    private updates: SwUpdate,
    private windowService: WindowService,
    private configService: ConfigService
  ) {}

  private available$: Subscription;
  private everyHourOnceAppIsStable$: Observable<number | boolean> | Subscription;

  check(): void {
    if (this.configService.isProduction) {
      this.checkOnAvailable();
      this.startIntervalForChecking();
    }
  }

  unsubscribe(): void {
    if (this.configService.isProduction) {
      if (this.available$ && this.available$.unsubscribe) {
        this.available$.unsubscribe();
      }

      if (
        this.everyHourOnceAppIsStable$ &&
        (this.everyHourOnceAppIsStable$ as Subscription).unsubscribe
      ) {
        (this.everyHourOnceAppIsStable$ as Subscription).unsubscribe();
      }
    }
  }

  private checkOnAvailable(): void {
    this.available$ = this.updates.available
      .pipe(takeWhile(() => this.updates.isEnabled))
      .subscribe(() => {
        this.windowService.locationReload();
      });
  }

  private startIntervalForChecking(): void {
    const appIsStable$: Observable<boolean> = this.appRef.isStable.pipe(
      first((isStable) => isStable === true)
    );
    const everyHour$: Observable<number> = interval(60 * 60 * 1000);
    this.everyHourOnceAppIsStable$ = concat(appIsStable$, everyHour$);

    this.everyHourOnceAppIsStable$.subscribe(() => this.updates.checkForUpdate());
  }
}

export enum SearchSidebarTabs {
  FILTERS = 'search-filters',
  SAVED_SEARCHES = 'saved-searches',
  SEARCH_HISTORY = 'search-history'
}

export enum ProfileSearchEnum {
  defaultPageSize = 30,
  maxPage = 100,
  limitedAccesMaxPage = 2
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-default-photo',
  templateUrl: './user-default-photo.component.html',
  styleUrls: ['./user-default-photo.component.scss']
})
export class UserDefaultPhotoComponent {
  @Input() bright = false;
}

import { ComponentFactory, ComponentRef, Injectable } from '@angular/core';
import { createCustomElement, NgElementConstructor } from '@angular/elements';

import { CreateCustomElementInfo } from '../models';
import { DocumentService } from './global';

@Injectable({
  providedIn: 'root'
})
export class CustomElementsService {
  constructor(private documentService: DocumentService) {}

  createCustomElement({
    info,
    injector,
    applicationRef,
    componentFactoryResolver
  }: CreateCustomElementInfo): void {
    const { name, component } = info;

    if (!customElements.get(name)) {
      const constructor: NgElementConstructor<typeof component> = createCustomElement(component, {
        injector
      });

      customElements.define(name, constructor);

      const customElement: HTMLElement = this.documentService.createElement(name);
      const factory: ComponentFactory<typeof component> =
        componentFactoryResolver.resolveComponentFactory(component as any);

      const customComponent: ComponentRef<typeof component> = factory.create(
        injector,
        [],
        customElement
      );

      applicationRef.attachView(customComponent.hostView);
    }
  }
}

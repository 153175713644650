import { Injectable } from '@angular/core';

import { Autocomplete, ShowBlockInfo } from '../models';
import { CustomDropdownComponent } from '../../modules/platform/components';

@Injectable()
export class NavDropdownService {
  constructor() {}

  private showDropdownInfo: ShowBlockInfo;
  private contentList: Autocomplete[];
  private input: HTMLElement;
  private dropdownComponent: CustomDropdownComponent;
  private dropdown: HTMLElement;

  private activeItemIndex: number;

  // Set data:

  setShowDropdownInfo(info: ShowBlockInfo): void {
    this.showDropdownInfo = info;
  }

  setContentList(list: Autocomplete[]): void {
    this.contentList = list;
  }

  setInput(input: HTMLElement): void {
    this.input = input;
  }

  setDropdownComponent(dropdownComponent: CustomDropdownComponent): void {
    this.dropdownComponent = dropdownComponent;
  }

  setDropdown(dropdown: HTMLElement): void {
    this.dropdown = dropdown;
  }

  // dropdown handlers:

  toggleDropdown(): void {
    if (this.showDropdownInfo) {
      this.showDropdownInfo.show = !this.showDropdownInfo.show;

      if (this.dropdownComponent) {
        if (this.showDropdownInfo.show) {
          this.dropdownComponent.show();
        } else {
          this.dropdownComponent.hide();
        }
      }
    }
  }

  showDropdown(): void {
    if (this.showDropdownInfo && !this.showDropdownInfo.show) {
      this.showDropdownInfo.show = true;

      if (this.dropdownComponent) {
        this.dropdownComponent.show();
      }
    }
  }

  hideDropdown(): void {
    if (this.showDropdownInfo?.show) {
      this.showDropdownInfo.show = false;

      if (this.dropdownComponent) {
        this.dropdownComponent.hide();
      }
    }
  }

  // Keyboard handlers:

  inputKeyDown(): void {
    const dropdownActive: boolean = this.showDropdownInfo.show && this.contentList?.length > 0;

    if (dropdownActive) {
      this.activeItemIndex = 0;

      this.handleItemsOnNav();

      this.blurInput();
      this.focusOnDropdown();
    }
  }

  dropdownArrowDown(): void {
    if (this.activeItemIndex + 1 < this.contentList.length) {
      this.activeItemIndex++;
    } else {
      this.activeItemIndex = 0;
    }

    this.handleItemsOnNav();
  }

  dropdownArrowUp(): void {
    this.activeItemIndex--;

    this.handleItemsOnNav();

    if (this.activeItemIndex < 0) {
      this.blurDropdown();
      this.focusOnInput();

      this.hideDropdown();
    }
  }

  // private handlers:

  private blurInput(): void {
    if (this.input) {
      this.input.blur();
    }
  }

  private focusOnInput(): void {
    if (this.input) {
      this.input.focus();

      requestAnimationFrame(() => {
        const inputValue: string = (this.input as HTMLInputElement).value;
        const cursorPosition: number = inputValue?.length;

        (this.input as HTMLInputElement).setSelectionRange(cursorPosition, cursorPosition);
      });
    }
  }

  private blurDropdown(): void {
    if (this.dropdown) {
      this.dropdown.blur();
    }
  }

  private focusOnDropdown(): void {
    if (this.dropdown) {
      this.dropdown.focus();
    }
  }

  private handleItemsOnNav(): void {
    this.contentList.forEach((item: Autocomplete, index: number) => {
      this.contentList[index].active = index === this.activeItemIndex;
    });
  }
}

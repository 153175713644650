import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import {
  AccountService,
  AuthService,
  FontsService,
  GlobalScrollService,
  WindowService
} from '../../../shared/services';
import { PaymentService, UserService } from '../../../shared/services/requests';
import { Account, User } from '../../../shared/models';
import { ChangedUserHandlerService } from './changed-user-handler.service';
import { VersionCheckService } from './version-check.service';
import { WidgetInjectorService } from './widget-injector.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class AppHandlerService implements OnDestroy {
  constructor(
    public dialog: MatDialog,
    private versionCheck: VersionCheckService,
    private scroll: GlobalScrollService,
    private authService: AuthService,
    private windowService: WindowService,
    private userService: UserService,
    private widgetInjector: WidgetInjectorService,
    private accountService: AccountService,
    private changedUserHandler: ChangedUserHandlerService,
    private paymentService: PaymentService,
    private fontsService: FontsService
  ) {}

  private unsubscribe$ = new Subject<void>();

  init(): void {
    // fonts loading:
    this.fontsService.loadCriticalFonts();

    setTimeout(() => {
      this.fontsService.loadNonCriticalFonts();
    }, 500);

    // rest setting:
    this.versionCheck.check();
    this.widgetLoad();
  }

  closeAllDialogs(): void {
    this.dialog.closeAll();
  }

  setScrollbarRef(scrollbarRef: HTMLElement): void {
    this.scroll.setScrollbar(scrollbarRef);
  }

  getWindowWidth(): number {
    return this.windowService.innerWidth;
  }

  getAccount(): Observable<Account> {
    return this.paymentService.getAccount();
  }

  handleChangedUser(user: User): void {
    this.changedUserHandler.handle(user);
  }

  clearWidget(): void {
    this.widgetInjector.clear();
  }

  unsubscribe(): void {
    this.versionCheck.unsubscribe();
    this.authService.unsubscribe();
  }

  private widgetLoad(): void {
    combineLatest([this.userService.currentUser$, this.accountService.currentAccountInfo$])
      .pipe(
        filter(([user, accountInfo]) => (!user && !accountInfo) || (!!user && !!accountInfo)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([user, accountInfo]) => {
        this.widgetInjector.load(user, accountInfo);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe();

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

import { Injectable } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { Link, LinkFull } from '../../models';
import { urlProtocolRegExp } from '../../utilities';
import { WindowService } from '../global';

@Injectable({
  providedIn: 'root'
})
export class CandidateSourcesService {
  constructor(
    private windowService: WindowService,
    private sanitizer: DomSanitizer
  ) {}

  private sourcesInfo: LinkFull[] = [
    {
      domain: 'https://www.linkedin.com/',
      label: 'linkedin',
      name: 'linkedin',
      fabIcon: 'linkedin-in',
      className: 'icon--linkedin'
    },
    {
      domain: 'https://stackoverflow.com/',
      label: 'stackoverflow',
      name: 'stackoverflow',
      fabIcon: 'stack-overflow',
      className: 'icon--stackoverflow'
    },
    {
      domain: 'https://github.com/',
      label: 'github',
      name: 'github',
      fabIcon: 'github',
      className: 'icon--github'
    },
    {
      domain: 'https://www.behance.net/',
      label: 'behance',
      name: 'behance',
      fabIcon: 'behance',
      className: 'icon--behance'
    },
    {
      domain: 'https://dou.ua/',
      label: 'dou.ua',
      name: 'dou.ua',
      fabIcon: null,
      className: 'icon--dou'
    },
    {
      domain: 'https://www.facebook.com/',
      label: 'facebook',
      name: 'facebook',
      fabIcon: 'facebook-f',
      className: 'icon--facebook'
    },
    {
      domain: 'https://twitter.com/',
      label: 'twitter',
      name: 'twitter',
      fabIcon: 'twitter',
      className: 'icon--twitter'
    },
    {
      domain: 'https://dribbble.com/',
      label: 'dribbble',
      name: 'dribbble',
      fabIcon: 'dribbble',
      className: 'icon--dribbble'
    }
  ];

  private static handleConnectionsCount(link: Link): void {
    if (link?.connectionsCount) {
      link.connectionsCount = Math.abs(link.connectionsCount);
    }
  }

  getFabIcon(source: string): IconProp {
    const particularSourceInfo =
      source && this.sourcesInfo.find((sourceInfo: LinkFull) => sourceInfo.name === source);

    return particularSourceInfo?.fabIcon || null;
  }

  getDomainName(url: string): string {
    if (url) {
      const urlWithoutProtocol: string = url.replace(urlProtocolRegExp, '');

      if (urlWithoutProtocol && urlWithoutProtocol.indexOf('/') !== -1) {
        return urlWithoutProtocol.split('/')[0];
      }
    }

    return url;
  }

  getParticularLinkInfo(link: Link): LinkFull {
    return this.sourcesInfo.find((linkInfo: LinkFull) => link?.domain?.includes(linkInfo.name));
  }

  handleSourceListByLink(link: Link): void {
    this.handleLink(link);
    CandidateSourcesService.handleConnectionsCount(link);
  }

  openLinkInNewTab(link: string): void {
    this.windowService.open(link, '_blank');
  }

  getHandledLinkValueWithoutHttp(
    value: string,
    madeSecure: boolean = false
  ): string | SafeResourceUrl {
    if (!(value as string)?.includes('https://') && !(value as string)?.includes('http://')) {
      const slashes = '//';
      const link: string = `${slashes}${value}`;

      return madeSecure ? this.sanitizer.bypassSecurityTrustResourceUrl(link) : link;
    }

    return value;
  }

  private handleLink(link: Link): void {
    link.value = this.getHandledLinkValueWithoutHttp(link.value as string, true);
  }
}

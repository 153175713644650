import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { SystemPages } from '../../enums/routerPaths.enum';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodPageService {
  constructor(private router: Router) {}

  private isPageOpened = false;

  getBeOpened(): boolean {
    return this.isPageOpened;
  }

  openPage(queryParams: Params = null): void {
    this.isPageOpened = true;

    if (!queryParams) queryParams = {};

    this.router.navigate([SystemPages.ADD_PAYMENT_METHOD], { queryParams }).then(() => {});
  }

  closePage(): void {
    this.isPageOpened = false;
  }
}

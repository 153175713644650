import { Directive, ElementRef, HostBinding, Input } from '@angular/core';

import { FolderStatusComponentType } from '../../../../../shared/types';
import { FolderStatus } from '../../../../../shared/models';
import { FolderStatusesService } from '../../../services';

@Directive({
  selector: '[appFolderStatus]'
})
export class FolderStatusDirective {
  constructor(
    private element: ElementRef,
    private statusesService: FolderStatusesService
  ) {}

  private _status: FolderStatus;

  @Input() type: FolderStatusComponentType = 'full-actions';
  @Input() active = false; // only for 'filter' or 'chose-one-status'
  @Input() noMove = false; // for drag and drop (move cursor)

  @Input() set status(status: FolderStatus) {
    this._status = status;

    if (this._status) {
      this.setStatusView();
    }
  }

  @HostBinding('class.clickable') get clickableClass(): boolean {
    return this.type === 'filter' || this.type === 'text' || this.type === 'single-change-only';
  }

  @HostBinding('class.move') get moveClass(): boolean {
    return (
      (this.type === 'full-actions' ||
        this.type === 'default-change-only' ||
        this.type === 'click-all') &&
      !this._status.lastDefault
    );
  }

  private setStatusView(): void {
    const colorValue: string = this.statusesService.getStatusColorValue(this._status.color);

    if (colorValue) {
      const standard: boolean = this.type !== 'text';
      const active: boolean = this.active;

      if (standard) {
        const element: HTMLElement = this.element.nativeElement;
        element.style.backgroundColor = FolderStatusDirective.getHexColorWithOpacity(
          colorValue,
          10
        );
      }

      this.setElementColorOnActive(colorValue, active);
    }
  }

  private setElementColorOnActive(colorValue: string = '', active: boolean = null): void {
    if (!colorValue) {
      colorValue = this.statusesService.getStatusColorValue(this._status.color);
    }

    if (active === null) {
      active = this.active;
    }

    if (active) {
      this.element.nativeElement.style.borderColor = colorValue;
    }
  }

  private static getHexColorWithOpacity(hexColor, opacity): string {
    const alpha = opacity / 100;
    let hexAlpha = Math.round(alpha * 255).toString(16);

    if (hexAlpha.length === 1) {
      hexAlpha = '0' + hexAlpha;
    }

    return hexColor + hexAlpha;
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FolderStatus } from '../../../../../shared/models';
import { RolesTypesEnum } from '../../../../../shared/enums';
import { FolderStatusComponentType } from '../../../../../shared/types';

@Component({
  selector: 'app-folder-status',
  templateUrl: './folder-status.component.html',
  styleUrls: ['./folder-status.component.scss']
})
export class FolderStatusComponent {
  constructor() {}

  @Input() type: FolderStatusComponentType = 'full-actions';
  @Input() selectable: boolean = true;
  @Input() currentUserId: string;

  @Input() status: FolderStatus;
  @Input() profileUserRoleLabel: RolesTypesEnum = null; // only for 'full-actions'
  @Input() active = false; // only for 'filter' or 'chose-one-status'
  @Input() noMove = false; // for drag and drop (move cursor)

  @Output() editStatusName = new EventEmitter<FolderStatus>(); // only for 'full-actions'
  @Output() editStatusColor = new EventEmitter<FolderStatus>(); // only for 'full-actions'
  @Output() removeStatus = new EventEmitter<FolderStatus>(); // only for 'full-actions'

  @Output() clickOnStatus = new EventEmitter<FolderStatus>(); // for 'filter', 'text', 'chose-one-status'

  get isActive(): boolean {
    return this.active && this.selectable;
  }

  clickEditStatusName(): void {
    if (this.type === 'full-actions') {
      this.editStatusName.emit(this.status);
    }
  }

  clickEditStatusColor(): void {
    if (this.type === 'full-actions') {
      this.editStatusColor.emit(this.status);
    }
  }

  clickRemoveStatus(): void {
    if (this.type === 'full-actions') {
      this.removeStatus.emit(this.status);
    }
  }

  onClickOnStatus(): void {
    if (this.type === 'filter' || this.type === 'text' || this.type === 'single-change-only') {
      this.clickOnStatus.emit(this.status);
    }
  }
}

import { SpecificFacetType, FilterCheckboxItem } from '../../../../../shared/models';

export class IndustryFilterData {
  private static listInfo: string[] = [
    'Accounting',
    'Airlines/Aviation',
    'Alternative Dispute Resolution',
    'Alternative Medicine',
    'Animation',
    'Apparel & Fashion',
    'Architecture & Planning',
    'Arts & Crafts',
    'Automotive',
    'Aviation & Aerospace',
    'Banking',
    'Biotechnology',
    'Broadcast Media',
    'Building Materials',
    'Business Supplies & Equipment',
    'Capital Markets',
    'Chemicals',
    'Civic & Social Organization',
    'Civil Engineering',
    'Commercial Real Estate',
    'Computer & Network Security',
    'Computer Games',
    'Computer Hardware',
    'Computer Networking',
    'Computer Software',
    'Construction',
    'Consumer Electronics',
    'Consumer Goods',
    'Consumer Services',
    'Cosmetics',
    'Dairy',
    'Defense & Space',
    'Design',
    'E-learning',
    'Education Management',
    'Electrical & Electronic Manufacturing',
    'Entertainment',
    'Events Services',
    'Executive Office',
    'Facilities Services',
    'Farming',
    'Financial Services',
    'Fine Art',
    'Fishery',
    'Food & Beverages',
    'Food Production',
    'Fundraising',
    'Furniture',
    'Gambling & Casinos',
    'Glass, Ceramics & Concrete',
    'Government Administration',
    'Government Relations',
    'Graphic Design',
    'Health, Wellness & Fitness',
    'Higher Education',
    'Hospital & Health Care',
    'Hospitality',
    'Human Resources',
    'Import & Export',
    'Individual & Family Services',
    'Industrial Automation',
    'Information Services',
    'Information Technology & Services',
    'Insurance',
    'International Affairs',
    'International Trade & Development',
    'Internet',
    'Investment Banking',
    'Investment Management',
    'Judiciary',
    'Law Enforcement',
    'Law Practice',
    'Legal Services',
    'Legislative Office',
    'Leisure, Travel & Tourism',
    'Libraries',
    'Logistics & Supply Chain',
    'Luxury Goods & Jewelry',
    'Machinery',
    'Management Consulting',
    'Maritime',
    'Market Research',
    'Marketing & Advertising',
    'Mechanical Or Industrial Engineering',
    'Media Production',
    'Medical Device',
    'Medical Practice',
    'Mental Health Care',
    'Military',
    'Mining & Metals',
    'Motion Pictures & Film',
    'Museums & Institutions',
    'Music',
    'Nanotechnology',
    'Newspapers',
    'Non-profit Organization Management',
    'Oil & Energy',
    'Online Media',
    'Outsourcing/Offshoring',
    'Package/Freight Delivery',
    'Packaging & Containers',
    'Paper & Forest Products',
    'Performing Arts',
    'Pharmaceuticals',
    'Philanthropy',
    'Photography',
    'Plastics',
    'Political Organization',
    'Primary/Secondary Education',
    'Printing',
    'Professional Training & Coaching',
    'Program Development',
    'Public Policy',
    'Public Relations & Communications',
    'Public Safety',
    'Publishing',
    'Railroad Manufacture',
    'Ranching',
    'Real Estate',
    'Recreational Facilities & Services',
    'Religious Institutions',
    'Renewables & Environment',
    'Research',
    'Restaurants',
    'Retail',
    'Security & Investigations',
    'Semiconductors',
    'Shipbuilding',
    'Sporting Goods',
    'Sports',
    'Staffing & Recruiting',
    'Supermarkets',
    'Telecommunications',
    'Textiles',
    'Think Tanks',
    'Tobacco',
    'Translation & Localization',
    'Transportation/Trucking/Railroad',
    'Utilities',
    'Venture Capital & Private Equity',
    'Veterinary',
    'Warehousing',
    'Wholesale',
    'Wine & Spirits',
    'Wireless',
    'Writing & Editing'
  ];

  static getInitListForFilter(): FilterCheckboxItem[] {
    return IndustryFilterData.listInfo.map((item: string, index: number) => {
      const cssClass = this.getCssClass(item);
      const facetFilterType: SpecificFacetType =
        IndustryFilterData.getStandardFacetFilterType(item);
      const extraFacetFilterType: SpecificFacetType =
        IndustryFilterData.getCurrentFacetFilterType(item);

      return {
        value: item,
        label: item,
        class: cssClass,
        id: index,
        facetType: 'industry',
        extraFacetType: 'currentIndustry',
        checked: false,
        specificFacetType: facetFilterType,
        extraSpecificFacetType: extraFacetFilterType
      };
    });
  }

  private static getCssClass(value: string): string {
    const prefix = 'industry';
    const separator = '-';
    const handledValue: string = value.toLowerCase().replace(/[^a-zA-Z\s]+/g, '');
    const lastPart: string = handledValue.split(' ').join(separator);

    return `${prefix}${separator}${lastPart}`;
  }

  private static getStandardFacetFilterType(value: string): SpecificFacetType {
    return IndustryFilterData.getFacetFilterType(value, 'industry');
  }

  private static getCurrentFacetFilterType(value: string): SpecificFacetType {
    return IndustryFilterData.getFacetFilterType(value, 'current_industry');
  }

  private static getFacetFilterType(
    value: string = '',
    prefix: string = 'industry'
  ): SpecificFacetType {
    const separator = '_';

    return `${prefix}${separator}${value}` as SpecificFacetType;
  }
}

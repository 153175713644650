import { QueryLabelInfo, RangeInfo } from '../../../../shared/models';

export class RangeInfoFilterQueryItem {
  constructor(public data: RangeInfo) {}

  private separator = '_';

  doesHaveAnyValue(): boolean {
    return this.data?.start > 0 || this.data?.finish !== 10;
  }

  getContentString(): string {
    let filter = '';

    if (this.data) {
      if (this.data.start >= 0 || this.data.finish >= 0) {
        if (this.data.start === 0 && this.data.finish === 10) {
          return filter;
        }

        filter =
          this.data.finish === 10
            ? `${this.data?.start}`
            : `${this.data?.start}${this.separator}${this.data?.finish}`;
      }
    }

    return filter;
  }

  setDataFromSearchLine(searchItem: string): void {
    if (searchItem?.length && this.data && this.separator) {
      const itemList: string[] = searchItem?.split(this.separator);

      if (itemList?.length && Array?.isArray(itemList)) {
        const start: string = itemList[0];
        const finish: string = itemList[1];

        if (start) {
          this.data.start = Number?.parseInt(start, 0);
        }

        if (finish) {
          this.data.finish = Number?.parseInt(finish, 0);
        }
      } else {
        this.data.start = Number?.parseInt(searchItem, 0);
        this.data.finish = 10;
      }
    }
  }

  getLabelInfo(): QueryLabelInfo {
    const label: string = this.getFullLabel();

    return label ? { value: label } : null;
  }

  getFullLabel(): string {
    const { start, finish } = this.data;

    if (start && finish == 10) {
      return `${start} - ${finish}+`;
    }

    return start || finish !== 10 ? `${start} - ${finish}` : '';
  }
}

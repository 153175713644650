import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { TariffDto, TariffGettingInfo, TariffName, TariffsByMonthsAsset } from '../../../models';
import { TariffInfo } from '../../../enums';
import { TariffsHandlerUtilities } from './tariffs-handler.utilities';

@Injectable({
  providedIn: 'root'
})
export class TariffsHandlerService {
  private readonly contactCreditsPerUser = TariffInfo.contactCreditsPerUser;
  private readonly exportCreditsPerUser = TariffInfo.exportCreditsPerUser;
  private currentTariff: TariffDto;
  private nextTariff: TariffDto;
  private info: TariffGettingInfo;

  private currentTariffSubject = new BehaviorSubject<TariffDto>(null);
  private nextTariffSubject = new BehaviorSubject<TariffDto>(null);
  private accountUserNumberSubject = new BehaviorSubject<number>(1);

  currentTariff$: Observable<TariffDto> = this.currentTariffSubject.asObservable();
  nextTariff$: Observable<TariffDto> = this.nextTariffSubject.asObservable();
  accountUserNumber$ = this.accountUserNumberSubject.asObservable();

  getTariffParsedInfoByName(name: TariffName, isItNextAfterTrial: boolean = false): TariffDto {
    if (name === 'free' || (!name && isItNextAfterTrial)) {
      return {
        name,
        users: 1,
        grains: this.contactCreditsPerUser,
        price: 0,
        exportCredits: this.exportCreditsPerUser
      };
    }

    return TariffsHandlerUtilities.getTariffByName(name);
  }

  updateCurrentTariff(tariff: TariffDto): void {
    this.currentTariff = tariff;

    this.currentTariffSubject.next(tariff);
  }

  updateNextTariff(tariff: TariffDto): void {
    this.nextTariff = tariff;

    this.nextTariffSubject.next(tariff);
  }

  dispatchTariffs(info: TariffGettingInfo): void {
    this.info = info;

    this.handleCurrentAndNextTariffs(info);
  }

  resetSystemTariffsInfo(): void {
    this.updateCurrentTariff(null);
    this.updateNextTariff(null);
  }

  updateAccountUserNumber(userNumber: number): void {
    this.accountUserNumberSubject.next(userNumber);
  }

  getTariffsByUsers(asset: TariffsByMonthsAsset, userNumber: number): TariffDto[] {
    return Object.keys(asset).map((monthKey: string) => {
      return this.getTariffByMonthAndUsers(asset, userNumber, +monthKey);
    });
  }

  getTariffByMonthAndUsers(
    asset: TariffsByMonthsAsset,
    userNumber: number,
    monthNumber: number
  ): TariffDto {
    const tariffs: TariffDto[] = asset[monthNumber];

    return tariffs ? tariffs[userNumber - 1] : null;
  }

  private handleCurrentAndNextTariffs(info: TariffGettingInfo): void {
    const { currentTariff, nextTariff } = info;

    this.updateCurrentTariff(currentTariff);
    this.updateNextTariff(nextTariff);
  }
}

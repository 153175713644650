<app-modal
  [info]="info"
  (closeModal)="closeModal()">
  <ng-template #textContent>
    <p class="modal__text">
      {{ 'TAGS.MODAL.TEXT' | translate }}
    </p>
  </ng-template>

  <ng-template #scrollableContent>
    <div *ngIf="tagLists$ | async as lists" class="modal-content">
      <div class="candidate-tags">
        <app-tag
          *ngFor="let tag of lists.profileTags; index as i"
          [tag]="tag"
          [firstItem]="i === 0"
          (remove)="removeTag(tag)">
        </app-tag>

        <app-add-tag-block
          class="add-tag-block"
          [dropdownSizeType]="'small'"
          [tagLists]="lists"
          (addTag)="addTag($event)">
        </app-add-tag-block>
      </div>
    </div>
  </ng-template>
</app-modal>

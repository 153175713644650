<div
  *ngIf="downloadInfo$ | async as downloadInfo"
  class="download-cv">
  <button
    type="button"
    class="btn btn--icon download-cv__btn"
    [ngClass]="{'in-progress': downloadInfo.isInProgress}"
    [disabled]="limitAccess || downloadInfo.isInProgress"
    (click)="download()">
    <ng-container *ngIf="!downloadInfo.isInProgress; else downloadInProgress">
      <svg
        class="icon"
        width="16"
        height="16">
        <use xlink:href="#download">
        </use>
      </svg>
    </ng-container>

    <ng-template #downloadInProgress>
      <app-loading-animation></app-loading-animation>
    </ng-template>
  </button>
</div>

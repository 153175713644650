import { Injectable } from '@angular/core';

import { Account, User, WidgetGlobalData } from '../../../shared/models';
import { UserService } from '../../../shared/services';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WidgetInjectorService {
  constructor(private userService: UserService) {}

  private readonly appId = environment.appIdIntercom;
  private readonly widgetGlobalParam = 'intercomSettings';
  private readonly scriptId = 'widget';

  private readonly scriptSecondHTML = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/db72rxew';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

  load(user: User = null, accountInfo: Account = null): void {
    const body: HTMLElement = WidgetInjectorService.getBody();

    if (body) {
      if (this.isScriptsLoaded(body)) {
        this.handleScriptLoadedCondition(body, user, accountInfo);
      } else {
        this.handleLoadingScripts(body, user, accountInfo);
      }
    }
  }

  clear(): void {
    this.load();
  }

  // main load conditions:

  private handleScriptLoadedCondition(
    body: HTMLElement,
    user: User = null,
    accountInfo: Account = null
  ): void {
    if (user) {
      const isWindowVarsActual: boolean = this.isWindowVarsActual(user, accountInfo);

      if (!isWindowVarsActual) {
        this.updateScripts(body, user, accountInfo);
      }
    } else {
      this.updateScripts(body);
    }
  }

  private handleLoadingScripts(body: HTMLElement, user: User, accountInfo: Account): void {
    const script: HTMLScriptElement = this.generateScript();

    this.handleSettingGlobalData(user, accountInfo);

    if (script) {
      body.appendChild(script);
    }
  }

  // rest private methods:

  private updateScripts(body: HTMLElement, user: User = null, accountInfo: Account = null): void {
    let scripts: HTMLScriptElement = this.getScriptSecondByBody(body);

    this.handleSettingGlobalData(user, accountInfo);

    if (scripts) {
      scripts.remove();

      scripts = this.generateScript();

      body.appendChild(scripts);
    }
  }

  private handleSettingGlobalData(user: User = null, accountInfo: Account = null): void {
    if (user) {
      this.setGlobalDataToWindow(user, accountInfo);
    } else {
      this.setLandingGlobalDataToWindow();
    }
  }

  // global data methods and scripts generations:

  private setGlobalDataToWindow(user: User, accountInfo: Account = null): void {
    window[this.widgetGlobalParam] = this.getGlobalData(user, accountInfo);
  }

  private setLandingGlobalDataToWindow(): void {
    window[this.widgetGlobalParam] = {
      app_id: this.appId,
      name: null
    };
  }

  private getGlobalData(user: User, accountInfo: Account = null): WidgetGlobalData {
    const data: WidgetGlobalData = {
      app_id: this.appId,
      name: user.getFullName(),
      email: user.email,
      user_id: user.userId,
      org_id: user.orgId,
      language: this.userService.extractLocaleFromUser(user),
      role: user.role
    };

    WidgetInjectorService.setAccountInfoToData(data, accountInfo);

    return data;
  }

  private generateScript(): HTMLScriptElement {
    const script: HTMLScriptElement = WidgetInjectorService.getScript();

    script.async = true;
    script.id = this.scriptId;
    script.innerHTML = this.scriptSecondHTML;

    script.setAttribute('data-html2canvas-ignore', 'true');

    return script;
  }

  private static setAccountInfoToData(data: WidgetGlobalData, accountInfo: Account): void {
    if (accountInfo) {
      data.tariff = accountInfo.tariff;
      data.paid_till = accountInfo.paidTill;
      data.trial_end = accountInfo.trialEnd;
      data.is_test = accountInfo.test;
      data.org_name = accountInfo.orgName;
    }
  }

  // conditions private methods:

  private isScriptsLoaded(body: HTMLElement): boolean {
    const scriptSecond: HTMLScriptElement = this.getScriptSecondByBody(body);

    return !!scriptSecond;
  }

  private isWindowVarsActual(user: User, accountInfo: Account = null): boolean {
    if (user) {
      const userWithParams: WidgetGlobalData = this.getGlobalData(user, accountInfo);

      return Object.keys(userWithParams).every(
        (param: string) => window[this.widgetGlobalParam][param] === userWithParams[param]
      );
    }

    return false;
  }

  // common getting HTML Element methods:

  private getScriptSecondByBody(body: HTMLElement): HTMLScriptElement {
    return body.querySelector(`#${this.scriptId}`);
  }

  private static getBody(): HTMLElement {
    return document.getElementsByTagName('body')[0];
  }

  private static getScript(): HTMLScriptElement {
    return document.createElement('script');
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../services';
import { SystemPages } from '../enums/routerPaths.enum';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminGuard  {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isSuperAdmin: boolean = this.authService.getIsSuperAdmin();

    if (isSuperAdmin) {
      return true;
    } else {
      this.router.navigateByUrl(SystemPages.USER_PROFILE).then(() => {});

      return false;
    }
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { Action, HeaderState } from '../models';
import { RoutesHandlerService } from './routes-handler.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  constructor(
    private routesHandler: RoutesHandlerService,
    private authService: AuthService
  ) {}

  private updateStateSubject = new BehaviorSubject<HeaderState>(null);
  private setLastActionsSubject = new Subject<Action[]>();

  updateState$ = this.updateStateSubject.asObservable();
  setLastActions$ = this.setLastActionsSubject.asObservable();

  headerState: HeaderState;

  updateState(state: string): void {
    this.headerState = this.getHeaderState(state);

    this.updateStateSubject.next(this.headerState);
  }

  setLastActions(actions: Action[] = null): void {
    this.setLastActionsSubject.next(actions);
  }

  private getHeaderState(state: string): HeaderState {
    const mainLanding: boolean = this.routesHandler.getIsMainLanding(state);
    const landingInfo: boolean = this.routesHandler.getIsLandingPage(state);
    const auth: boolean = this.routesHandler.getIsAuthPage(state);

    const system: boolean = this.routesHandler.getIsSystemState(state);
    const folders: boolean = this.routesHandler.getIsFolderState(state);
    const candidate: boolean = this.routesHandler.getIsCandidateState(state);

    const search: boolean = this.routesHandler.getIsSearchState(state);
    const user: boolean = this.routesHandler.getIsUserState(state);
    const adminPanel: boolean = this.routesHandler.getIsAdminPanelState(state);
    const redirectLink: boolean = this.routesHandler.getIsRedirectLinkState(state);

    return {
      landing: mainLanding || landingInfo,
      mainLanding,
      landingInfo,
      auth,
      folders,
      system,
      candidate,
      search,
      user,
      logIn: this.authService.getIsUserLoggedIn(),
      adminPanel,
      redirectLink
    };
  }
}

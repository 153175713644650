import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

import { Folder } from '../../../../../shared/models';
import { FolderCardSmallComponentType, SystemColors } from '../../../../../shared/types';

@Component({
  selector: 'app-folder-card-small',
  templateUrl: './folder-card-small.component.html',
  styleUrls: ['./folder-card-small.component.scss']
})
export class FolderCardSmallComponent {
  @Input() type: FolderCardSmallComponentType = 'forCandidatePage';
  @Input() folder: Folder;
  @Input() candidatePicture: SafeUrl;
  @Input() candidateFullName: string;
  @Input() defaultColor: SystemColors;

  @Output() toggleCandidate = new EventEmitter();
  @Output() removeCandidate = new EventEmitter<Folder>();
  @Output() clickFolder = new EventEmitter<Folder>();

  clickRemoveCandidate(event: Event): void {
    if (this.type === 'forCandidatePage') {
      event.stopPropagation();

      this.removeCandidate.emit(this.folder);
    }
  }

  clickFullCard(event: Event): void {
    if (this.type === 'forCandidatePage' || this.type === 'simple') {
      event.preventDefault();
      this.clickFolder.emit(this.folder);
    } else if (this.type === 'withPicture') {
      this.clickToggleCandidate();
    }
  }

  onError(): void {
    this.candidatePicture = '';
  }

  private clickToggleCandidate(): void {
    this.toggleCandidate.emit(null);
  }
}

import { Injectable } from '@angular/core';
import { SearchFilterQuery } from 'src/app/modules/system-shared/classes';
import { SearchFilterQueryItem } from 'src/app/modules/system-shared/classes/search-filters/search-filter-query-item';
import { SearchFilterQueryItemData } from 'src/app/modules/system-shared/models/search-filter';
import { Autocomplete } from 'src/app/shared/models';

@Injectable()
export class UsedSearchQueryItemsService {
  private usedItems: SearchFilterQueryItemData[] = [];

  update(searchQuery: SearchFilterQuery): void {
    this.usedItems = UsedSearchQueryItemsService.getUsedItems(searchQuery);
  }

  isAutocompleteEntryUsed(item: Autocomplete): boolean {
    return this.usedItems.some(
      (used: SearchFilterQueryItemData) =>
        (item.id !== undefined && item.id === used.autocompleteId) ||
        (item.name !== undefined && item.name === used.value)
    );
  }

  private static getUsedItems(searchQuery: SearchFilterQuery): SearchFilterQueryItemData[] {
    if (!searchQuery.data?.info?.searchItemList) {
      return [];
    }

    const { searchItemList } = searchQuery.data.info;

    return searchItemList.map((item: SearchFilterQueryItem) => item.data).filter((a) => !!a);
  }

}

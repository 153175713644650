import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { CustomDropdownItemInfo, ShowBlockInfo, Tag } from '../../../../../shared/models';
import { SizeType } from '../../../../../shared/types';

@Component({
  selector: 'app-user-tags-dropdown-content',
  templateUrl: './user-tags-dropdown-content.component.html',
  styleUrls: ['./user-tags-dropdown-content.component.scss']
})
export class UserTagsDropdownContentComponent {
  @Input() filteredAutocompleteList$: Observable<Tag[]>;
  @Input() showDropdownInfo: ShowBlockInfo;

  @Input() sizeType: SizeType = 'standard';
  @Input() holdDownSiblingsSelector: string;
  @Input() holdDownSiblingsClass: string;

  @Output() hideDropdownOnClickOutside = new EventEmitter();
  @Output() setItem = new EventEmitter<CustomDropdownItemInfo>();

  clickHideDropdownOnClickOutside(): void {
    this.hideDropdownOnClickOutside.emit(null);
  }

  clickSetItem(event: MouseEvent, item: Tag): void {
    const info: CustomDropdownItemInfo = {
      event,
      autocomplete: item
    };

    this.setItem.emit(info);
  }
}

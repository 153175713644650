import { Autocomplete, Tag } from '../../models';
import {
  CategoryAutocompletes,
  SearchCategoryAutocomplete
} from '../../models/search/searchCategory';

export class TagAutocomplete implements SearchCategoryAutocomplete {
  constructor() {}

  private static getAutocomplete(item: Tag): Autocomplete {
    const id: string = item?.tagId;

    return {
      name: item?.name,
      id
    };
  }

  static getAutocompleteResult(autocompleteList: CategoryAutocompletes): Autocomplete[] {
    return (autocompleteList as Tag[]).map((tag: Tag) => TagAutocomplete.getAutocomplete(tag));
  }
}

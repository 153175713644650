import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ConditionInfo, Position } from '../../../../../../shared/models';
import { CandidateCardHandlerService } from '../../../../../../shared/services';

@Component({
  selector: 'app-candidate-card-experience',
  templateUrl: './candidate-card-experience.component.html',
  styleUrls: ['./candidate-card-experience.component.scss']
})
export class CandidateCardExperienceComponent implements OnInit, OnDestroy {
  constructor(private handlerService: CandidateCardHandlerService) {}

  @Input() positions: Position[];
  @Input() revealed: boolean = false;
  @Input() limitAccess = false;

  private readonly collapsedLength = 1;
  private isExperienceCollapsed = true;
  private unsubscribe$ = new Subject<void>();

  positionsForShow: Position[];

  ngOnInit(): void {
    this.handlerService.revealedCardInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((info: ConditionInfo) => {
        this.onRevealInfoChange(info?.condition);
      });

    this.isExperienceCollapsed = this.positions?.length > 2;
    this.positionsForShow = this.getPositionsForShow();
  }

  private onRevealInfoChange(revealed: boolean): void {
    if (!this.limitAccess) {
      this.isExperienceCollapsed = !revealed;
      this.positionsForShow = this.getPositionsForShow();
    }
  }

  private getPositionsForShow(): Position[] {
    if (!this.isExperienceCollapsed || this.positions?.length <= this.collapsedLength) {
      return this.positions;
    }

    return this.positions.slice(0, this.collapsedLength);
  }

  ngOnDestroy(): void {
    this.positionsForShow = null;
    this.isExperienceCollapsed = false;

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

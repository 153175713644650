<div
  *ngIf="disableScrollBar$ | async as disableScrollBar"
  class="app-component scroll-wrapper vertical full-height {{ systemLocale$ | async }}"
  data-html2canvas-ignore="true"
  [ngClass]="{'landing': isItLanding}">
  <app-scrollbar
    #scrollbar
    class="scrollbar global-scrollbar"
    cdkScrollable
    [vertical]="true"
    [size]="'large'"
    [disabled]="disableScrollBar.condition">

    <router-outlet *ngIf="isRotesDataInit$ | async"></router-outlet>

  </app-scrollbar>

  <app-footer></app-footer>
</div>

import { Injectable } from '@angular/core';

import { ModalSubmitBtnType, ModalSubmitWithClosingBtnType } from '../models';

type ModalSubmitWithWithClothingBtnInfo = {
  [type in ModalSubmitWithClosingBtnType]: string;
};

type ModalSubmitActionBtnInfo = {
  [type in ModalSubmitBtnType]: string;
};

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor() {}

  submitWithClothingBtnInfo: ModalSubmitWithWithClothingBtnInfo = {
    continue: 'GENERAL.CONTINUE',
    delete: 'GENERAL.YES_DELETE',
    signOut: 'GENERAL.YES_SIGN_OUT',
    confirm: 'GENERAL.CONFIRM'
  };

  submitBtnInfo: ModalSubmitActionBtnInfo = {
    done: 'GENERAL.DONE',
    change: 'GENERAL.CHANGE',
    save: 'GENERAL.SAVE',
    update: 'GENERAL.UPDATE',
    upgrade: 'GENERAL.UPGRADE'
  };

  getSubmitWithClothingBtnText(type: ModalSubmitWithClosingBtnType): string {
    return this.submitWithClothingBtnInfo[type] || '';
  }

  getSubmitBtnText(type: ModalSubmitBtnType): string {
    return this.submitBtnInfo[type] || '';
  }
}

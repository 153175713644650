export enum CodesVariableNamesEnum {
  accessToken = 'gfb87unbduincj',
  refreshToken = 'vbf789hdfcn9d',
  isTokenRefreshing = 'rh7je4rfrjiefjei',
  users = '8hjg90jn59jg9',
  searchList = '6tyguiuoioni',
  categorySwitchersState = 'fvb739hrfin3if0i',
  locale = 'uhiwnfiwf903hf09f09j2',
  adminFilterList = 'jbv0h0v3hn0vin3f',
  reviewedFilterInfo = 'ifh383h0h08hfh30hf03hf',
  UTMSource = 'vdy3gd9b3dn082n9ms902m20s9',
  extensionAdCloseDate = 'kh8lkjhd026f9ej9kh',
  testExtensionId = 'testExtensionId'
}

import { Component, Input, OnInit } from '@angular/core';

import { Candidate } from '../../../../../../shared/models';
import { SkillsService } from '../../../../../../shared/services';

@Component({
  selector: 'app-download-profile-c-v-main-content',
  templateUrl: './download-profile-c-v-main-content.component.html',
  styleUrls: ['./download-profile-c-v-main-content.component.scss']
})
export class DownloadProfileCVMainContentComponent implements OnInit {
  constructor(private skillsService: SkillsService) {}

  @Input() profile: Candidate;

  mainSkillList: string[] = [];
  secondarySkillList: string[] = [];

  ngOnInit(): void {
    if (this.profile) {
      this.setSkillList();
    }
  }

  private setSkillList(): void {
    this.mainSkillList = this.skillsService.getMainSkillNameList(this.profile.skillGroupsMain);
    this.secondarySkillList = this.skillsService.getSecondarySkillNameList(
      this.profile.skillsSecondary
    );
  }
}

import { Autocomplete, PositionSynonym } from '../../models';
import {
  CategoryAutocompletes,
  SearchCategoryAutocomplete
} from '../../models/search/searchCategory';

export class PositionAutocomplete implements SearchCategoryAutocomplete {
  constructor() {}

  static getAutocomplete(item: PositionSynonym): Autocomplete {
    return {
      name: item?.positionName,
      id: item?.positionName
    };
  }

  static getAutocompleteResult(autocompleteList: CategoryAutocompletes): Autocomplete[] {
    return (autocompleteList as PositionSynonym[]).map((positionSynonym: PositionSynonym) => {
      return PositionAutocomplete.getAutocomplete(positionSynonym);
    });
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { CandidateCardHandlerService, TagService } from '../../../../../../shared/services';
import { ConditionInfo, Tag } from '../../../../../../shared/models';

@Component({
  selector: 'app-candidate-card-tags',
  templateUrl: './candidate-card-tags.component.html',
  styleUrls: ['./candidate-card-tags.component.scss']
})
export class CandidateCardTagsComponent implements OnInit, OnDestroy {
  constructor(
    private tagService: TagService,
    private cardHandler: CandidateCardHandlerService
  ) {}

  @Input() docId: string;
  @Input() revealed: boolean = false;

  readonly gap = 10;
  readonly visibleRowNumber = 1;

  private updateTags$ = new Subject<Tag[]>();
  private unsubscribe$ = new Subject<void>();

  tags$: Observable<Tag[]> = this.updateTags$.asObservable();
  revealedCardInfo$: Observable<ConditionInfo>;

  ngOnInit(): void {
    this.setRevealedInfoPipe();
    this.onTagsChanged();
    this.setTagsPipe();
  }

  private setRevealedInfoPipe(): void {
    this.revealedCardInfo$ = this.cardHandler.revealedCardInfo$.pipe(
      map((info: ConditionInfo) => info)
    );
  }

  private onTagsChanged(): void {
    this.cardHandler.launchUpdateTags$
      .pipe(switchMap(() => this.tagService.getProfileTags(this.docId)))
      .subscribe((tags: Tag[]) => {
        this.updateTags(tags);
      });
  }

  private setTagsPipe(): void {
    this.tagService
      .getProfileTags(this.docId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((tags: Tag[] = []) => {
        this.updateTags(tags);
      });
  }

  private updateTags(tags: Tag[]): void {
    this.updateTags$.next(tags);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

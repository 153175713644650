import { NgModule } from '@angular/core';
import { NotifierModule, NotifierOptions } from 'angular-notifier';

const notifierOptions: NotifierOptions = {
  position: {
    vertical: {
      position: 'top',
      distance: 20,
      gap: 10
    },
    horizontal: {
      position: 'right',
      distance: 35
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: false,
    onClick: false,
    onMouseover: 'pauseAutoHide',
    showDismissButton: false,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};
@NgModule({
  imports: [NotifierModule.withConfig(notifierOptions)],
  exports: [NotifierModule]
})
export class NotificationModule {}

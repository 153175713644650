import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ShowBlockInfo, Tag, TagLists } from '../../../../../shared/models';
import { CandidateCardEnum } from '../../../../../shared/enums';
import { SizeType } from '../../../../../shared/types';

@Component({
  selector: 'app-add-tag-block',
  templateUrl: './add-tag-block.component.html',
  styleUrls: ['./add-tag-block.component.scss']
})
export class AddTagBlockComponent {
  constructor() {}

  @Input() type: 'standard' | 'full' = 'standard';
  @Input() dropdownSizeType: SizeType = 'standard';

  readonly holdDownSiblingsSelector = CandidateCardEnum.holdDownSiblingsSelector;
  readonly holdDownSiblingsClass = CandidateCardEnum.holdDownSiblingsClass;

  private profileTags: Tag[];
  private userTags: Tag[];
  private filteredUserTagsSubject = new BehaviorSubject<Tag[]>([]);

  dropdownBlockInfo: ShowBlockInfo = { show: false };
  filteredUserTags$: Observable<Tag[]> = this.filteredUserTagsSubject.asObservable();

  @Input() set tagLists(lists: TagLists) {
    // dynamic param - changes on actions
    if (lists) {
      this.profileTags = lists.profileTags;
      this.userTags = lists.userTags;

      this.filterUserTags();
    }
  }

  @Output() addTag = new EventEmitter<Tag>();

  get noTagSelected(): boolean {
    return !this.profileTags?.length;
  }

  clickAddNewTag(event: Event): void {
    event.stopPropagation();
    this.showDropdown();
  }

  onAddTag(tag: Tag): void {
    if (tag) {
      this.hideDropdown();
      this.addTag.emit(tag);
    }
  }

  private filterUserTags(): void {
    if (!this.userTags) {
      this.userTags = [];
    }

    let filteredUserTags: Tag[];

    if (this.profileTags) {
      filteredUserTags = this.userTags.filter(
        (userTag: Tag) => !this.profileTags.some((tag: Tag) => tag.tagId === userTag.tagId)
      );
    } else {
      filteredUserTags = this.userTags;
    }

    if (!filteredUserTags.length) {
      this.hideDropdown();
    }

    this.filteredUserTagsSubject.next(filteredUserTags);
  }

  private showDropdown(): void {
    this.dropdownBlockInfo.show = true;
  }

  private hideDropdown(): void {
    this.dropdownBlockInfo.show = false;
  }
}

import { Injectable } from '@angular/core';

import { FontsInfo } from '../../../../shared/models/candidate/candidateCVGenerator';

@Injectable({
  providedIn: 'root'
})
export class CandidatesCVGeneratorService {
  constructor() {}

  private _fontsInfo: FontsInfo;

  set fonts(fontsInfo: FontsInfo) {
    this._fontsInfo = fontsInfo;
  }

  get fonts(): FontsInfo {
    return this._fontsInfo;
  }
}

import { Injectable } from '@angular/core';

import {
  ContactBaseInfo,
  ContactInfo,
  ContactInfoItem,
  ContactsInfoMap,
  ContactTypeInfo,
  ContactUserDto
} from '../../models';
import { ContactExtendedType, ContactType } from '../../types';
import { CandidateContactsData } from './candidate-contacts.data';
import { NotificationService } from '../helpers';
import { NotificationTypeEnum } from '../../enums';

@Injectable({
  providedIn: 'root'
})
export class CandidateContactsService {
  constructor(private notificationService: NotificationService) {}

  isSystemContact(contact: ContactUserDto): boolean {
    const { contactId, contactUserId } = contact;

    return !!(contactId || !contactUserId);
  }

  isPhoneType(type: ContactExtendedType): boolean {
    const phoneTypes: ContactExtendedType[] = ['PHONE', 'PHONE_WORK', 'PHONE_HOME'];

    return phoneTypes.some((item: ContactExtendedType) => type === item);
  }

  isAnyContactHidden(contacts: ContactUserDto[]): boolean {
    return contacts.some((item: ContactUserDto) => item.hide);
  }

  getContactTypeInfoList(activeType: ContactExtendedType = null): ContactTypeInfo[] {
    const list: ContactTypeInfo[] = CandidateContactsData.contactTypeInfoList();

    list.forEach((listItem: ContactTypeInfo) => {
      listItem.active = activeType && listItem.type === activeType;
    });

    return list;
  }

  getContactsData(contacts: ContactUserDto[]): ContactsInfoMap {
    const contactsInfo: ContactInfo = this.getContactsInfo(contacts);

    return this.getContactsInfoMap(contactsInfo);
  }

  getContactsInfo(contacts: ContactUserDto[]): ContactInfo {
    if (contacts) {
      let contactsInfo: ContactInfo = {};

      this.handleGettingContacts(contacts, contactsInfo);
      this.sortContactInfoValues(contactsInfo);

      return contactsInfo;
    }

    return null;
  }

  getContactsInfoMap(contactInfo: ContactInfo): ReadonlyMap<string, ContactInfoItem> {
    const list: [string, ContactInfoItem][] = contactInfo
      ? Object.keys(contactInfo).map((key: string) => [key, contactInfo[key]])
      : null;

    return new Map(list);
  }

  getContactDto(
    contact?: ContactUserDto,
    updatedInfo?: ContactBaseInfo,
    docId?: string
  ): ContactUserDto {
    let dto: ContactUserDto = null;
    const contactType: ContactExtendedType = updatedInfo
      ? updatedInfo.contactType
      : contact
      ? contact.contactType
      : null;
    const value: string = updatedInfo ? updatedInfo.value : contact ? contact.value : null;
    docId = docId ? docId : contact.docId;

    if (contactType && value) {
      dto = { contactType, value, docId };

      if (contact?.contactId) {
        dto.contactId = contact.contactId;
      }

      if (contact?.contactUserId) {
        dto.contactUserId = contact.contactUserId;
      }
    }

    return dto;
  }

  showContactsSearchResultNotification(
    prevContactsNumber: number,
    newContactsNumber: number
  ): void {
    const moreContactsFound: boolean = newContactsNumber > prevContactsNumber;
    const noInitialContacts: boolean = prevContactsNumber === 0;

    const noficationType = moreContactsFound
      ? NotificationTypeEnum.SUCCESS
      : NotificationTypeEnum.ERROR;

    let notificationMessage = '';

    if (moreContactsFound) {
      if (noInitialContacts) {
        notificationMessage = 'NOTIFICATION.CONTACTS_FOUND';
      } else {
        notificationMessage = 'NOTIFICATION.NEW_CONTACTS_FOUND';
      }
    }

    if (!moreContactsFound) {
      if (noInitialContacts) {
        notificationMessage = 'NOTIFICATION.CONTACTS_NOT_FOUND';
      } else {
        notificationMessage = 'NOTIFICATION.NEW_CONTACTS_NOT_FOUND';
      }
    }

    this.notificationService.notify(noficationType, notificationMessage);
  }

  private getNewContactInfo(contact: ContactUserDto): ContactInfoItem {
    const particularContactNameItem: ContactInfoItem = this.getParticularContactNameItem(contact);
    const contactType: ContactType = contact.contactType as ContactType;

    return {
      contactType,
      values: [contact],
      contactTitle: particularContactNameItem?.contactTitle,
      contactUniversalTitle: particularContactNameItem?.contactUniversalTitle
    };
  }

  private getParticularContactNameItem(contact: ContactUserDto): ContactInfoItem {
    return CandidateContactsData.contactNames.find(
      (contactName: ContactInfoItem) => contactName.contactType === contact.contactType
    );
  }

  private handleGettingContacts(contacts: ContactUserDto[], contactsInfo: ContactInfo): void {
    contacts.forEach((contact: ContactUserDto) => {
      CandidateContactsService.handleContactType(contact);

      const particularContactInfo: ContactInfoItem =
        CandidateContactsService.getParticularContactInfo(contact, contactsInfo);

      if (particularContactInfo) {
        particularContactInfo.values.push(contact);
      } else {
        contactsInfo[contact.contactType] = this.getNewContactInfo(contact);
      }
    });
  }

  private sortContactInfoValues(contactInfo: ContactInfo): void {
    Object.values(contactInfo).forEach((contactInfo: ContactInfoItem) => {
      contactInfo.values = contactInfo.values.sort((contact: ContactUserDto, _) => {
        const isSystemContact = this.isSystemContact(contact);

        return isSystemContact ? -1 : 1;
      });
    });
  }

  private static handleContactType(contact: ContactUserDto): void {
    if (contact.contactType === 'PHONE_WORK' || contact.contactType === 'PHONE_HOME') {
      contact.contactType = 'PHONE';
    }
  }

  private static getParticularContactInfo(
    contact: ContactUserDto,
    contactsInfo: ContactInfo
  ): ContactInfoItem {
    return contactsInfo?.hasOwnProperty(contact.contactType) && contactsInfo[contact.contactType];
  }
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { ProfileService } from '../../../../../shared/services/requests';
import {
  AddCandidateToFolderModalData,
  AddCandidateToFolderModalResult,
  Candidate,
  Folder,
  ModalInfo
} from '../../../../../shared/models';
import { CandidateImageHandlerService } from '../../../../../shared/services';

@Component({
  selector: 'app-add-candidate-to-folder-modal',
  templateUrl: './add-candidate-to-folder-modal.component.html',
  styleUrls: ['./add-candidate-to-folder-modal.component.scss']
})
export class AddCandidateToFolderModalComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<AddCandidateToFolderModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddCandidateToFolderModalData,
    private profileService: ProfileService,
    private imageHandler: CandidateImageHandlerService
  ) {}

  info: ModalInfo = {
    type: 'simple-modal',
    extraCssClass: 'add-candidate-to-folder-modal',
    closeModalBtnId: 'close-add-candidate-folder-m',
    static: {
      height: true,
      title: true,
      text: true
    },
    header: {
      title: 'FOLDERS.ADD_CANDIDATE.ADD_TO_FOLDER',
      text: 'FOLDERS.ADD_CANDIDATE.MODAL_TEXT',
      withForm: true
    },
    actions: {
      cancel: {
        id: 'm-cancel-add-candidate-to-folder--btn'
      },
      submit: {
        id: 'm-add-candidate-to-folder--btn',
        type: 'done',
        extraClass: 'bright'
      }
    }
  };

  form: UntypedFormArray;
  selectedFolderNumber = 0;
  removedFolderNumber = 0;

  private unsubscribe$ = new Subject<void>();
  private candidatePictureUrlSubject = new BehaviorSubject<SafeUrl>(null);

  candidatePictureUrl$: Observable<SafeUrl> = this.candidatePictureUrlSubject.asObservable();

  ngOnInit(): void {
    this.initForm();
    this.handleFormValueChanges();

    this.setCandidatePictureUrl();
  }

  closeModal(result: AddCandidateToFolderModalResult = null): void {
    this.dialogRef.close(result);
  }

  onSubmit(): void {
    if (this.form?.value?.length) {
      const foldersToAdd: Folder[] = [];
      const foldersToRemove: Folder[] = [];

      this.form.value.forEach((folder: Folder) => {
        if (folder.candidateAdded && !folder.haveProfile) {
          foldersToAdd.push(folder);
        } else if (!folder.candidateAdded && folder.haveProfile) {
          foldersToRemove.push(folder);
        }
      });

      this.closeModal({
        foldersToAdd,
        foldersToRemove
      });
    }
  }

  toggleCandidateToFolder(folder: Folder): void {
    if (this.form?.value?.length) {
      this.successToggleCandidateToFolder(folder);
      this.setSubmitBtnDisabledCondition();
    }
  }

  private successToggleCandidateToFolder(folder: Folder): void {
    this.form.value.some((folderItem: Folder, index: number) => {
      const condition: boolean = folderItem.id === folder.id;

      if (condition) {
        this.form.value[index].candidateAdded = !this.form.value[index].candidateAdded;

        if (this.form.value[index].candidateAdded) {
          if (!folder.haveProfile) {
            this.selectedFolderNumber++;
          } else {
            this.removedFolderNumber++;
          }

          this.form.value[index].candidatesCount++;
        } else {
          if (!folder.haveProfile) {
            this.selectedFolderNumber--;
          } else {
            this.removedFolderNumber--;
          }

          this.form.value[index].candidatesCount--;
        }
      }

      return condition;
    });
  }

  private initForm(): void {
    this.form = new UntypedFormArray([]);

    if (this.data.folders?.length) {
      this.data.folders.forEach((folder: Folder) => {
        folder.candidateAdded = folder.haveProfile;

        this.form.push(new UntypedFormControl({ ...folder }));
      });
    }
  }

  private handleFormValueChanges(): void {
    this.setSubmitBtnDisabledCondition();

    this.form.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.setSubmitBtnDisabledCondition();
    });
  }

  private setCandidatePictureUrl(): void {
    if (this.data?.candidate) {
      this.setCandidatePictureUrlWithCandidate(this.data.candidate);
    } else if (this.data?.docId) {
      this.setCandidatePictureUrlWithoutCandidate(this.data.docId);
    }
  }

  private setCandidatePictureUrlWithCandidate(candidate: Candidate): void {
    this.setCandidatePictureUrlOnSuccess(candidate);
  }

  private setCandidatePictureUrlWithoutCandidate(docId: string): void {
    this.profileService.currentCandidate$
      .pipe(
        filter((candidate: Candidate) => candidate?.docId === docId),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((candidate: Candidate) => {
        this.setCandidatePictureUrlOnSuccess(candidate);
      });
  }

  private setCandidatePictureUrlOnSuccess(candidate: Candidate): void {
    if (candidate?.pictureUrl) {
      this.updateCandidatePictureUrl(candidate.pictureUrl);

      return;
    }

    if (candidate) {
      this.imageHandler
        .getCandidateImageUrl(candidate)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((pictureUrl: SafeUrl) => {
          candidate.pictureUrl = pictureUrl as string;

          this.updateCandidatePictureUrl(pictureUrl);
        });
    }
  }

  private updateCandidatePictureUrl(url: SafeUrl): void {
    this.candidatePictureUrlSubject.next(url);
  }

  private setSubmitBtnDisabledCondition(): void {
    this.info.actions.submit.disabled =
      this.form.invalid || (!this.selectedFolderNumber && !this.removedFolderNumber);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

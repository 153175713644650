import { Injectable } from '@angular/core';

import { DropdownInfo, HoldDownDropdownSiblingsInfo } from '../models';
import { DocumentService } from './global';

@Injectable({
  providedIn: 'root'
})
export class DropdownsService {
  constructor(private document: DocumentService) {}

  private dropdownList: DropdownInfo[] = [];

  add(info: DropdownInfo): void {
    if (!this.dropdownList.some((item: DropdownInfo) => item.element.isSameNode(info.element))) {
      this.dropdownList.push(info);
    }
  }

  hideOther(exceptionElement: HTMLElement): void {
    if (this.dropdownList.length) {
      this.dropdownList.forEach((item: DropdownInfo) => {
        const { element, showInfo } = item;

        if (!element.isSameNode(exceptionElement) && showInfo.show) {
          showInfo.show = false;
        }
      });
    }
  }

  handleByInfo(info: HoldDownDropdownSiblingsInfo, showDropdown: boolean = false): void {
    if (info?.selector) {
      if (showDropdown) {
        this.handle({
          ...info,
          itemZIndex: '0',
          currentZIndex: '1'
        });
      } else {
        this.handle({
          ...info,
          itemZIndex: '1'
        });
      }
    }
  }

  handle(info: HoldDownDropdownSiblingsInfo): void {
    const { element, selector, parentClass, itemZIndex, currentZIndex = '' } = info;

    if (selector && parentClass) {
      const parent: HTMLElement = DropdownsService.findAncestor(element, parentClass);

      if (parent) {
        const parentList: NodeListOf<HTMLElement> = this.document.querySelectorAll(selector);

        if (parentList) {
          Array.from(parentList).forEach((item: HTMLElement) => {
            item.style.zIndex = itemZIndex;
          });
        }

        if (currentZIndex) {
          parent.style.zIndex = currentZIndex;
        }
      }
    }
  }

  private static findAncestor(element: HTMLElement, selector: string): HTMLElement {
    let currentElement: HTMLElement = element;

    while (
      currentElement &&
      (!currentElement?.parentElement || !currentElement.parentElement.classList.contains(selector))
    ) {
      currentElement = currentElement.parentElement;
    }

    return currentElement?.parentElement;
  }
}

import { Component, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

import { Candidate, SimpleCandidate } from '../../../../../../shared/models';

@Component({
  selector: 'app-download-profile-c-v-header',
  templateUrl: './download-profile-c-v-header.component.html',
  styleUrls: ['./download-profile-c-v-header.component.scss']
})
export class DownloadProfileCVHeaderComponent {
  constructor() {}

  @Input() profile: Candidate | SimpleCandidate;
  @Input() image: SafeResourceUrl | string;

  imageLoadFailed = false;

  onError(): void {
    this.profile.picture = '';
    this.profile.pictureUrl = '';
    this.imageLoadFailed = true;
  }
}

import { FilterParam, NotViewedFilterDefaultData } from '../../enums';
import { QueryLabelInfo, SearchQueryItemInfo } from '../../models';
import { FilterExecutantType, FilterIncludeType } from '../../types';
import { StorageService } from '../../services';

export class ReviewedFilter {
  // ft = filter type

  private readonly ftByMe = FilterParam.notViewed;
  private readonly ftByMeDays = FilterParam.notViewedLastDays;

  private readonly ftByCommand = FilterParam.notViewedByAccount;
  private readonly ftByCommandDays = FilterParam.notViewedByAccountLastDays;

  constructor(public data: ReviewedFilterInfo) {}

  static getInitData(): ReviewedFilterInfo {
    return new ReviewedFilterInfo(
      NotViewedFilterDefaultData.includeType,
      NotViewedFilterDefaultData.dayNumber,
      NotViewedFilterDefaultData.executantType
    );
  }

  static getInitDataFromStorage(dataFromStorage: ReviewedFilterInfo): ReviewedFilterInfo {
    const includeType: FilterIncludeType = NotViewedFilterDefaultData.includeType;
    const dayNumber: number = this.getDayNumberFromStorage(dataFromStorage);
    const executantType: FilterExecutantType = this.getExecutantTypeFromStorage(dataFromStorage);

    return new ReviewedFilterInfo(includeType, dayNumber, executantType);
  }

  doesHaveAnyValue(): boolean {
    const { includeType } = this.data;

    return includeType !== 'none';
  }

  getContentString(): string | string[] {
    let filter = '';

    if (this.data) {
      return this.getFilterWithValidData();
    }

    return filter;
  }

  setDataFromSearchLine(info: SearchQueryItemInfo): void {
    if (info) {
      this.handleSetDataItem(info);
      StorageService.setReviewedFilterInfo(this.data);
    }
  }

  getLabelInfo(): QueryLabelInfo {
    const { includeType, dayNumber } = this.data;

    if (includeType !== 'none') {
      const label = this.getFullLabel();

      return {
        value: label,
        translateParam: dayNumber,
        excluded: includeType === 'exclude'
      };
    }

    return null;
  }

  getFullLabel(): string {
    const { dayNumber, executantType } = this.data;
    const lastTranslateKey = executantType === 'me' ? 'ME' : 'COMMAND';
    const labelPreTranslate =
      dayNumber === 1 ? 'FILTER.REVIEWED.LABEL_FULL.SINGLE' : 'FILTER.REVIEWED.LABEL_FULL.MULTIPLE';

    return `${labelPreTranslate}.${lastTranslateKey}`;
  }

  private getFilterWithValidData(): string[] {
    const { includeType, executantType, dayNumber } = this.data;

    if (includeType !== 'none') {
      const notViewed: boolean = includeType === 'exclude';
      const notViewedFilterType = executantType === 'me' ? this.ftByMe : this.ftByCommand;
      const daysFilterType = executantType === 'me' ? this.ftByMeDays : this.ftByCommandDays;

      const notViewedFilter: string = `${notViewedFilterType}[0]:${notViewed}`;
      const daysFilter: string = `${daysFilterType}[0]:${dayNumber}`;

      return [notViewedFilter, daysFilter];
    }
  }

  private handleSetDataItem(item: SearchQueryItemInfo): void {
    const { filterParam, searchText } = item;

    if (filterParam === this.ftByMe || filterParam === this.ftByMeDays) {
      this.data.executantType = 'me';
    } else if (filterParam === this.ftByCommand || filterParam === this.ftByCommandDays) {
      this.data.executantType = 'command';
    }

    if (filterParam === this.ftByMe || filterParam === this.ftByCommand) {
      this.data.includeType =
        searchText === 'true' ? 'exclude' : searchText === 'false' ? 'include' : 'none';
    } else if (filterParam === this.ftByMeDays || filterParam === this.ftByCommandDays) {
      this.data.dayNumber = searchText
        ? parseInt(searchText)
        : NotViewedFilterDefaultData.dayNumber;
    }
  }

  private static getDayNumberFromStorage(data: ReviewedFilterInfo): number {
    const { dayNumber } = data;

    if (
      !dayNumber ||
      typeof dayNumber !== 'number' ||
      dayNumber > NotViewedFilterDefaultData.maxDayNumber
    ) {
      return NotViewedFilterDefaultData.dayNumber;
    }

    return data.dayNumber;
  }

  private static getExecutantTypeFromStorage(
    dataFromStorage: ReviewedFilterInfo
  ): FilterExecutantType {
    const { executantType } = dataFromStorage;

    if (!executantType || (executantType !== 'me' && executantType !== 'command')) {
      return NotViewedFilterDefaultData.executantType;
    }

    return dataFromStorage.executantType;
  }
}

export class ReviewedFilterInfo {
  constructor(
    public includeType: FilterIncludeType,
    public dayNumber: number,
    public executantType: FilterExecutantType
  ) {}

  static getInstant(data: ReviewedFilterInfo): ReviewedFilterInfo {
    return new ReviewedFilterInfo(data.includeType, data.dayNumber, data.executantType);
  }

  static getNotViewedInitData(): ReviewedFilterInfo {
    const dataFromStorage: ReviewedFilterInfo = StorageService.getReviewedFilterInfo();

    if (dataFromStorage) {
      return ReviewedFilter.getInitDataFromStorage(dataFromStorage);
    }

    return ReviewedFilter.getInitData();
  }

  toggleInclude(includeType: FilterIncludeType): void {
    if (this.includeType === includeType) {
      this.includeType = 'none';
    } else {
      this.includeType =
        this.includeType === 'include'
          ? 'exclude'
          : this.includeType === 'exclude'
          ? 'include'
          : includeType;
    }

    this.handleEmptyDayNumber();
  }

  toggleExecutant(executantType: FilterExecutantType): void {
    if (this.executantType === executantType) {
      this.executantType = executantType === 'me' ? 'command' : 'me';
    } else {
      this.executantType = executantType === 'me' ? 'me' : 'command';
    }

    this.handleEmptyDayNumber();
  }

  private handleEmptyDayNumber(): void {
    if (!this.dayNumber) {
      this.dayNumber = NotViewedFilterDefaultData.dayNumber;
    }
  }

  changeDayNumber(dayNumber: number): void {
    this.changeDayNumberByType(dayNumber, 'dayNumber');
  }

  private changeDayNumberByType(dayNumber: number, type: 'dayNumber'): void {
    if (
      dayNumber >= NotViewedFilterDefaultData.minDayLength &&
      dayNumber <= NotViewedFilterDefaultData.maxDayNumber
    ) {
      this[type] = typeof dayNumber === 'number' ? dayNumber : parseInt(dayNumber);
    } else {
      this[type] = null;
    }
  }
}

<span class="profile-contacts">
  <ng-container *ngFor="let item of contactsData | keyvalue; index as i">
    <span class="contact__title">
      {{ (item.value.contactTitle | translate) + ':' }}
    </span>

    <ng-container *ngFor="let contact of item.value.values; index as j">
      <span 
        *ngIf="contact.hide; else userAddedContact"
        class="contact__name hidden-contact"
        [class.last]="j === item.value.values.length - 1">
        {{ '...' + contact.value }}
      </span>

      <ng-template #userAddedContact>
        <span
          appCandidateContactHandler
          class="contact__name open-contact"
          [class.last]="j === item.value.values.length - 1">
          {{ contact.value }}
        </span>
      </ng-template>
    </ng-container>
  </ng-container>
</span>

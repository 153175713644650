import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { ModalInfo } from '../../../../../shared/models';

@Component({
  selector: 'app-regenerate-api-key-modal',
  templateUrl: './regenerate-api-key-modal.component.html',
  styleUrls: ['./regenerate-api-key-modal.component.scss']
})
export class RegenerateApiKeyModalComponent {
  constructor(public dialogRef: MatDialogRef<RegenerateApiKeyModalComponent>) {}

  info: ModalInfo = {
    type: 'success-modal',
    closeModalBtnId: 'close-btn-m',
    header: {
      title: 'API.REGENERATE_MODAL.TITLE',
      text: 'API.REGENERATE_MODAL.TEXT'
    },
    actions: {
      cancel: {
        id: 'm-cancel-action-btn'
      },
      submitWithClothing: {
        id: 'm-submit-action-btn',
        extraClass: 'bright',
        type: 'continue'
      }
    }
  };

  closeModal(condition: boolean = false): void {
    this.dialogRef.close(condition);
  }
}

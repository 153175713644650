<app-modal
  [info]="info"
  (closeModal)="closeModal($event)"
  (onSubmit)="onSubmit()">
  <ng-template #scrollableContent>
    <div class="folders">
      <app-folder-card-small
        class="folders__item"
        *ngFor="let folder of form.value"
        [type]="'withPicture'"
        [folder]="folder"
        [candidateFullName]="data.candidate.fullName"
        [defaultColor]="data.defaultColor"
        [candidatePicture]="candidatePictureUrl$ | async"
        (toggleCandidate)="toggleCandidateToFolder(folder)">
      </app-folder-card-small>
    </div>
  </ng-template>
</app-modal>

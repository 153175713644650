import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CandidateModalData, ModalInfo } from '../../../../../shared/models';
import { CandidateCardHandlerService } from '../../../../../shared/services';

@Component({
  selector: 'app-add-comment-modal',
  templateUrl: './add-comment-modal.component.html',
  styleUrls: ['./add-comment-modal.component.scss']
})
export class AddCommentModalComponent {
  constructor(
    public dialogRef: MatDialogRef<AddCommentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CandidateModalData,
    private cardHandlerService: CandidateCardHandlerService
  ) {}

  info: ModalInfo = {
    type: 'simple-modal',
    extraCssClass: 'add-comment-modal',
    closeModalBtnId: 'close-add-comment-modal-btn',
    static: {
      height: true,
      title: true
    },
    header: {
      title: 'COMMENT.ADD_COMMENT',
      textContent: true
    }
  };

  closeModal(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.closeModal();
  }

  updateCommentsCount(count: number): void {
    this.cardHandlerService.updateCommentsCount(count);
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FolderStatusComponentType } from '../../../../../../shared/types';

@Component({
  selector: 'app-folder-status-circle',
  templateUrl: './folder-status-circle.component.html',
  styleUrls: ['./folder-status-circle.component.scss']
})
export class FolderStatusCircleComponent {
  constructor() {}

  @Input() size: 'standard' | 'large' = 'standard';
  @Input() type: FolderStatusComponentType;
  @Input() color: string;
  @Input() active: boolean;

  @Input() clickable: boolean = false;

  @Output() editStatusColor = new EventEmitter<null>();

  clickEditStatusColor(): void {
    this.editStatusColor.emit(null);
  }
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CandidateModalData, ModalInfo, Tag, TagLists } from '../../../../../shared/models';
import {
  CandidateCardHandlerService,
  NotificationService,
  TagService,
  TagsService
} from '../../../../../shared/services';
import { NotificationTypeEnum } from '../../../../../shared/enums';

@Component({
  selector: 'app-add-tag-modal',
  templateUrl: './add-tag-modal.component.html',
  styleUrls: ['./add-tag-modal.component.scss']
})
export class AddTagModalComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<AddTagModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CandidateModalData,
    private tagService: TagService,
    private tagsService: TagsService,
    private cardHandler: CandidateCardHandlerService,
    private notificationService: NotificationService
  ) {}

  private unsubscribe$ = new Subject<void>();
  private updateTagLists$ = new Subject<TagLists>();

  private profileTags: Tag[];
  private userTags: Tag[] = [];
  private addedTag: Tag;
  private removedTagId: string;

  info: ModalInfo = {
    type: 'simple-modal',
    extraCssClass: 'add-tag-modal',
    closeModalBtnId: 'close-add-tag-modal-btn',
    static: {
      height: true,
      title: true
    },
    header: {
      title: 'TAGS.ADD_TAG',
      textContent: true
    }
  };

  tagLists$: Observable<TagLists> = this.updateTagLists$.asObservable();

  ngOnInit(): void {
    this.setAllTags();
    this.onUpdateUserTags();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  addTag(tag: Tag): void {
    if (tag) {
      const { docId } = this.data.candidate;
      const profileId: string = this.data.candidate.profileIds[0];
      this.addedTag = { name: tag.name, tagId: tag.tagId };

      this.tagService
        .addProfileTag(tag.tagId, profileId, docId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          this.onTagAdded();
        });
    }
  }

  removeTag(tag: Tag): void {
    if (tag?.tagId) {
      const { name } = tag;
      const { docId } = this.data.candidate;
      const profileId: string = this.data.candidate.profileIds[0];
      this.removedTagId = tag.tagId;

      this.tagService
        .removeProfileTag(this.removedTagId, profileId, docId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          this.onTagRemoved(name);
        });
    }
  }

  private setAllTags(): void {
    if (this.data?.candidate?.docId) {
      combineLatest([
        this.tagService.getProfileTags(this.data.candidate.docId),
        this.tagService.getUserTags()
      ])
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(([profileTags = [], userTags = []]: [Tag[], Tag[]]) => {
          this.setUserTags(userTags);
          this.updateProfileTags(profileTags);
        });
    }
  }

  onUpdateUserTags(): void {
    if (!this.data?.candidate?.docId) {
      return;
    }

    this.tagsService
      .getUserTagUpdates()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((userTags: Tag[]) => {
        this.setUserTags(userTags);
        this.updateProfileTagNames(userTags);
        this.updateTagLists(this.profileTags, userTags);
      });
  }

  private updateProfileTags(profileTags: Tag[] = []): void {
    if (!profileTags) {
      profileTags = [];
    }

    this.profileTags = profileTags.filter((tag: Tag) => !!tag).slice();

    this.updateTagLists();
  }

  private updateProfileTagNames(userTags: Tag[]): void {
    this.profileTags.forEach((profileTag: Tag) => {
      const userTag = userTags.find((userTag: Tag) => userTag.tagId === profileTag.tagId);
      if (userTag) {
        profileTag.name = userTag.name;
      }
    });
  }

  private updateTagLists(profileTags: Tag[] = null, userTags: Tag[] = null): void {
    const tagLists: TagLists = {
      userTags: userTags ? userTags : this.userTags,
      profileTags: profileTags ? profileTags : this.profileTags
    };

    this.updateTagLists$.next(tagLists);
  }

  private setUserTags(userTags: Tag[] = []): void {
    if (!userTags) {
      userTags = [];
    }

    this.userTags = userTags.slice().reverse();
  }

  private onTagAdded(): void {
    if (this.addedTag) {
      if (!this.profileTags) {
        this.profileTags = [];
      }

      this.profileTags = [this.addedTag].concat(this.profileTags);

      this.updateProfileTags(this.profileTags);
      this.cardHandler.updateTagsCount(this.profileTags.length);
      this.notificationService.notify(
        NotificationTypeEnum.SUCCESS,
        'NOTIFICATION.TAG_SUCCESSFULLY_ADDED',
        { tagName: this.addedTag.name }
      );

      this.addedTag = null;
    }
  }

  private onTagRemoved(tagName: string): void {
    if (this.removedTagId) {
      if (!this.profileTags) {
        this.profileTags = [];
      }

      this.profileTags = this.profileTags.filter((tag: Tag) => tag.tagId !== this.removedTagId);

      this.updateProfileTags(this.profileTags);
      this.cardHandler.updateTagsCount(this.profileTags.length);
      this.notificationService.notify(
        NotificationTypeEnum.SUCCESS,
        'NOTIFICATION.TAG_SUCCESSFULLY_DELETED',
        { tagName }
      );
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Tag } from '../../models';
import { TabCommunicationService } from '../global';
import { TagService } from '../requests';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TagsService {
  constructor(
    private tagService: TagService,
    private tabCommunication: TabCommunicationService
  ) {}

  getUserTagUpdates(): Observable<Tag[]> {
    let waitingServerResponce = false;

    return this.tabCommunication.tagAction$.pipe(
      filter(() => {
        if (waitingServerResponce) {
          return false;
        }

        waitingServerResponce = true;
        return true;
      }),
      switchMap(() => this.tagService.getUserTags()),
      catchError((err) => {
        waitingServerResponce = false;
        throw err;
      }),
      tap(() => {
        waitingServerResponce = false;
      })
    );
  }
}

import { Component, Input } from '@angular/core';
import { ContactsInfoMap } from '../../../../../../shared/models';

@Component({
  selector: 'app-revealed-candidate-contacts',
  templateUrl: './revealed-candidate-contacts.component.html',
  styleUrls: ['./revealed-candidate-contacts.component.scss']
})
export class RevealedCandidateContactsComponent {
  @Input() contactsInfoMap: ContactsInfoMap;
}

<div
  *ngIf="folder"
  class="folder-card {{ type }}"
  [ngClass]="{'active': folder.candidateAdded}"
  (click)="clickFullCard($event)">
  <img
    *ngIf="type !== 'forCandidatePage'"
    alt=""
    width="241"
    height="124"
    class="folder-card__background-hover"
    src="assets/images/helper/folder-holder-on-hover-small.svg">

  <img
    *ngIf="type === 'forCandidatePage'"
    alt=""
    width="343"
    height="126"
    class="folder-card__background-hover"
    src="assets/images/helper/folder-holder-on-hover-large.svg">

  <div *ngIf="type === 'forCandidatePage'" class="folder-card__content">
    <a [routerLink]="['/folders',folder.id]" target="_blank" class="folder-card__info" >
      <app-folder-card-small-info [type]="type" [folder]="folder"></app-folder-card-small-info>
    </a>

    <div
      class="folder-card__action"
      (click)="clickRemoveCandidate($event)">
      <svg
        width="20"
        height="20"
        class="add-candidate-circle__sign minus">
        <use xlink:href="#minus">
        </use>
      </svg>
    </div>
  </div>

  <div *ngIf="type !== 'forCandidatePage'" class="folder-card__content">
    <div class="folder-card__info">
      <app-folder-card-small-info [type]="type" [folder]="folder"></app-folder-card-small-info>
    </div>

    <div
      *ngIf="type === 'withPicture'"
      class="folder-card__action">
      <div
        class="add-candidate-circle"
        [ngClass]="{'default': !candidatePicture}">
        <img
          class="add-candidate-circle__img"
          *ngIf="candidatePicture"
          alt=""
          [src]="candidatePicture"
          (error)="onError()">

        <span
          *ngIf="!candidatePicture"
          class="media-placeholder small">
          <span class="media-placeholder__text">
            <app-candidate-name
              [name]="candidateFullName"
              [getNameAbbreviation]="true">
            </app-candidate-name>
          </span>
        </span>

        <svg
          *ngIf="folder.candidateAdded"
          width="16"
          height="16"
          class="add-candidate-circle__sign minus">
          <use xlink:href="#minus">
          </use>
        </svg>

        <svg
          *ngIf="!folder.candidateAdded"
          width="16"
          height="16"
          class="add-candidate-circle__sign plus">
          <use xlink:href="#plus">
          </use>
        </svg>
      </div>
    </div>
  </div>
</div>

<div
  class="candidate-contacts">
  <ng-container *ngFor="let infoItem of contactsInfoMap | keyvalue">
    <div
      *ngIf="infoItem.value"
      class="candidate-contacts__row">
      <app-card-extend-contacts-row
        [redesigned]="true"
        [contactInfoItem]="infoItem.value">
      </app-card-extend-contacts-row>
    </div>
  </ng-container>
</div>

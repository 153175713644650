<ng-container *ngIf="tags$ | async as tags">
  <div *ngIf="tags?.length" class="profile-info small">
    <div *ngIf="revealed" class="profile-info__decoration">
      <div class="profile-info__icon-wrapper">
        <svg
          width="16"
          height="16"
          class="icon higher">
          <use xlink:href="#tags">
          </use>
        </svg>
      </div>
    </div>

    <div
      appCardBlockCollapse
      class="candidate-tags view-only card-collapse-block"
      [revealedInfo]="revealedCardInfo$ | async"
      [visibleRowNumber]="visibleRowNumber"
      [gap]="gap">
      <app-tag
        *ngFor="let tag of tags; index as i"
        [tag]="tag"
        [viewOnly]="true"
        appCardBlockItemCollapse>
      </app-tag>

      <span class="collapse-sign">...</span>
    </div>
  </div>
</ng-container>

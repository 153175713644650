import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TabCommunicationService {
  private broadcastChannel = new BroadcastChannel('user-action-events');

  private tagActionSubject = new Subject<{}>();

  tagAction$: Observable<{}> = this.tagActionSubject.asObservable();

  constructor(private zone: NgZone) {
    this.setMessageListener();
  }

  broadcastTagActionHappened(): void {
    this.broadcastChannel.postMessage({ actionOn: 'tag' });
  }

  private setMessageListener(): void {
    this.broadcastChannel.onmessage = (event: MessageEvent) => {
      // BrodcastChanges.onmessage callback is run outside angular zone so we need
      // wrapper to ensure that all service observables subscribers will be run inside angular zone.
      this.zone.run(() => this.handleMessage(event));
    };
  }

  private handleMessage(event: MessageEvent): void {
    if (!event?.data?.actionOn) {
      return;
    }

    const actionOn: string = event.data.actionOn;
    switch (actionOn) {
      case 'tag':
        this.tagActionSubject.next({});
        break;
    }
  }
}

<div *ngIf="skillNamesList$ | async as skillNamesList" class="profile-info small">
  <div *ngIf="revealed" class="profile-info__decoration small">
    <div class="profile-info__icon-wrapper">
      <svg
        width="16"
        height="16"
        class="icon higher">
        <use xlink:href="#skill-icon">
        </use>
      </svg>
    </div>
  </div>

  <div
    appCardBlockCollapse
    class="candidate-skills card-collapse-block"
    [revealedInfo]="revealedCardInfo$ | async"
    [visibleRowNumber]="2"
    [gap]="gap">
    <span
      *ngFor="let skill of skillNamesList"
      class="candidate-skill"
      appCardBlockItemCollapse>
      {{ skill.name }}
    </span>

    <span class="collapse-sign small">...</span>
  </div>
</div>

<div *ngIf="show$ | async" class="extension-block">
  <div class="extension-block__content">
    <span class="h7 extension-block__description">
      {{ 'EXTENSION.USER_PROFILE.DESCRIPTION' | translate }}
    </span>

    <a [href]="extensionLink" target="_blank" class="extension-block__install-btn">
      <h6 class="extension-block__install-btn-text">
        {{ 'EXTENSION.INSTALL_BUTTON' | translate }}
      </h6>
    </a>

    <button
      (click)="close()"
      class="extension-block__close">
      <svg
        *ngIf="true"
        width="8"
        height="8"
        class="icon">
        <use xlink:href="#large-cross"></use>
      </svg>
    </button>
  </div>
</div>

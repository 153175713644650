import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  constructor() {}

  doesObjectEmpty(object: Object): boolean {
    return !(object && Object.keys(object).length && object.constructor === Object);
  }
}

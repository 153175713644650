import { RangeInfo } from '../../../../../shared/models';

export class RangeFilterData {
  static getInitExperienceRange(): RangeInfo {
    return {
      start: 0,
      finish: 10
    };
  }
}

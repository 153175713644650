import { FilterCheckboxItem } from '../../../../../shared/models';

export class LevelFilterData {
  static getInitLevelListForFilter(): FilterCheckboxItem[] {
    return [
      {
        value: 'junior',
        label: 'LEVELS.JUNIOR',
        id: 0,
        specificFacetType: 'level_junior',
        checked: false
      },
      {
        value: 'middle',
        label: 'LEVELS.MIDDLE',
        id: 1,
        specificFacetType: 'level_middle',
        checked: false
      },
      {
        value: 'senior',
        label: 'LEVELS.SENIOR',
        id: 2,
        specificFacetType: 'level_senior',
        checked: false
      },
      {
        value: 'teamlead',
        label: 'LEVELS.TEAMLEAD',
        id: 3,
        specificFacetType: 'level_teamlead',
        checked: false
      },
      {
        value: 'architect',
        label: 'LEVELS.ARCHITECT',
        id: 4,
        specificFacetType: 'level_architect',
        checked: false
      },
      {
        value: 'cto',
        label: 'LEVELS.CTO',
        id: 5,
        specificFacetType: 'level_cto',
        checked: false
      },
      {
        value: 'pm',
        label: 'LEVELS.PM',
        id: 6,
        specificFacetType: 'level_pm',
        checked: false
      }
    ];
  }
}

import { Locale, MultiLangLinkInfo } from '../models';

export const systemLocales: Locale[] = ['en', 'ua', 'pl'];

export const multiLangLinkInfo: MultiLangLinkInfo = {
  en: {
    langCode: 'en',
    href: 'https://wandify.io/en'
  },
  ua: {
    langCode: 'uk',
    href: 'https://wandify.io/ua'
  },
  pl: {
    langCode: 'pl',
    href: 'https://wandify.io/pl'
  }
};

<div class="dropdown-menu">
  <div class="entry" (click)="selectThisPage()">
    <h6>{{ 'SEARCH.SELECT_THIS_PAGE' | translate }} ({{ profilesOnCurrentPage }})</h6>
  </div>

  <div class="entry" (click)="selectAll()">
    <h6>{{ 'SEARCH.SELECT_ALL' | translate }} ({{ totalProfiles | max : maxSelectableProfiles | numberWithSpaces }})</h6>
  </div>

  <div *ngIf="withRange && totalProfiles > 1" class="entry entry__range">
    <form (onSubmit)="selectRange()" autocomplete="off">
      <div class="range">
        <h6>{{ 'SEARCH.SELECT_RANGE.FROM' | translate }}</h6>
        <input 
          name="range-from" 
          type="text"
          placeholder="1"
          autocomplete="off"
          #rangeFrom 
          [formControl]="fromControl" >
        <h6 class="range-to">{{ 'SEARCH.SELECT_RANGE.TO' | translate }}</h6>
        <input 
          name="range-to" 
          type="text"
          [placeholder]="totalProfiles | max: maxSelectableProfiles | numberWithSpaces"
          autocomplete="off"
          #rangeTo 
          [formControl]="toControl" >
      </div>
      <button
        class="select-range-btn btn btn--new-primary bright"
        (click)="selectRange()"
        [disabled]="!rangeValid">
          {{ 'SEARCH.SELECT_RANGE.BUTTON' | translate }}
      </button>
    </form>
  </div>
</div>
import { ContactInfoItem, ContactTypeInfo } from '../../models';

export class CandidateContactsData {
  static readonly contactNames: ContactInfoItem[] = [
    {
      contactType: 'EMAIL',
      contactTitle: 'CONTACTS.EMAILS',
      contactUniversalTitle: 'CONTACTS.EMAILS_UNIVERSAL'
    },
    {
      contactType: 'PHONE',
      contactTitle: 'CONTACTS.PHONES',
      contactUniversalTitle: 'CONTACTS.PHONES_UNIVERSAL'
    },
    {
      contactType: 'TELEGRAM',
      contactTitle: 'CONTACTS.TELEGRAM',
      contactUniversalTitle: 'CONTACTS.TELEGRAM'
    },
    {
      contactType: 'ICQ',
      contactTitle: 'CONTACTS.ICQ',
      contactUniversalTitle: 'CONTACTS.ICQ'
    },
    {
      contactType: 'GTALK',
      contactTitle: 'CONTACTS.GTALK',
      contactUniversalTitle: 'CONTACTS.GTALK'
    },
    {
      contactType: 'QQ',
      contactTitle: 'CONTACTS.QQ',
      contactUniversalTitle: 'CONTACTS.QQ'
    },
    {
      contactType: 'SKYPE',
      contactTitle: 'CONTACTS.SKYPE',
      contactUniversalTitle: 'CONTACTS.SKYPE'
    },
    {
      contactType: 'WECHAT',
      contactTitle: 'CONTACTS.WECHAT',
      contactUniversalTitle: 'CONTACTS.WECHAT'
    }
  ];

  static contactTypeInfoList(): ContactTypeInfo[] {
    return [
      {
        type: 'EMAIL',
        label: 'CONTACTS.EMAIL'
      },
      {
        type: 'PHONE',
        label: 'CONTACTS.PHONE_NUMBER'
      },
      {
        type: 'PHONE_WORK',
        label: 'CONTACTS.PHONE_WORK'
      },
      {
        type: 'PHONE_HOME',
        label: 'CONTACTS.PHONE_HOME'
      },
      {
        type: 'SKYPE',
        label: 'CONTACTS.SKYPE'
      }
    ];
  }
}

<app-modal
  [info]="info"
  (closeModal)="closeModal()"
  (onSubmit)="onExportSubmited()"
  >
  <div *ngIf="state === 'settings'" class="export">
    <form class="form">
      <fieldset class="field">
        <label>
          <input
            type="text"
            class="field__input export-name"
            [formControl]="nameControl"
            required
            maxlength="32"
            [placeholder]="'EXPORT.EXPORT_MODAL.NAME_PLACEHOLDER' | translate">
        </label>
      </fieldset>
    </form>

    <div *ngIf="showContactSettings" class="contacts-settings">
      <div class="toggle">
        <span class="label">{{ 'EXPORT.EXPORT_MODAL.WITH_CONTACTS' | translate }}</span>

        <mat-slide-toggle
          [checked]="withContacts"
          class="blue-theme small"
          (click)="toggleContactSetting()">
        </mat-slide-toggle>
      </div>

      <p class="info">
        {{ 'EXPORT.EXPORT_MODAL.CONTACT_SETTING_EXPLANATION' | translate }}
      </p>
    </div>

    <div class="links">
      <a class="link" [href]="systemPagesEnum.EXPORT" target="_blank" (click)="closeModal()">
        <svg class="icon" width="14" height="14">
          <use xlink:href="#gear-icon"></use>
        </svg>

        <span class="text">{{ 'EXPORT.EXPORT_MODAL.EXPORT_SETTINGS_LINK' | translate }}</span>
      </a>

      <a class="link" [href]="systemPagesEnum.EXPORT + '?tab=history'" target="_blank" (click)="closeModal()">
        <svg class="icon" width="16" height="16">
          <use xlink:href="#stopwatch"></use>
        </svg>

        <span class="text">{{ 'EXPORT.EXPORT_MODAL.EXPORT_HISTORY_LINK' | translate }}</span>
      </a>
    </div>
  </div>

  <div *ngIf="state !== 'settings'" class="waiting">
    <div class="animation">
      <app-waiting-animation [state]="state"></app-waiting-animation>

      <div
        *ngIf="progressPercentage !== null"
        [class.hidden]="state !== 'waiting'" 
        class="progress-indicator">
        {{ progressPercentage + '%'}} 
      </div>
    </div>

    <div class="info-text">
      <p>{{ infoText | translate }}</p>
    </div>

    <div class="links">
      <a class="link" [href]="systemPagesEnum.EXPORT + '?tab=history'" target="_blank">
        <svg class="icon" width="16" height="16">
          <use xlink:href="#stopwatch"></use>
        </svg>

        <span class="text">{{ 'EXPORT.EXPORT_MODAL.EXPORT_HISTORY_LINK' | translate }}</span>
      </a>
    </div>
  </div>
</app-modal>

import { Injectable } from '@angular/core';

import { GlobalScrollService } from './global-scroll.service';
import { Observable, Subject } from 'rxjs';
import { ConditionInfo } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class ScrollHandlerService {
  constructor(private globalScroll: GlobalScrollService) {}

  private disableScrollBarSubject = new Subject<ConditionInfo>();

  disableScrollBar$: Observable<ConditionInfo> = this.disableScrollBarSubject.asObservable();

  scrollToPageStart(behavior: ScrollBehavior = 'auto'): void {
    this.scrollTo(0, behavior);
  }

  scrollTo(top: number = 0, behavior: ScrollBehavior = 'auto'): void {
    const options: ScrollToOptions = {
      top,
      behavior
    };
    const scrollbar: HTMLElement = this.globalScroll.scrollbar;

    if (scrollbar) {
      scrollbar.scrollTo(options);
    }
  }

  scrollToElementWithGap(elementId: string, gap: number = 0, scrollbar: HTMLElement = null): void {
    if (!scrollbar) {
      scrollbar = this.globalScroll.scrollbar;
    }

    if (scrollbar) {
      const element: HTMLElement = document.querySelector(`#${elementId}`);

      if (element) {
        const optionsTop: number = element.offsetTop;
        const options: ScrollToOptions = {
          top: optionsTop + gap,
          behavior: 'smooth'
        };

        scrollbar.scrollTo(options);
      }
    }
  }

  disableScroll(): void {
    this.disableScrollBarSubject.next({ condition: true });
  }

  unDisableScroll(): void {
    this.disableScrollBarSubject.next({ condition: false });
  }
}

import { Injectable } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { map } from 'rxjs/operators';

import { PreloadingStrategy, Route } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class CustomPreloadingStrategyService implements PreloadingStrategy {
  constructor(private authService: AuthService) {}

  private readonly preloadDueTime = 5000;

  preload(route: Route, fn: () => Observable<any>): Observable<any> {
    const isUserLogIn: boolean = this.authService.getIsUserLoggedIn();

    if (isUserLogIn && route?.data?.preload) {
      return timer(this.preloadDueTime).pipe(map(() => fn()));
    }

    return of(null);
  }
}

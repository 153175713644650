import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { RestService } from './rest.service';
import { ApiResponseMultiple, ApiResponseSingle, BaseApiResponse, Tag } from '../../models';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})
export class TagService {
  constructor(private restService: RestService) {}

  private dispatchGetTagsCountSubject = new BehaviorSubject<string>('0');

  getTagsCount$ = this.dispatchGetTagsCountSubject.asObservable();

  getUserTags(): Observable<Tag[]> {
    return this.restService
      .Tag()
      .getAllTags()
      .pipe(map((data: ApiResponseMultiple<Tag>) => RequestService.getData(data)));
  }

  removeTag(tagId: string): Observable<BaseApiResponse> {
    return this.restService.Tag().removeTag(tagId);
  }

  createTag(tag: Tag): Observable<Tag> {
    return this.restService
      .Tag()
      .createTag(tag)
      .pipe(
        map((data: ApiResponseSingle<Tag>) => {
          return RequestService.getData(data);
        })
      );
  }

  getProfileTags(docId: string): Observable<Tag[]> {
    return this.restService
      .Tag()
      .getProfileTags(docId)
      .pipe(
        map((data: ApiResponseMultiple<Tag>) => RequestService.getData(data)),
        map((tags: Tag[]) => tags || [])
      );
  }

  removeProfileTag(tagId: string, profileId: string, docId: string): Observable<BaseApiResponse> {
    return this.restService.Tag().removeProfileTag(tagId, profileId, docId);
  }

  addProfileTag(tagId: string, profileId: string, docId: string): Observable<BaseApiResponse> {
    return this.restService.Tag().addProfileTag(tagId, profileId, docId);
  }

  changeTagName(tagId: string, tagName: string): Observable<ApiResponseMultiple<null>> {
    return this.restService.Tag().changeTagName(tagId, tagName);
  }

  updateTagsCount(tagsCount: string): void {
    this.dispatchGetTagsCountSubject.next(tagsCount);
  }

  dispatchGetTagsCount(): void {
    this.restService
      .Tag()
      .getTagCount()
      .pipe(take(1))
      .subscribe((tagsCount: string) => {
        this.updateTagsCount(tagsCount);
      });
  }
}

<app-modal
  *ngIf="ready$ | async"
  [info]="info"
  (closeModal)="closeModal($event)">
  <ng-template #submitWithClothingContent>
    <button
      id="m-plan-limitation-action-btn"
      [routerLink]="[actionRouterLink]"
      (click)="closeModal(true)"
      class="btn btn--new-primary small bright modal-btn">
      <span class="btn__text">
        {{ actionText | translate }}
      </span>
    </button>
  </ng-template>
</app-modal>

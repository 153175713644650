import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'encodeUrl',
  pure: true
})
export class EncodeUrlPipe implements PipeTransform {
  transform(url: string): string {
    if (!url) {
      return '';
    }

    return encodeURIComponent(url);
  }
}

import { Injectable } from '@angular/core';

import { Skill, SkillGroup } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class SkillsService {
  private readonly skillGroupException = 'Programming Languages';
  private readonly maxSkillLength = 200;

  getMainSkillNameList(skillGroupsMain: SkillGroup[] = []): string[] {
    const mainSkillList: string[] = [];
    const programmingLanguagesSkillGroup: SkillGroup = skillGroupsMain.find(
      (skillGroup: SkillGroup) => skillGroup.groupName === this.skillGroupException
    );

    if (programmingLanguagesSkillGroup?.skills?.length) {
      programmingLanguagesSkillGroup.skills.forEach((skill: Skill) => {
        mainSkillList.push(skill.skillName);
      });
    }

    skillGroupsMain.forEach((skillGroup: SkillGroup) => {
      if (skillGroup.groupName !== this.skillGroupException) {
        mainSkillList.push(skillGroup.groupName);
      }
    });

    if (mainSkillList.length > this.maxSkillLength) {
      return mainSkillList.slice(0, this.maxSkillLength);
    }

    return mainSkillList;
  }

  getSecondarySkillNameList(skillsSecondary: Skill[] = []): string[] {
    if (skillsSecondary.length > this.maxSkillLength) {
      return skillsSecondary.slice(0, this.maxSkillLength).map((skill: Skill) => skill.skillName);
    }

    return skillsSecondary.map((skill: Skill) => skill.skillName);
  }
}

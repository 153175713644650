import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { RestService } from './rest.service';
import { ApiResponseSingle, Company, SearchResponse } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  constructor(private restService: RestService) {}

  private updateEmployees$ = new Subject<SearchResponse>();

  employees$ = this.updateEmployees$.asObservable();

  private static getSearchResponseForEmployees(
    response: ApiResponseSingle<SearchResponse>,
    withUpdating: boolean = false
  ): SearchResponse {
    const searchResponse: SearchResponse = RestService.getData(response);

    searchResponse.withUpdating = withUpdating;

    return searchResponse;
  }

  // company api:

  getCompanyInfo(companyId: number): Observable<Company> {
    return this.restService.Company().getCompanyInfo(companyId);
  }

  getEmployees(
    companyId: number,
    pageNumber: number
  ): Observable<ApiResponseSingle<SearchResponse>> {
    return this.restService.Company().getEmployees(companyId, pageNumber);
  }

  // rest methods:

  dispatchGetEmployees(companyId: number, pageNumber: number, withUpdating: boolean = false): void {
    this.getEmployees(companyId, pageNumber)
      .pipe(
        map((response: ApiResponseSingle<SearchResponse>) => {
          return CompanyService.getSearchResponseForEmployees(response, withUpdating);
        }),
        take(1)
      )
      .subscribe((searchResponse: SearchResponse) => {
        this.updateEmployees$.next(searchResponse);
      });
  }
}

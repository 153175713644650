<ng-container
  *ngIf="filteredAutocompleteList$ | async as filteredAutocompleteList">
  <div
    *ngIf="filteredAutocompleteList?.length"
    appDropdown
    [withNavigation]="true"
    [info]="showDropdownInfo"
    [holdDownSiblingsSelector]="holdDownSiblingsSelector"
    [holdDownSiblingsClass]="holdDownSiblingsClass"
    (hideOnClickOutside)="clickHideDropdownOnClickOutside()"
    class="dropdown {{ sizeType }}"
    tabindex="0">
    <h6
      *ngIf="sizeType === 'standard'"
      class="dropdown__heading">
      {{ 'SEARCH.YOU_MAY_LOOKING_FOR' | translate }}
    </h6>

    <div class="scroll-wrapper vertical">
      <app-scrollbar
        class="scrollbar"
        [vertical]="true"
        [size]="'small'">
        <span
          class="dropdown__items">
          <span
            *ngFor="let item of filteredAutocompleteList"
            appDropdownItem
            (click)="clickSetItem($event, item)"
            [active]="item.active"
            class="user-tags-dropdown-item dropdown__item">
            <span class="dropdown__item-text">
              {{ item.name }}
            </span>
          </span>
        </span>
      </app-scrollbar>
    </div>
  </div>
</ng-container>

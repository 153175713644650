import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { ApiResponse, ApiResponseMultiple, ApiResponseSingle, FileInfo } from '../../models';
import { RequestMethod } from '../../types';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  constructor(public http: HttpClient) {}

  backEndUrl = environment?.backEndUrl || '';

  static getData<T>(response: ApiResponseMultiple<T>, withPagination: true): ApiResponseMultiple<T>;
  static getData<T>(response: ApiResponseMultiple<T>, withPagination?: boolean): T[];
  static getData<T>(response: ApiResponseSingle<T>): T;

  static getData<T>(
    response: ApiResponse<T>,
    withPagination: boolean = false
  ): ApiResponse<T> | ApiResponseMultiple<T> | T[] | T {
    if ((response as ApiResponseMultiple<T>).objects) {
      if (withPagination) {
        return response;
      }

      return (response as ApiResponseMultiple<T>).objects as T[];
    }

    return (response as ApiResponseSingle<T>).object as T;
  }

  private static getOptionsWithSetting(setOptions: any, setJSON: boolean = false): any {
    if (setJSON) {
      setOptions.headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    }

    return setOptions;
  }

  private static getOptions(fileInfo: FileInfo, setOptions: any, setJSON: boolean): any {
    let options: any = {};

    if (fileInfo?.isFile) {
      options.responseType = 'blob';
    }

    if (setOptions) {
      options = {
        ...options,
        ...RequestService.getOptionsWithSetting(setOptions, setJSON)
      };
    } else if (setJSON) {
      options = {};
      options.headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    }

    return options;
  }

  makeRequest<T>(
    method: RequestMethod,
    restUrl: string,
    setOptions: any = null,
    body: any = null,
    fileInfo: FileInfo = { isFullMatch: false, isFile: false },
    setJSON: boolean = false
  ): Observable<any> {
    const url: string = !fileInfo?.isFullMatch ? this.backEndUrl + restUrl : restUrl;
    const options: any = RequestService.getOptions(fileInfo, setOptions, setJSON);

    if (body) {
      return this.http[method as 'post' | 'patch' | 'put'](url, body, options);
    }

    return this.http[method](url, options);
  }
}

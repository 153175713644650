import { Injectable } from '@angular/core';

import { DocumentService } from './document.service';
import { Cookie, SystemInfo } from '../../enums';
import { WindowService } from './window.service';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {
  constructor(
    private document: DocumentService,
    private window: WindowService
  ) {}

  // common:

  getCookie(name: string): string {
    const cookies: string = this.document.cookie;

    if (cookies) {
      return CookiesService.getCookieByName(name, cookies);
    }

    return '';
  }

  // special:

  getGACookie(): string {
    const initialExtraText = 'GA1.1.';
    const gaCookieFull: string = this.getCookie(SystemInfo.gaCookieKey);

    return gaCookieFull ? gaCookieFull.split(initialExtraText)[1] : '';
  }

  clearCookieBeforeLogout(): void {
    const domain = this.window.getLocationHostName();

    this.document.clearCookieByName(Cookie.pluginToken, domain);
  }

  // privates:

  private static getCookieByName(name: string, cookies: string): string {
    const cookieList: string[] = cookies.split(';');
    let cookie = '';

    cookieList.some((item: string) => {
      cookie = this.handleCookieItem(item, name);

      return !!cookie;
    });

    return cookie;
  }

  private static handleCookieItem(item: string, name: string): string {
    const splitList: string[] = item.split('=');
    const itemName: string = splitList[0];

    if (itemName === name) {
      return splitList[1];
    }

    return '';
  }
}

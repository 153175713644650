export enum SearchCategoryTypeEnum {
  default = 'word',
  skill = 'skill',
  position = 'position',
  currentPosition = 'current_position',
  company = 'company',
  currentCompany = 'current_company',
  location = 'location',
  currentLocation = 'current_location',
  smart = 'smart',
  education = 'education',
  certificate = 'certificate',
  name = 'name',
  tag = 'tag',
  comment = 'comment'
}

export enum AutocompleteResultNameEnum {
  skills = 'skills',
  locations = 'locations',
  locationsCurrent = 'locationsCurrent',
  positionSynonymDtos = 'positionSynonymDtos',
  positionSynonymDtosCurrent = 'positionSynonymDtosCurrent',
  companyDtos = 'companyDtos',
  companyDtosCurrent = 'companyDtosCurrent',
  tags = 'tags'
}

import { Injectable } from '@angular/core';

import { PaginationInfo } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {
  getNumberOfPages({ numFound, pageSize }: PaginationInfo): number {
    if (numFound && pageSize && numFound > pageSize) {
      return Math.ceil(numFound / pageSize);
    }

    return 1;
  }

  isPageExist(paginationInfo: PaginationInfo): boolean {
    if (paginationInfo?.page === 1) {
      return true;
    }

    const numberOfPages: number = this.getNumberOfPages(paginationInfo);

    return paginationInfo.page <= numberOfPages;
  }
}

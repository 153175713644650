import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ModalInfo, UpdateEntity } from '../../../../../shared/models';

@Component({
  selector: 'app-remove-entity-modal',
  templateUrl: './remove-entity-modal.component.html'
})
export class RemoveEntityModalComponent {
  constructor(
    public dialogRef: MatDialogRef<RemoveEntityModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UpdateEntity
  ) {}

  info: ModalInfo = {
    type: 'alert-modal',
    closeModalBtnId: 'close-btn-m',
    header: {
      titleContent: true
    },
    actions: {
      cancel: {
        id: 'm-cancel-action-btn'
      },
      submitWithClothing: {
        id: 'm-submit-action-btn',
        extraClass: 'alert',
        type: 'delete'
      }
    }
  };

  closeModal(condition: boolean = false): void {
    this.dialogRef.close(condition);
  }
}

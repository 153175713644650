import { Injectable } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

import { FolderStatus, FolderStatusActionInfo } from '../../models';
import { StatusesInfoFilterKey } from '../../types';

@Injectable({
  providedIn: 'root'
})
export class DragAndDropHandlerService {
  constructor() {}

  finishDropByMaterialMethods(event: CdkDragDrop<FolderStatus[]>): void {
    // methods from the box

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  updateFilteredStatusesListAfterDrop(
    filteredStatuses: FolderStatus[],
    filterType: StatusesInfoFilterKey,
    defaultActionInfo: FolderStatusActionInfo = null
  ): void {
    filteredStatuses.forEach((status: FolderStatus, index: number) => {
      filteredStatuses[index].position = index + 1;

      if (!status[filterType]) {
        filteredStatuses[index][filterType] = true;

        if (defaultActionInfo) {
          defaultActionInfo.type = 'setDefault';
          defaultActionInfo.name = status.nameForTranslate || status.name;
        }
      }
    });
  }

  updateRestStatusesListAfterDrop(
    restStatuses: FolderStatus[],
    filterType: StatusesInfoFilterKey,
    defaultStatusesLastPosition: number,
    defaultActionInfo: FolderStatusActionInfo = null
  ): void {
    restStatuses.forEach((status: FolderStatus, index: number) => {
      if (index === 0) {
        restStatuses[index].position = defaultStatusesLastPosition + 1;
      } else {
        restStatuses[index].position = restStatuses[index - 1].position + 1;
      }

      if (status[filterType]) {
        restStatuses[index][filterType] = false;

        if (defaultActionInfo) {
          defaultActionInfo.type = 'offDefault';
          defaultActionInfo.name = status.nameForTranslate || status.name;
        }
      }
    });
  }
}

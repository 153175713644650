import { Component, Input } from '@angular/core';

import { Duration } from '../../../../shared/models';

type TimeExtraClass = '' | 'small';

@Component({
  selector: 'app-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss']
})
export class TimeComponent {
  @Input() duration: Duration;
  @Input() extraClass: TimeExtraClass = '';
}

<div
  *ngIf="positionsForShow?.length"
  class="profile-info small">
  <div *ngIf="revealed" class="profile-info__decoration">
    <div class="profile-info__icon-wrapper">
      <img
        alt=""
        src="assets/images/icons/briefcase.svg"
        width="16"
        height="16"
        class="icon">
    </div>
  </div>

  <div class="profile-experiences profile-info__content">
    <div
      *ngFor="let position of positionsForShow"
      class="profile-experience">
      <h3 class="experience-position">
        {{ position.positionTitle }}
      </h3>

      <h3
        *ngIf="position.positionTitle"
        class="experience-separator">
        —
      </h3>

      <span
        *ngIf="position.companyName"
        class="company-name">
        {{ position.companyName }}<span *ngIf="position.location?.city || position.location?.country">,</span>
        {{ position.location?.city }}<span *ngIf="position.location?.city && position.location?.country">,</span>
        {{ position.location?.country }}
      </span>

      <span
        *ngIf="!position.companyName"
        class="company-name">
        {{ 'CANDIDATE.SOMEWHERE' | translate  }}<span *ngIf="position.location?.city || position.location?.country">,</span>
        {{ position.location?.city }}<span *ngIf="position.location?.city && position.location?.country">,</span>
        {{ position.location?.country }}
      </span>

      <span class="experience-duration">
        <app-period-range
          [duration]="position.duration"
          [durationType]="'short'"
          [styleType]="'bold'">
        </app-period-range>
      </span>
    </div>
  </div>
</div>

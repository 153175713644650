import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-radio-btn-line',
  templateUrl: './radio-btn-line.component.html',
  styleUrls: ['./radio-btn-line.component.scss']
})
export class RadioBtnLineComponent {
  constructor() {}

  @Input() text: string;
  @Input() value: any;
  @Input() checked = false;
  @Input() disabled = false;
  @Input() breakWord = false;

  @Output() valueChanged = new EventEmitter<any>();

  onValueChanged(): void {
    if (!this.disabled) {
      this.valueChanged.emit(this.value);
    }
  }
}

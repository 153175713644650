import { Inject, Injectable, OnDestroy } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowService implements OnDestroy {
  constructor(@Inject('Window') private window: Window) {}

  get innerWidth(): number {
    return this.window.innerWidth;
  }

  get innerHeight(): number {
    return this.window.innerHeight;
  }

  getSelection(): Selection {
    return this.window.getSelection();
  }

  getLocationHostName(): string {
    return this.window.location.hostname;
  }

  open(url: string, target: '_blank' | '_self' = null): void {
    this.window.open(url, target, 'noopener');
  }

  locationReload(): void {
    this.window.location.reload();
  }

  setLocationHref(href: string): void {
    this.window.location.href = href;
  }

  addMessageListener(
    listener: EventListener | EventListenerObject,
    options?: boolean | AddEventListenerOptions
  ): void {
    this.window.addEventListener('message', listener, options);
  }

  removeMessageListener(
    listener: EventListener | EventListenerObject,
    options?: boolean | AddEventListenerOptions
  ): void {
    this.window.removeEventListener('message', listener, options);
  }

  ngOnDestroy(): void {
    this.window = null;
  }
}

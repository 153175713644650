import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { delay, map, mergeMap } from 'rxjs/operators';

import { Candidate, Company, Position } from '../../models';
import { FileService, RestRequestsService } from '../requests';

@Injectable({
  providedIn: 'root'
})
export class CandidateImageHandlerService {
  constructor(
    private fileService: FileService,
    private sanitizer: DomSanitizer,
    private restRequestsService: RestRequestsService
  ) {}

  getResultPictureUrlOnDelay(pictureUrl: string): Observable<SafeUrl> {
    return of(true).pipe(
      delay(0),
      mergeMap(() => {
        return this.getResultPictureUrl(pictureUrl);
      })
    );
  }

  getCandidateImageUrl(candidate: Candidate): Observable<SafeUrl> {
    if (candidate.pictureFromStorage) {
      const pictureUrl = this.fileService.getProfileImageUrl(candidate.pictureFromStorage);

      return this.getResultPictureUrlOnDelay(pictureUrl);
    } else if (candidate.picture) {
      const pictureUrl = this.sanitizer.bypassSecurityTrustResourceUrl(candidate.picture);

      return of(pictureUrl);
    }

    return of('');
  }

  getCandidatePositionCompanyUrl(position: Position): string {
    let pictureUrl = '';

    if (position.companyLogoFromStorage) {
      pictureUrl = this.fileService.getProfileImageUrl(position.companyLogoFromStorage);
    } else if (position.companyLogo) {
      pictureUrl = position.companyLogo;
    }

    return pictureUrl;
  }

  getCompanyUrl(company: Company): string {
    let pictureUrl = '';

    if (company.logoFromStorage) {
      pictureUrl = this.fileService.getProfileImageUrl(company.logoFromStorage);
    } else if (company.logo) {
      pictureUrl = company.logo;
    }

    return pictureUrl;
  }

  private getResultPictureUrl(pictureUrl: string): Observable<SafeUrl> {
    if (pictureUrl) {
      return this.restRequestsService.sendImage(pictureUrl).pipe(
        map((blob: Blob) => {
          const url: string = blob ? URL.createObjectURL(blob) : '';

          return this.sanitizer.bypassSecurityTrustResourceUrl(url);
        })
      );
    }

    return of('');
  }
}

import {
  faBehance,
  faBuyNLarge,
  faCss3Alt,
  faDAndD,
  faDribbble,
  faFacebookF,
  faGithub,
  faHtml5,
  faJava,
  faJs,
  faLinkedinIn,
  faNodeJs,
  faPhp,
  faPython,
  faStackOverflow,
  faSwift,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';

export const fabIcons = [
  faLinkedinIn,
  faStackOverflow,
  faGithub,
  faBehance,
  faFacebookF,
  faTwitter,
  faDribbble,
  faJava,
  faPhp,
  faJs,
  faNodeJs,
  faSwift,
  faPython,
  faHtml5,
  faCss3Alt,
  faBuyNLarge,
  faDAndD
];

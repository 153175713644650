import { Injectable } from '@angular/core';

import { AccountService, AuthService, TagService, UserService } from '../../../shared/services';
import { User } from '../../../shared/models';

@Injectable({
  providedIn: 'root'
})
export class ChangedUserHandlerService {
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private accountService: AccountService,
    private tagService: TagService
  ) {}

  handle(user: User): void {
    const userIdFromToken: string = this.authService.getUserId();

    if (user && user?.userId !== userIdFromToken) {
      this.userService.updateCurrentUser(userIdFromToken);

      this.accountService.updateAccount(true);
      this.tagService.dispatchGetTagsCount();
    }
  }
}

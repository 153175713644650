import { Candidate, Folder, FolderQueryParams, TotalProfilesByStatuses } from '../../models';
import { FolderStatusConstructor } from './folderStatusConstructor';
import { FolderStatusesInfo } from '../../types';

export class FolderConstructor {
  constructor(
    public archived?: boolean,
    public dc?: string,
    public description?: string,
    public id?: string,
    public name?: string,
    public orgId?: string,
    public privateAccess?: boolean,
    public profiles?: Candidate[],
    public statusIds?: string[],
    public statuses?: FolderStatusesInfo,
    public userId?: string,
    public candidatesCount?: number,
    public candidatesCountByStatuses?: number,
    public totalProfilesByStatuses?: TotalProfilesByStatuses,
    public page?: number,
    public count?: number
  ) {}

  static toInstance(data: Folder): Folder {
    if (data) {
      if (!data.statuses) {
        data.statuses = {};
      }

      const cleanStatuses: FolderStatusesInfo = {};

      Object.keys(data.statuses).forEach((statusId: string) => {
        cleanStatuses[statusId] = FolderStatusConstructor.toInstance(data.statuses[statusId]);
      });

      return new FolderConstructor(
        data.archived,
        data.dc,
        data.description,
        data.id,
        data.name,
        data.orgId,
        data.privateAccess,
        data.profiles,
        data.statusIds,
        cleanStatuses,
        data.userId,
        data.candidatesCount,
        data.candidatesCountByStatuses,
        data.totalProfilesByStatuses,
        data.page,
        data.count
      );
    }

    return data;
  }
}

export class FolderQueryParamsConstructor {
  constructor(
    public page?: number,
    public count?: number,
    public statusIds?: string[],
    public returnProfilesOnAllStatuses?: boolean
  ) {}

  static toInstance(data: FolderQueryParams): FolderQueryParams {
    if (data) {
      const queryParams: FolderQueryParams = new FolderQueryParamsConstructor(
        data.page,
        data.count,
        data.statusIds
      );

      if (data?.hasOwnProperty('returnProfilesOnAllStatuses')) {
        queryParams.returnProfilesOnAllStatuses = data.returnProfilesOnAllStatuses;
      }

      return queryParams;
    }
  }
}

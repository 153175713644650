import { Autocomplete, QueryLabelInfo } from '../../../../shared/models';
import { FilterValueWrapperInfo, SearchFilterQueryItemData } from '../../models/search-filter';
import {
  bracketsRegExp,
  parenthesisRegExp,
  QueryHelpers,
  SearchRegExpExcludedSymbols
} from '../../data';

interface ContentStringWrapperData {
  autocompleteId: string;
  wrapperInfo?: FilterValueWrapperInfo;
  rawQuery?: boolean;
  parsedWithWrapper?: boolean;
}

export class SearchFilterQueryItem {
  constructor(public data: SearchFilterQueryItemData) {}

  static createNew(id: number, value: string = ''): SearchFilterQueryItem {
    const data: SearchFilterQueryItemData = {
      value,
      id,
      editMode: true,
      excluded: false
    };

    return new SearchFilterQueryItem(data);
  }

  static createItemFromAutocomplete(
    autocomplete: Autocomplete,
    index: number = null
  ): SearchFilterQueryItem {
    const { name, id } = autocomplete;

    if (!index) {
      index = 0;
    }

    return new SearchFilterQueryItem({
      value: name,
      id: index,
      autocompleteId: id
    });
  }
  static createParsedItem(
    searchTextItem: string,
    index: number,
    autocompletes: Autocomplete[]
  ): SearchFilterQueryItem {
    let autocompleteId: string;
    const excluded: boolean = SearchFilterQueryItem.isItemExcluded(searchTextItem);
    let handlesSearchTextItem: string = SearchFilterQueryItem.getHandlesSearchTextItem(
      searchTextItem,
      excluded,
      true
    );
    const parsedWithWrapper = SearchFilterQueryItem.isSearchTextParsedWithWrapper(
      searchTextItem,
      excluded
    );
    const autocomplete: Autocomplete = SearchFilterQueryItem.getItemAutocomplete(
      autocompletes,
      handlesSearchTextItem
    );

    if (autocomplete) {
      handlesSearchTextItem = autocomplete.name;
      autocompleteId = autocomplete.id;
    }

    return new SearchFilterQueryItem({
      value: handlesSearchTextItem,
      id: index,
      excluded,
      autocompleteId,
      parsedWithWrapper
    });
  }

  static getHandlesSearchTextItem(
    searchTextItem: string,
    excluded: boolean = false,
    parsing: boolean = false
  ): string {
    let handlesSearchTextItem: string = searchTextItem.trim();
    const regExp = parsing ? bracketsRegExp : SearchRegExpExcludedSymbols;

    if (excluded) {
      handlesSearchTextItem = handlesSearchTextItem.slice(QueryHelpers.EXCLUDED_OPERATOR.length);
    }

    handlesSearchTextItem = handlesSearchTextItem.replace(regExp, '');

    return handlesSearchTextItem;
  }

  getLabelInfo(): QueryLabelInfo {
    return {
      value: this.data.value,
      excluded: this.data.excluded
    };
  }

  // actions:

  setEditMode(): void {
    if (!this.data.editMode) {
      this.data.editMode = true;
    }
  }

  resetEditMode(): void {
    if (this.data.editMode) {
      this.data.editMode = false;
    }
  }

  toggleExcluded(): void {
    this.data.excluded = !this.data.excluded;
  }

  setValue(value: string): void {
    this.data.value = value;
  }

  setAutocompleteId(autocompleteId: string): void {
    this.data.autocompleteId = autocompleteId;
  }

  // data handle:

  getContentString(wrapperInfo: FilterValueWrapperInfo, rawQuery: boolean = false): string {
    const { autocompleteId, excluded, parsedWithWrapper } = this.data;
    const resultValue: string = this.getResultValueContent(rawQuery);

    if (resultValue) {
      const excludedPart: string = excluded ? QueryHelpers.EXCLUDED_OPERATOR : '';
      const contentWrapperData: ContentStringWrapperData = {
        autocompleteId,
        wrapperInfo,
        rawQuery,
        parsedWithWrapper
      };
      const startWrapper: string =
        SearchFilterQueryItem.getContentStringStartWrapper(contentWrapperData);
      const endWrapper: string =
        SearchFilterQueryItem.getContentStringEndWrapper(contentWrapperData);

      return `${excludedPart}${startWrapper}${resultValue}${endWrapper}`;
    }

    return '';
  }

  private getResultValueContent(rawQuery: boolean = false): string {
    const { value, autocompleteId } = this.data;
    let result = '';

    if (rawQuery) {
      result = value;
    } else if (autocompleteId) {
      result = autocompleteId;
    } else {
      result = SearchFilterQueryItem.getHandlesSearchTextItem(value);
    }

    return result;
  }

  static isItemExcluded(searchTextItem: string): boolean {
    return (
      searchTextItem.trim().slice(0, QueryHelpers.EXCLUDED_OPERATOR.length) ===
      QueryHelpers.EXCLUDED_OPERATOR
    );
  }

  private static getItemAutocomplete(
    autocompletes: Autocomplete[],
    searchTextItem: string
  ): Autocomplete {
    if (!autocompletes?.length) {
      return null;
    }

    return autocompletes.find((item: Autocomplete) => searchTextItem === item?.id);
  }

  private static getContentStringStartWrapper(data: ContentStringWrapperData): string {
    const { autocompleteId, wrapperInfo = {}, rawQuery = false, parsedWithWrapper = false } = data;
    const { withWrapper = false, withWrapperOnAutocomplete = false } = wrapperInfo;

    return (autocompleteId ? withWrapperOnAutocomplete : rawQuery ? parsedWithWrapper : withWrapper)
      ? `${QueryHelpers.START_VALUE_WRAPPER}${QueryHelpers.QUOTE}`
      : QueryHelpers.START_VALUE_WRAPPER;
  }

  private static getContentStringEndWrapper(data: ContentStringWrapperData): string {
    const { autocompleteId, wrapperInfo = {}, rawQuery = false, parsedWithWrapper = false } = data;
    const { withWrapper = false, withWrapperOnAutocomplete = false } = wrapperInfo;

    return (autocompleteId ? withWrapperOnAutocomplete : rawQuery ? parsedWithWrapper : withWrapper)
      ? `${QueryHelpers.QUOTE}${QueryHelpers.END_VALUE_WRAPPER}`
      : QueryHelpers.END_VALUE_WRAPPER;
  }

  private static isSearchTextParsedWithWrapper(searchText: string, excluded: boolean): boolean {
    let resultContent: string = searchText.trim().replace(parenthesisRegExp, '');

    if (excluded) {
      resultContent = resultContent.slice(QueryHelpers.EXCLUDED_OPERATOR.length);
    }

    if (resultContent?.length > 2) {
      const firstSymbol: string = resultContent[0];
      const lastSymbol: string = resultContent[resultContent.length - 1];

      return firstSymbol === QueryHelpers.QUOTE && lastSymbol === QueryHelpers.QUOTE;
    }

    return false;
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import {
  CustomDropdownItemInfo,
  HoldDownDropdownSiblingsInfo,
  Locale,
  ShowBlockInfo,
  Tag
} from '../../../../../shared/models';
import {
  DropdownsService,
  KeyboardHandlerService,
  LocalizationService,
  NavDropdownService
} from '../../../../../shared/services';
import { InputMaxLengthEnum } from '../../../../../shared/enums';
import { SizeType } from '../../../../../shared/types';
import { CustomDropdownComponent } from '../../../../platform/components';

@Component({
  selector: 'app-user-tags-autocomplete-dropdown',
  templateUrl: './user-tags-autocomplete-dropdown.component.html',
  styleUrls: ['./user-tags-autocomplete-dropdown.component.scss'],
  providers: [NavDropdownService]
})
export class UserTagsAutocompleteDropdownComponent implements OnInit, AfterViewInit {
  constructor(
    private localization: LocalizationService,
    private element: ElementRef,
    private dropdownsService: DropdownsService,
    private dropdownService: NavDropdownService,
    private keyboardHandler: KeyboardHandlerService
  ) {}

  @Input() sizeType: SizeType = 'standard';
  @Input() holdDownSiblingsSelector: string;
  @Input() holdDownSiblingsClass: string;

  @Input() set autocompleteList(list: Tag[]) {
    this._autocompleteList = list;

    this.updateFilteredAutocompleteList();
  }

  @Input() set showAllDropdownBlockInfo(info: ShowBlockInfo) {
    this._showAllDropdownBlockInfo = info;

    if (this._showAllDropdownBlockInfo.show) {
      if (this.dropdown) {
        this.dropdown.show();
      } else {
        setTimeout(() => {
          this.dropdown.show();
        });
      }
    }
  }

  @Output() createTagFromAutocomplete: EventEmitter<Tag> = new EventEmitter<Tag>();
  @Output() resetOtherDropdowns: EventEmitter<null> = new EventEmitter<null>();

  @ViewChild('input') public input: ElementRef;
  @ViewChild('dropdownComp') public dropdown: CustomDropdownComponent;

  readonly tagNameMaxLength = InputMaxLengthEnum.tagName;

  private _autocompleteList: Tag[];
  private filteredAutocompleteListSubject = new BehaviorSubject<Tag[]>([]);
  private filteredAutocompleteList: Tag[];

  _showAllDropdownBlockInfo: ShowBlockInfo = {
    show: false
  };
  showDropdownInfo: ShowBlockInfo = {
    show: false
  };

  filteredAutocompleteList$: Observable<Tag[]> =
    this.filteredAutocompleteListSubject.asObservable();
  systemLocale$: Observable<Locale>;

  control: UntypedFormControl;
  chosenTag: Tag;

  get disabledCreateBtn(): boolean {
    return !this.control.value || this.control.invalid;
  }

  ngOnInit(): void {
    this.initControl();

    this.dropdownService.setShowDropdownInfo(this.showDropdownInfo);
    this.handleControlValueChanges();
    this.setSystemLocalePipe();
  }

  ngAfterViewInit() {
    this.dropdownService.setInput(this.input?.nativeElement);
    this.dropdownService.setDropdownComponent(this.dropdown);

    this.setFocusOnInput();
  }

  clickInput(event: MouseEvent): void {
    event.stopPropagation();

    this.dropdownService.toggleDropdown();
  }

  onInput(): void {
    this.setValidConditionToControl();
  }

  onInputKeydown(event: KeyboardEvent): void {
    if (this.keyboardHandler.arrowDownPressed(event)) {
      this.dropdownService.inputKeyDown();
    }
  }

  setItem({ event, autocomplete }: CustomDropdownItemInfo): void {
    event.stopPropagation();
    this.control.setValue(autocomplete?.name);
    this.handleEmptyShowDropdownInfo();

    this.setValidConditionToControl();

    this.chosenTag = autocomplete;

    this.dropdownService.hideDropdown();
    this.createItem();
  }

  createItem(): void {
    const handleDropdownShow: boolean = this.getHandleDropdownShowBeforeCreateItem();

    if (this.chosenTag) {
      this.createTagFromAutocomplete.emit(this.chosenTag);

      if (handleDropdownShow) {
        this.handleEmptyShowDropdownInfo();

        this.dropdownService.hideDropdown();
        this.hideAllDropdownBlockInfo();

        this.handleSiblingHolding();
      }
    }
  }

  hideDropdownOnClickOutside(): void {
    if (this._showAllDropdownBlockInfo.show) {
      this._showAllDropdownBlockInfo.show = false;

      this.dropdown.hide();
    }

    this.handleSiblingHolding();
  }

  // ********************** Init methods: **********************

  private initControl(): void {
    this.control = new UntypedFormControl('');

    this.setValidConditionToControl();
  }

  private setSystemLocalePipe(): void {
    this.systemLocale$ = this.localization.systemLocale$.pipe(map((locale: Locale) => locale));
  }

  private setFocusOnInput(): void {
    if (this.input.nativeElement) {
      this.input.nativeElement.focus();
    }
  }

  // ************************ Dropdown handlers: ************************

  private hideAllDropdownBlockInfo(): void {
    if (this._showAllDropdownBlockInfo.show) {
      this._showAllDropdownBlockInfo.show = false;

      this.dropdown.hide();
    }
  }

  private handleEmptyShowDropdownInfo(): void {
    if (!this.showDropdownInfo) {
      this.showDropdownInfo = {};
    }
  }

  private handleSiblingHolding(): void {
    if (this.holdDownSiblingsSelector) {
      const info: HoldDownDropdownSiblingsInfo = {
        element: this.element.nativeElement,
        selector: this.holdDownSiblingsSelector,
        parentClass: this.holdDownSiblingsClass
      };

      this.dropdownsService.handleByInfo(info, this._showAllDropdownBlockInfo?.show);
    }
  }

  // ************************ form handlers: ************************

  private handleControlValueChanges(): void {
    this.control.valueChanges.pipe(startWith('')).subscribe((value: string) => {
      this.updateFilteredAutocompleteList(value);

      this.showDropdownInfo.show = !!this.filteredAutocompleteList.length;
    });
  }

  private setValidConditionToControl(): void {
    if (this.control && this._autocompleteList?.length) {
      const valid: boolean = this.isControlValueValid();

      if (valid) {
        this.control.setErrors(null);
      } else {
        this.control.setErrors({ inValid: true });
      }
    } else if (this.control) {
      this.control.setErrors({ inValid: true });
    }
  }

  private updateFilteredAutocompleteList(value: string = ''): void {
    this.handleEmptyShowDropdownInfo();

    this.filteredAutocompleteList = this.filter(value);

    this.filteredAutocompleteListSubject.next(this.filteredAutocompleteList);
    this.dropdownService.setContentList(this.filteredAutocompleteList);
    this.setValidConditionToControl();

    this.dropdownService.showDropdown();
  }

  // ********************** Private getters: **********************

  private filter(value: string): Tag[] {
    if (value) {
      const filterValue = UserTagsAutocompleteDropdownComponent.getNormalizedValue(value);

      return this._autocompleteList.filter((item: Tag) => {
        return UserTagsAutocompleteDropdownComponent.getNormalizedValue(item?.name).includes(
          filterValue
        );
      });
    }

    return this._autocompleteList;
  }

  private getHandleDropdownShowBeforeCreateItem(): boolean {
    let handleDropdownShow: boolean = false;

    if (!this.chosenTag) {
      const valid: boolean = this.isControlValueValid();

      if (valid) {
        const chosenTag = this.getChosenTagByControl();

        if (chosenTag) {
          this.chosenTag = chosenTag;
          handleDropdownShow = true;
        }
      }
    }

    return handleDropdownShow;
  }

  private isControlValueValid(): boolean {
    return this._autocompleteList.some((item: Tag) => this.control?.value === item.name);
  }

  private getChosenTagByControl(): Tag {
    return this._autocompleteList.find((item: Tag) => this.control?.value === item.name);
  }

  private static getNormalizedValue(value: string): string {
    return value?.toLowerCase().replace(/\s/g, '') || '';
  }
}

import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { TooltipComponent } from './tooltip.component';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective implements OnInit, OnDestroy {
  constructor(
    private elementRef: ElementRef,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef
  ) {}

  content = '';
  private readonly gap = 8;

  @Input('appTooltip') set appTooltop(tooltip: string) {
    this.content = tooltip;

    if (this.overlayRef?.hasAttached()) {
      this.overlayRef.detach();
      this.showTooltip();
    }
  }

  @Input('disableTooltip') disabled: boolean = false;

  private overlayRef: OverlayRef;

  ngOnInit(): void {
    this.createOverlay();
  }

  ngOnDestroy(): void {
    this.overlayRef.dispose();
  }

  @HostListener('mouseenter') showTooltip(): void {
    if (this.disabled) {
      return;
    }

    const tooltipRef: ComponentRef<TooltipComponent> = this.overlayRef.attach(
      new ComponentPortal(TooltipComponent, this.viewContainerRef)
    );

    tooltipRef.instance.content = this.content;
  }

  @HostListener('mouseleave') hideTooltip(): void {
    this.overlayRef.detach();
  }

  private createOverlay(): void {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          offsetY: -this.gap
        },
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          offsetY: this.gap
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: false,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });
  }
}

import { SafeResourceUrl } from '@angular/platform-browser';
import { TariffDto } from './tariffInfo';

export class User {
  constructor(
    public firstName?: string,
    public lastName?: string,
    public email?: string,
    public newPassword?: string,
    public password?: string,
    public currentPassword?: string,
    public role?: RoleType,
    public userId?: string,
    public tokenId?: string,
    public photoId?: string,
    public notLocked?: boolean,
    public lastLoginDateDisplay?: string,
    public lastLoginDate?: string,
    public joinDate?: string,
    public enable?: boolean,
    public authorities?: string[],
    public searchesToday?: number,
    public weekSearches?: number,
    public monthSearches?: number,
    public totalSearches?: number,
    public parameters?: UserParameter[],
    public photoUrl?: SafeResourceUrl,
    public nextTariff?: TariffDto,
    public orgId?: string,
    public utmSource?: string,
    public gaCookie?: string,
    public companyName?: string
  ) {}

  static toInstance(data: User): User {
    if (!data) {
      return null;
    }

    const parameters: UserParameter[] = [];

    if (data?.parameters?.length) {
      data.parameters.forEach((paramItem: UserParameter) => {
        const parameter: UserParameter = UserParameter.toInstance(paramItem);

        if (parameter) {
          parameters.push(parameter);
        }
      });
    }

    return new User(
      data?.firstName,
      data?.lastName,
      data?.email,
      data?.newPassword,
      data?.newPassword,
      data?.currentPassword,
      data?.role,
      data?.userId,
      data?.tokenId,
      data?.photoId,
      data?.notLocked,
      data?.lastLoginDateDisplay,
      data?.lastLoginDate,
      data?.joinDate,
      data?.enable,
      data?.authorities,
      data?.searchesToday,
      data?.weekSearches,
      data?.monthSearches,
      data?.totalSearches,
      parameters,
      data?.photoUrl,
      data?.nextTariff,
      data?.orgId,
      data?.utmSource,
      data?.gaCookie,
      data?.companyName
    );
  }

  static updateUserWithParameter(data: User, type: UserParameterType, value: string): User {
    let isParameterFound = false;

    if (!data?.parameters) {
      data.parameters = [];
    }

    data.parameters.forEach((paramItem: UserParameter, index: number) => {
      if (paramItem.type === type) {
        data.parameters[index].value = value;
        isParameterFound = true;
      }
    });

    if (!isParameterFound) {
      const parameter: UserParameter = UserParameter.toInstance({
        type,
        value
      });

      data.parameters.push(parameter);
    }

    return data;
  }

  getFullName?(): string {
    if (this.firstName || this.lastName) {
      const space = this.firstName && this.lastName ? ' ' : '';

      return `${this.firstName}${space}${this.lastName}`;
    }

    return '';
  }
}

export class UserParameter {
  constructor(
    public type: UserParameterType,
    public value: string,
    public parameterId?: string
  ) {}

  static toInstance(data: UserParameter): UserParameter {
    return new UserParameter(data?.type, data?.value, data?.parameterId);
  }
}

export type UserParameterType =
  | 'language'
  | 'pluginAgreement'
  | 'onboardingStatus'
  | 'hideFunctionality';

export type RoleType = 'ROLE_ADMIN' | 'ROLE_USER';

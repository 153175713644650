import { Injectable } from '@angular/core';
import { ConfigService } from '../../../core/services';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  constructor(private configService: ConfigService) {}

  log(message: any, showDate: boolean = true): void {
    if (!this.configService.isProduction) {
      const date: string = showDate ? new Date().toTimeString() : '';
    }
  }
}

import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ConditionInfo, Skill, SkillBase, SkillGroup } from '../../../../../../shared/models';
import { CandidateCardHandlerService } from '../../../../../../shared/services';

@Component({
  selector: 'app-candidate-card-skills',
  templateUrl: './candidate-card-skills.component.html',
  styleUrls: ['./candidate-card-skills.component.scss']
})
export class CandidateCardSkillsComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(private handlerService: CandidateCardHandlerService) {}

  @Input() revealed: boolean = false;
  @Input() skillGroupsMain: SkillGroup[];

  readonly gap = 18;

  private skillNamesList: SkillBase[];
  private skillGroupException = 'Programming Languages';

  private updateRevealedCardInfo$ = new BehaviorSubject<ConditionInfo>({
    condition: this.revealed
  });
  private skillNamesListSubject = new Subject<SkillBase[]>();
  private unsubscribe$ = new Subject<void>();

  skillNamesList$: Observable<SkillBase[]> = this.skillNamesListSubject.asObservable();
  revealedCardInfo$: Observable<ConditionInfo> = this.updateRevealedCardInfo$.asObservable();

  ngOnInit(): void {
    this.handleReveal();
  }

  ngAfterViewInit(): void {
    this.setSkillNamesList();
  }

  private handleReveal(): void {
    this.handlerService.revealedCardInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((info: ConditionInfo) => {
        this.onRevealInfoChange(info?.condition);
      });
  }

  private onRevealInfoChange(revealed: boolean): void {
    this.updateRevealedCardInfo(revealed);
    this.handleList();
  }

  private updateRevealedCardInfo(revealed: boolean): void {
    this.updateRevealedCardInfo$.next({ condition: revealed });
  }

  private handleList(): void {
    this.skillNamesListSubject.next(this.skillNamesList);
  }

  private setSkillNamesList(): void {
    const exceptionSkillNamesList: SkillBase[] = this.getExceptionSkillNamesList();
    const restSkillGroupList: SkillBase[] = this.getSkillGroupNamesList();

    this.skillNamesList = exceptionSkillNamesList.concat(restSkillGroupList);

    this.handleList();
  }

  private getExceptionSkillNamesList(): SkillBase[] {
    const programmingLangSkills: SkillBase[] = this.skillGroupsMain
      ?.find((skillGroup: SkillGroup) => skillGroup.groupName === this.skillGroupException)
      ?.skills?.map((skill: Skill) => {
        return { name: skill.skillName };
      });

    return programmingLangSkills || [];
  }

  private getSkillGroupNamesList(): SkillBase[] {
    const skillGroupNamesList: SkillBase[] = this.skillGroupsMain
      ?.filter((skillGroup: SkillGroup) => skillGroup.groupName !== this.skillGroupException)
      ?.map((skillGroup: SkillGroup) => {
        return {
          name: skillGroup.groupName
        };
      });

    return skillGroupNamesList || [];
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

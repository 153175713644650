import { Inject, Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { fromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserPhotoData } from '../../models';
import { RestService } from './rest.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(
    private restService: RestService,
    private sanitizer: DomSanitizer,
    @Inject('Document') private document: Document,
    @Inject('Window') private window: Window & typeof globalThis
  ) {}

  getFormData(blob: Blob): FormData {
    const formData: FormData = new FormData();

    formData.append('file', blob);

    return formData;
  }

  getImageUrlFromBlob({ photo, userId }: UserPhotoData): Promise<UserPhotoData> {
    return new Promise((resolve, reject) => {
      if (photo) {
        const reader: FileReader = new FileReader();

        reader.addEventListener(
          'load',
          this.onLoadGetImageUrlFromBlob.bind(this, reader, userId, resolve, reject),
          false
        );

        reader.readAsDataURL(photo);
      } else {
        reject();
      }
    });
  }

  getImageSrcFromBlob(
    image: Blob,
    withSanitizer: boolean = false
  ): Observable<SafeResourceUrl | string> {
    const reader: FileReader = new FileReader();

    reader.readAsDataURL(image);

    return fromEvent(reader, 'load').pipe(
      map(() => {
        const result: string = reader.result as string;

        if (withSanitizer) {
          return this.sanitizer.bypassSecurityTrustResourceUrl(result);
        }

        return result;
      })
    );
  }

  getProfileImageUrl(pictureFromStorage: string): string {
    const getImageRequestUrl = '/profile/getImage2?location=';

    return pictureFromStorage
      ? `${environment.backEndUrl}${getImageRequestUrl}${pictureFromStorage}`
      : '';
  }

  downloadBlobAsFile(blob: Blob, filename: string): void {
    const a = this.document.createElement('a');
    const blobUrl = this.window.URL.createObjectURL(blob);
    a.href = blobUrl;
    a.download = filename;
    this.document.body.appendChild(a);
    a.click();
    this.document.body.removeChild(a);
    this.window.URL.revokeObjectURL(blobUrl);
  }

  private getImageUrlFromBlobLoaded(reader: FileReader): SafeResourceUrl {
    const image: string = reader.result as string;

    return image && this.sanitizer.bypassSecurityTrustResourceUrl(image);
  }

  private onLoadGetImageUrlFromBlob(reader: FileReader, userId: string, resolve, reject): void {
    const sanitizedImageUrl: SafeResourceUrl = this.getImageUrlFromBlobLoaded(reader);

    if (sanitizedImageUrl) {
      const resultData: UserPhotoData = {
        photoUrl: sanitizedImageUrl,
        userId
      };

      resolve(resultData);
    } else {
      reject();
    }

    reader.removeEventListener('load', this.onLoadGetImageUrlFromBlob.bind(this));
  }
}

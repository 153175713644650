import { Injectable } from '@angular/core';
import {
  CandidateToFolderActionType,
  FolderActionType,
  FolderStatusActionType,
  NotificationInfoKey,
  SelectedCandidatesActionType
} from '../../../../shared/types';
import {
  ActionTypeNotification,
  CandidatesMoveToFolderActionData,
  CandidateToFolderActionInfo,
  Folder,
  FolderNotificationInterpolateParams
} from '../../../../shared/models';
import { FolderHandlerUtilities } from './folder-handler.utilities';
import { LocalizationService, NotificationService } from '../../../../shared/services';

@Injectable({
  providedIn: 'root'
})
export class FoldersNotificationsService {
  constructor(
    private notificationService: NotificationService,
    private localization: LocalizationService
  ) {}

  handleNotifications(
    notificationInfoKey: NotificationInfoKey,
    type:
      | FolderStatusActionType
      | FolderActionType
      | CandidateToFolderActionType
      | SelectedCandidatesActionType,
    interpolateParams: FolderNotificationInterpolateParams
  ): void {
    const notificationInfo: ActionTypeNotification = FolderHandlerUtilities[notificationInfoKey]
      ? FolderHandlerUtilities[notificationInfoKey][type]
      : null;

    this.handleNotificationsInterpolateParamsTranslation(interpolateParams);

    if (notificationInfo) {
      const interpolateParamsResult: Object = notificationInfo?.hasInterpolateParams
        ? interpolateParams
        : null;
      const message: string = FolderHandlerUtilities.getNotificationMessage(
        notificationInfo,
        interpolateParams
      );

      this.notificationService.notify(notificationInfo.type, message, interpolateParamsResult);
    }
  }

  handleCandidateToFolderNotification(info: CandidateToFolderActionInfo): void {
    if (info) {
      this.handleNotifications('candidateToFolderNotificationInfo', info.actionType, {
        name: info.folderName
      });
    }
  }

  handleDuplicateToCreatedFolderNotification(
    newFolder: Folder,
    data: CandidatesMoveToFolderActionData
  ): void {
    const { type, candidates } = data;

    const actionType: SelectedCandidatesActionType =
      type === 'duplicate' ? 'duplicateToCreated' : 'move' ? 'moveToCreated' : type;
    const multipleInfo: string = candidates.length > 1 ? `${candidates.length}` : null;

    this.handleNotifications('selectedCandidatesNotificationInfo', actionType, {
      name: newFolder.name,
      multipleInfo
    });
  }

  private handleNotificationsInterpolateParamsTranslation(
    interpolateParams: FolderNotificationInterpolateParams
  ): void {
    if (interpolateParams.name) {
      const maxLength = 30;
      interpolateParams.name = this.localization.translateInstant(interpolateParams.name);

      if (interpolateParams.name?.length > maxLength) {
        interpolateParams.name = `${interpolateParams.name.slice(0, maxLength)}...`;
      }
    }
  }
}

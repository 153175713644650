<div
  *ngIf="filteredUserTags$ | async as filteredUserTags"
  class="add-tag-block">
  <div
    *ngIf="!dropdownBlockInfo.show && filteredUserTags.length"
    class="add-item-block"
    [class.full-rounded]="noTagSelected">
    <button
      class="btn"
      (click)="clickAddNewTag($event)">
      <svg
        width="14"
        height="14"
        class="icon">
        <use xlink:href="#add-icon">
        </use>
      </svg>
    </button>
  </div>

  <ng-container *ngIf="filteredUserTags.length; else noUserTags">
    <app-user-tags-autocomplete-dropdown
      *ngIf="dropdownBlockInfo.show"
      class="tags-autocomplete-dropdown"
      [sizeType]="dropdownSizeType"
      [holdDownSiblingsSelector]="holdDownSiblingsSelector"
      [holdDownSiblingsClass]="holdDownSiblingsClass"
      [autocompleteList]="filteredUserTags"
      (createTagFromAutocomplete)="onAddTag($event)"
      [showAllDropdownBlockInfo]="dropdownBlockInfo">
    </app-user-tags-autocomplete-dropdown>
  </ng-container>

  <ng-template #noUserTags>
    <div
      class="add-item-block"
      [class.full-rounded]="noTagSelected">
      <a target="_blank" [routerLink]="['/user/tags']">
        <button class="btn">
          <svg
            width="14"
            height="14"
            class="icon">
            <use xlink:href="#add-icon">
            </use>
          </svg>
        </button>
      </a>
    </div>
  </ng-template>
</div>

<app-modal
  [info]="info"
  [formUnchangedInfo]="formUnchangedInfo"
  (closeModal)="closeModal()"
  (onSubmit)="onSubmit()">
  <ng-template #scrollableContent>
    <form
      class="form"
      [formGroup]="form">
      <fieldset class="field">
        <input
          type="text"
          [maxLength]="folderNameMaxlength"
          [formControlName]="'name'"
          class="field__input"
          [placeholder]="'FOLDERS.CREATE_FOLDER_MODAL.NAME_PLACEHOLDER' | translate">
      </fieldset>

      <fieldset class="field">
        <input
          type="text"
          [maxLength]="folderDescMaxLength"
          [formControlName]="'description'"
          class="field__input folder-description"
          [placeholder]="'FOLDERS.CREATE_FOLDER_MODAL.DESCRIPTION_PLACEHOLDER' | translate">
      </fieldset>

      <div class="radio-buttons-list row">
        <app-radio-btn-line
          [text]="'FOLDERS.ACCESS_TYPE.PRIVATE'"
          [checked]="accessType.value === 'private'"
          [value]="'private'"
          (valueChanged)="changeAccessType($event)">
        </app-radio-btn-line>

        <app-radio-btn-line
          [text]="'FOLDERS.ACCESS_TYPE.TEAM'"
          [checked]="accessType.value === 'team'"
          [value]="'team'"
          (valueChanged)="changeAccessType($event)">
        </app-radio-btn-line>
      </div>

      <div class="folder-statuses">
        <div class="folder-statuses__block">
          <span class="folder-statuses__title">
            {{ 'FOLDERS.CREATE_FOLDER_MODAL.SELECTED_STATUSES' | translate }}:
          </span>

          <div
            class="folder-statuses__list drag-list"
            cdkDropList
            id="default-statuses-list"
            [cdkDropListData]="selectedStatuses.value"
            [cdkDropListConnectedTo]="['rest-statuses-list']"
            (cdkDropListDropped)="drop($event)">
            <app-folder-status
              class="folder-statuses__item drag-item"
              *ngFor="let status of selectedStatuses.value"
              [status]="status"
              [selectable]="true"
              [type]="statusType"
              [active]="true"
              [cdkDragDisabled]="status.lastDefault"
              cdkDrag>
            </app-folder-status>
          </div>
        </div>

        <div class="folder-statuses__divider"></div>

        <div class="folder-statuses__block">
          <span class="folder-statuses__title">
            {{ 'FOLDERS.CREATE_FOLDER_MODAL.AVAILABLE_STATUSES' | translate }}:
          </span>

          <div
            class="folder-statuses__list drag-list"
            cdkDropList
            id="rest-statuses-list"
            [cdkDropListData]="availableStatuses.value"
            [cdkDropListConnectedTo]="['default-statuses-list']"
            (cdkDropListDropped)="drop($event)">
            <app-folder-status
              class="folder-statuses__item drag-item"
              *ngFor="let status of availableStatuses.value"
              [status]="status"
              [selectable]="false"
              [type]="statusType"
              [active]="false"
              cdkDrag>
            </app-folder-status>
          </div>
        </div>
      </div>
    </form>
  </ng-template>
</app-modal>

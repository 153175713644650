import { Component, Input } from '@angular/core';

import { Folder } from '../../../../../../shared/models';
import { FolderCardSmallComponentType } from '../../../../../../shared/types';

@Component({
  selector: 'app-folder-card-small-info',
  templateUrl: './folder-card-small-info.component.html',
  styleUrls: ['./folder-card-small-info.component.scss']
})
export class FolderCardSmallInfoComponent {
  @Input() type: FolderCardSmallComponentType = 'forCandidatePage';
  @Input() folder: Folder;
}

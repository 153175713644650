import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalInfo } from '../../../../../shared/models';

@Component({
  selector: 'app-remove-folder-modal',
  templateUrl: './remove-folder-modal.component.html',
  styleUrls: ['./remove-folder-modal.component.scss']
})
export class RemoveFolderModalComponent {
  constructor(
    public dialogRef: MatDialogRef<RemoveFolderModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { name: string; candidateCount: number }
  ) {}

  info: ModalInfo = {
    type: 'alert-modal',
    closeModalBtnId: 'close-remove-folder-m',
    header: {
      titleContent: true
    },
    actions: {
      cancel: {
        id: 'm-cancel-remove-folder--btn'
      },
      submitWithClothing: {
        id: 'm-remove-folder--btn',
        extraClass: 'alert',
        type: 'delete'
      }
    }
  };

  closeModal(condition: boolean = false): void {
    this.dialogRef.close(condition);
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { PaymentMethodPageService } from '../services';
import { SystemPages } from '../enums/routerPaths.enum';

@Injectable({
  providedIn: 'root'
})
export class AddPaymentMethodPageGuard  {
  constructor(
    private paymentMethodPageService: PaymentMethodPageService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const canBeOpened: boolean = this.paymentMethodPageService.getBeOpened();

    if (!canBeOpened) {
      this.router.navigate([SystemPages.BILLING]).then();
    }

    return canBeOpened;
  }
}

import { Autocomplete, Skill } from '../../models';
import {
  CategoryAutocompletes,
  SearchCategoryAutocomplete
} from '../../models/search/searchCategory';

export class SkillAutocomplete implements SearchCategoryAutocomplete {
  private static withSynonymsCheck = true;

  static getAutocomplete(item: Skill): Autocomplete {
    if (SkillAutocomplete.withSynonymsCheck && !item.synonyms?.length && !item.variants?.length) {
      return null;
    }

    return {
      name: item?.skillName,
      id: item?.skillName
    };
  }

  static getAutocompleteResult(autocompleteList: CategoryAutocompletes): Autocomplete[] {
    return (autocompleteList as Skill[])
      .map((skill: Skill) => SkillAutocomplete.getAutocomplete(skill))
      .filter((item: Autocomplete) => !!item);
  }
}

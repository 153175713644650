import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { StatusCodes } from '../../../shared/enums';
import { LimitationModalData } from '../../../shared/models';
import { LimitationModalComponent } from '../components';
import { Observable, of, throwError } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LimitationService {
  constructor(public dialog: MatDialog) {}

  private modal: MatDialogRef<LimitationModalComponent>;

  onRequestLimitCatchError(
    error: HttpErrorResponse,
    toThrowError = false,
    withAction: boolean = false,
    disableClose: boolean = false
  ): Observable<any> {
    if (error.status === StatusCodes.TOO_MANY_REQUESTS && !this.modal) {
      const modalData: LimitationModalData = { type: 'daily', withAction, disableClose };

      this.launchModal(modalData, disableClose);
    }

    return toThrowError ? throwError(error) : of(null);
  }

  onPlanLimit(shortenText = false): void {
    if (!this.modal) {
      const modalData: LimitationModalData = { type: 'plan', withAction: false, shortenText };

      this.launchModal(modalData);
    }
  }

  private launchModal(modalData: LimitationModalData, disableClose = false): void {
    this.modal = this.dialog.open(LimitationModalComponent, { data: modalData, disableClose });

    this.modal
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.modal = null;
      });
  }
}

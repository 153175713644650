<ng-container *ngIf="contactsData$ | async as contactsData">
  <div
    class="profile-contacts"
    [ngClass]="{'revealed': contactsOpened}"
    [class.empty]="numberOfContacts === 0 && (!searchRequestIsPending && lockSearchMore)">
    <div class="profile-contacts__content">
      <app-hidden-candidate-contacts
        *ngIf="this.someContactsHidden"
        [contactsData]="contactsData">
      </app-hidden-candidate-contacts>

      <app-revealed-candidate-contacts
        *ngIf="!this.someContactsHidden"
        [contactsInfoMap]="contactsData">
      </app-revealed-candidate-contacts>
    </div>

    <div class="profile-contacts__side-block">
      <div *ngIf="someContactsHidden; else noHiddenContacts" class="wrapper">
        <button
          class="btn"
          [class.disabled]="lockOpenContacts"
          (click)="openContacts()">
          <span class="text btn__text">
            {{ numberOfContacts | currentNumeric: 'OPEN_CONTACTS' | translate }}
          </span>
        </button>
      </div>

      <ng-template #noHiddenContacts>
        <div *ngIf="searchRequestIsPending || !lockSearchMore" class="wrapper">
          <button
            [class.invicible]="searchRequestIsPending"
            class="btn"
            [disabled]="searchRequestIsPending"
            (click)="searchMoreContacts()">
            <span class="text btn__text">
              <ng-container *ngIf="numberOfContacts === 0">
                {{ 'CANDIDATE.CONTACTS.SEARCH_CONTACTS' | translate }}
              </ng-container>
              <ng-container *ngIf="numberOfContacts > 0">
                {{ 'CANDIDATE.CONTACTS.SEARCH_MORE_CONTACTS' | translate }}
              </ng-container>
            </span>
          </button>

          <app-loading-animation 
            *ngIf="searchRequestIsPending"
            class="loader-animation">
          </app-loading-animation>
        </div>
      </ng-template>

    </div>
  </div>
</ng-container>

import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ContactsInfoMap, ContactUserDto } from '../../../../../../shared/models';
import { CandidateContactsService, ProfileService } from '../../../../../../shared/services';
import { catchError, filter, finalize, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { CandidateProfileHandlerService } from '../../../../services/candidates/candidate-profile-handler.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LimitationService } from 'src/app/modules/system-shared/services/limitation.service';

@Component({
  selector: 'app-candidate-card-contacts',
  templateUrl: './candidate-card-contacts.component.html',
  styleUrls: ['./candidate-card-contacts.component.scss']
})
export class CandidateCardContactsComponent implements OnInit, OnDestroy {
  constructor(
    private contactsService: CandidateContactsService,
    private profileService: ProfileService,
    private candidateHandler: CandidateProfileHandlerService,
    private limitationService: LimitationService,
    private cdr: ChangeDetectorRef
  ) {}

  @Input() profileId: string;
  @Input() type: 'standard' | 'forFolder' = 'standard';
  @Input() contacts: ContactUserDto[];
  @Input() showSearchContacts: boolean = false;

  someContactsHidden = false;
  numberOfContacts = 0;

  lockOpenContacts = false;
  lockSearchMore = false;
  searchRequestIsPending = false;

  private unsubscribe$ = new Subject<void>();

  contactsData$ = new BehaviorSubject<ContactsInfoMap>(null);

  ngOnInit(): void {
    this.updateContacts(this.contacts);
    this.onContactsOpenedFromOutside();
  }

  private updateContacts(contacts: ContactUserDto[]): void {
    this.updateState(contacts);

    const contactsData: ContactsInfoMap = this.contactsService.getContactsData(contacts);
    this.contactsData$.next(contactsData);
  }

  private updateState(contacts: ContactUserDto[] = []): void {
    if (!this.showSearchContacts) {
      this.lockSearchMore = true;
    }

    this.numberOfContacts = contacts.length;

    // When contacts are empty it imply that they are not hidden
    this.someContactsHidden = this.contactsService.isAnyContactHidden(contacts);
  }

  openContacts(): void {
    if (this.lockOpenContacts) {
      return;
    }

    this.lockOpenContacts = true;

    this.makeContactRequest('openProfileContacts')
      .pipe(
        catchError(() => {
          this.lockOpenContacts = false;

          return of(null);
        }),
        finalize(() => this.cdr.markForCheck())
      )
      .subscribe();
  }

  searchMoreContacts(): void {
    if (this.lockSearchMore) {
      return;
    }

    this.lockSearchMore = true;
    this.searchRequestIsPending = true;

    const prevContactsNumber = this.numberOfContacts;

    this.makeContactRequest('searchProfileContacts')
      .pipe(
        tap(() => {
          this.searchRequestIsPending = false;

          const newContactsNumber = this.numberOfContacts;
          this.contactsService.showContactsSearchResultNotification(
            prevContactsNumber,
            newContactsNumber
          );
        }),
        catchError(() => {
          this.lockSearchMore = false;
          this.searchRequestIsPending = false;

          return of(null);
        }),
        finalize(() => this.cdr.markForCheck())
      )
      .subscribe();
  }

  private makeContactRequest(
    requestType: 'openProfileContacts' | 'searchProfileContacts'
  ): Observable<ContactUserDto[]> {
    return this.profileService[requestType](this.profileId).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.limitationService.onRequestLimitCatchError(
          error,
          true,
          this.type !== 'standard'
        );
      }),
      tap((contacts: ContactUserDto[]) => this.updateContacts(contacts)),
      takeUntil(this.unsubscribe$)
    );
  }

  private onContactsOpenedFromOutside(): void {
    this.candidateHandler.contactsOpened$
      .pipe(
        filter((contacts: ContactUserDto[]) => contacts?.length && this.someContactsHidden),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((contacts: ContactUserDto[]) => {
        this.updateContacts(contacts);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { Candidate, CandidateActionsInfo, ConditionInfo } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class CandidateCardHandlerService {
  constructor() {}

  private actionsInfo: CandidateActionsInfo = {};

  private updateRevealedCardInfo$ = new Subject<ConditionInfo>();
  private updateActionsInfo$ = new BehaviorSubject<CandidateActionsInfo>(this.actionsInfo);
  private launchUpdateTagsSubject$ = new Subject<ConditionInfo>();

  revealedCardInfo$: Observable<ConditionInfo> = this.updateRevealedCardInfo$.asObservable();
  actionsInfo$: Observable<CandidateActionsInfo> = this.updateActionsInfo$.asObservable();
  launchUpdateTags$: Observable<ConditionInfo> = this.launchUpdateTagsSubject$.asObservable();

  setCandidateInfo(candidate: Candidate): void {
    if (candidate) {
      this.setActionsInfo(candidate);
    }
  }

  updateRevealedCardInfo(reveal: boolean): void {
    this.updateRevealedCardInfo$.next({ condition: reveal });
  }

  updateFoldersCount(count: number = 0): void {
    this.actionsInfo.foldersCount = count;

    this.updateActionsInfo(this.actionsInfo);
  }

  updateCommentsCount(count: number = 0): void {
    this.actionsInfo.commentCount = count;

    this.updateActionsInfo(this.actionsInfo);
  }

  updateTagsCount(count: number = 0): void {
    this.actionsInfo.tagCount = count;

    this.updateActionsInfo(this.actionsInfo);
    this.launchUpdateTags();
  }

  private launchUpdateTags(): void {
    this.launchUpdateTagsSubject$.next({ condition: true });
  }

  private updateActionsInfo(info: CandidateActionsInfo): void {
    this.updateActionsInfo$.next({ ...info });
  }

  private setActionsInfo(candidate: Candidate): void {
    const { commentCount, tagCount } = candidate;

    this.actionsInfo = {
      foldersCount: candidate.folders?.length,
      commentCount,
      tagCount
    };

    this.updateActionsInfo(this.actionsInfo);
  }
}

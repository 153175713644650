import { Inject, Injectable, OnDestroy } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DocumentService implements OnDestroy {
  constructor(@Inject('Document') private document: Document) {}

  get cookie(): string {
    return this.document.cookie;
  }

  getElementsByTagName(tagName: string): HTMLCollectionOf<Element> {
    return this.document.getElementsByTagName(tagName);
  }

  createDocumentFragment(): DocumentFragment {
    return this.document.createDocumentFragment();
  }

  createElement(tagName: string): HTMLElement {
    return this.document.createElement(tagName);
  }

  createTextNode(text: string): Node {
    return this.document.createTextNode(text);
  }

  createRange(): Range {
    return this.document.createRange();
  }

  querySelector(selector: string): HTMLElement {
    return this.document.querySelector(selector);
  }

  querySelectorAll(selector: string): NodeListOf<HTMLElement> {
    return this.document.querySelectorAll(selector);
  }

  activeElement(): Element {
    return this.document.activeElement;
  }

  appendChild(tagName: string, child: HTMLElement): HTMLElement {
    return this.document[tagName] ? this.document[tagName].appendChild(child) : null;
  }

  elementFromPoint(x: number, y: number): Element | null {
    return this.document.elementFromPoint(x, y);
  }

  get elementClientWidth(): number {
    return this.document.documentElement.clientWidth;
  }

  get elementClientHeight(): number {
    return this.document.documentElement.clientHeight;
  }

  clearCookieByName(name: string, domain: string = ''): void {
    this.document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;${
      domain ? ` Domain=.${domain};` : ''
    }`;
  }

  ngOnDestroy(): void {
    this.document = null;
  }
}

import { Injectable } from '@angular/core';

import { ShowBlockInfo } from '../../../../shared/models';
import { Subject } from 'rxjs';

interface DropdownListAtPageInfo {
  [index: number]: ShowBlockInfo;
}

@Injectable({
  providedIn: 'root'
})
export class SearchResultDropdownsService {
  constructor() {}

  private closeOtherBlocksSubject = new Subject<number>();
  closeOtherBlocks$ = this.closeOtherBlocksSubject.asObservable();

  dropdownListAtPage: DropdownListAtPageInfo = {};

  init(): void {
    this.dropdownListAtPage = {};
  }

  setDropdownInfoToPageList(index: number, showBlockInfo: ShowBlockInfo): void {
    this.dropdownListAtPage[index] = showBlockInfo;
  }

  resetDropdowns(exceptIndex: number = null, otherExceptIndex: number = null): void {
    Object.keys(this.dropdownListAtPage).forEach((itemIndex: string) => {
      if ((!exceptIndex && exceptIndex !== 0) || +itemIndex !== exceptIndex) {
        this.dropdownListAtPage[itemIndex]['show'] = false;
      }
    });

    this.closeOtherBlocks(otherExceptIndex);
  }

  private closeOtherBlocks(otherExceptIndex: number = null): void {
    this.closeOtherBlocksSubject.next(otherExceptIndex);
  }
}

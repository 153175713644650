import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ApiResponseSingle,
  Autocomplete,
  AutocompleteResponse,
  GooglePlace,
  HandledAutocompleteResponse,
  Locale
} from '../../models';
import { RestService } from './rest.service';
import { AutocompleteResultNameEnum, SearchCategoryTypeEnum } from '../../enums';
import {
  CompanyAutocomplete,
  LocationAutocomplete,
  PositionAutocomplete,
  SkillAutocomplete,
  TagAutocomplete
} from '../../classes';
import { LocalizationService } from '../localization';
import { CategoryAutocompletes } from '../../models/search/searchCategory';

@Injectable({
  providedIn: 'root'
})
export class AutocompleteService {
  constructor(
    private restService: RestService,
    private localization: LocalizationService
  ) {}

  private autocompletesAsset = {
    companyDtos: (items: CategoryAutocompletes) => CompanyAutocomplete.getAutocompleteResult(items),
    companyDtosCurrent: (items: CategoryAutocompletes) =>
      CompanyAutocomplete.getAutocompleteResult(items),
    locations: (items: CategoryAutocompletes) => this.getLocationsAutocompleteResult(items),
    locationsCurrent: (items: CategoryAutocompletes) => this.getLocationsAutocompleteResult(items),
    skills: (items: CategoryAutocompletes) => SkillAutocomplete.getAutocompleteResult(items),
    positionSynonymDtos: (items: CategoryAutocompletes) =>
      PositionAutocomplete.getAutocompleteResult(items),
    positionSynonymDtosCurrent: (items: CategoryAutocompletes) =>
      PositionAutocomplete.getAutocompleteResult(items),
    tags: (tags: CategoryAutocompletes) => TagAutocomplete.getAutocompleteResult(tags)
  };

  // API:

  autocomplete(type: SearchCategoryTypeEnum, word: string): Observable<AutocompleteResponse> {
    return this.restService.Autocomplete().autocomplete(type, word);
  }

  getSearchObjects(queryString: string): Observable<ApiResponseSingle<AutocompleteResponse>> {
    return this.restService.Autocomplete().searchObjects(queryString);
  }

  getAutocompleteResult(
    type: SearchCategoryTypeEnum,
    word: string,
    autocompleteType: AutocompleteResultNameEnum
  ): Observable<Autocomplete[]> {
    return this.autocomplete(type, word).pipe(
      map((response: AutocompleteResponse) =>
        this.getAutocompleteFromResponse(response, autocompleteType)
      )
    );
  }

  getSearchObjectsResult(queryString: string): Observable<HandledAutocompleteResponse> {
    return this.getSearchObjects(queryString).pipe(
      map((response: ApiResponseSingle<AutocompleteResponse>) => RestService.getData(response)),
      map((response: AutocompleteResponse) => this.getHandledAutocompleteResponse(response))
    );
  }

  getHandledAutocompleteResponse(response: AutocompleteResponse): HandledAutocompleteResponse {
    const handledResponse: HandledAutocompleteResponse = {};

    Object.keys(response).forEach((key: string) => {
      handledResponse[key] = this.getAutocompleteFromResponse(
        response,
        key as AutocompleteResultNameEnum
      );
    });

    return handledResponse;
  }

  // data handlers:

  private getAutocompleteFromResponse(
    response: AutocompleteResponse,
    autocompleteType: AutocompleteResultNameEnum
  ): Autocomplete[] {
    if (
      autocompleteType &&
      this.autocompletesAsset[autocompleteType] &&
      response[autocompleteType]
    ) {
      return this.autocompletesAsset[autocompleteType](response[autocompleteType]);
    }

    return [];
  }

  private getLocationsAutocompleteResult(autocompleteList: CategoryAutocompletes): Autocomplete[] {
    const systemLocale: Locale = this.localization.getSystemLocale();

    return LocationAutocomplete.getLocationsAutocompleteResult(
      autocompleteList as GooglePlace[],
      systemLocale
    );
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RestService } from './rest.service';
import {
  ApiResponseSingle,
  CandidateStatistic,
  CandidateStatisticLabels,
  GetCandidateStatisticResponse
} from '../../models';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})
export class StatisticService {
  constructor(private restService: RestService) {}

  private readonly statisticLabels: CandidateStatisticLabels = {
    avgPersonal: 'CANDIDATE.WITH_LOWERCASE.PERSONAL_AVERAGE',
    avgInCompany: 'CANDIDATE.WITH_LOWERCASE.AVERAGE_AMONG_COLLEAGUES',
    avgByPositionInCompany: 'CANDIDATE.WITH_LOWERCASE.PROFESSION_AVERAGE',
    avgByPositionInCountry: 'CANDIDATE.WITH_LOWERCASE.AVERAGE_BY_POSITION'
  };
  private readonly statisticColors: CandidateStatisticLabels = {
    avgPersonal: '#E34464',
    avgInCompany: '#07B38D',
    avgByPositionInCompany: '#FFCE2E',
    avgByPositionInCountry: '#60458C'
  };

  // Statistic api:

  getProfileStatistic(profileId: string): Observable<GetCandidateStatisticResponse> {
    return this.restService
      .Statistic()
      .getCandidateStatistic(profileId)
      .pipe(
        map((data: ApiResponseSingle<CandidateStatistic>) => {
          return {
            data: RequestService.getData(data),
            candidateId: profileId
          };
        })
      );
  }

  // common methods:

  getStatisticLabels(): CandidateStatisticLabels {
    return this.statisticLabels;
  }

  getStatisticColors(): CandidateStatisticLabels {
    return this.statisticColors;
  }
}

<app-modal
  [info]="info"
  (closeModal)="closeModal()"
  (onSubmit)="onSubmit()">
  <ng-template #textContent>
    <p class="modal__text">
      {{ 'COMMENT.MODAL.TEXT' | translate }}
    </p>
  </ng-template>

  <ng-template #scrollableContent>
    <div class="modal-content">
      <app-candidate-comments
        [parentType]="'candidate-card'"
        [index]="data.index"
        [commentNumber]="data.candidate.commentCount"
        [candidateId]="data.candidate.profileIds[0]"
        [docId]="data.candidate.docId"
        (updateCommentsCount)="updateCommentsCount($event)"
        class="flex-full-width">
      </app-candidate-comments>
    </div>
  </ng-template>
</app-modal>


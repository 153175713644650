<div
  *ngIf="scrollingInfo$ | async as scrollingInfo"
  class="bars {{ size }} {{ visibility }}"
  [class.theme-light]="theme === 'light'"
  [class.scrolling]="scrollingInfo.condition">
  <div
    *ngIf="hasVerticalBar"
    class="bar bar_vertical"
    [ngClass]="{'bar_has-horizontal': hasHorizontalBar}">
    <div
      class="thumb"
      appDraggableScrollbar
      [draggable]="'vertical'"
      [style.height.%]="verticalSize"
      [style.top.%]="verticalPosition"
      (dragged)="onVertical($event)">
    </div>
  </div>

  <div
    *ngIf="hasHorizontalBar"
    class="bar bar_horizontal"
    [ngClass]="{'bar_has-vertical': hasVerticalBar}">
    <div
      class="thumb"
      appDraggableScrollbar
      [draggable]="'horizontal'"
      [style.width.%]="horizontalSize"
      [style.left.%]="horizontalPosition"
      (dragged)="onHorizontal($event)">
    </div>
  </div>
</div>

<div class="scroll-content">
  <ng-content></ng-content>
</div>

export enum LandingPaths {
  INIT = '/',
  LOGIN = '/auth/login',
  REGISTRATION = '/auth/registration',
  INVITE = '/auth/invite/',
  PRIVACY_POLICY = '/info/privacy-policy',
  TERMS = '/info/terms-of-service',
  PRIVACY_NOTICE = '/info/privacy-notice',
  RECRUITING = '/recruiting',
  SALES = '/sales',
  PRICING = '/pricing'
}

export enum SystemPages {
  INIT = '/',
  SEARCH = '/search',
  BILLING = '/user/billing',
  ADD_PAYMENT_METHOD = '/add-payment-method',
  USER_PROFILE = '/user/profile',
  EXPORT = '/user/export'
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ContactUserDto } from '../../../../shared/models';

@Injectable()
export class CandidateProfileHandlerService {
  constructor() {}

  contactsOpened$ = new BehaviorSubject<ContactUserDto[]>(null);

  contactsOpened(contacts: ContactUserDto[]): void {
    this.contactsOpened$.next(contacts);
  }
}

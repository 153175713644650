<div
  *ngIf="candidate"
  class="candidate-actions"
  [class.revealed]="revealed">
  <div
    *ngIf="actionsInfo$ | async as actionsInfo"
    class="candidate-actions__wrapper">
    <div
      class="btn btn--icon candidate-action folder-action"
      [class.disabled]="(isAddToFolderStartedInfo$ | async).condition"
      (click)="onFolderAction()">
      <svg
        class="icon btn__icon"
        width="16"
        height="16">
        <use xlink:href="#medium-folder">
        </use>
      </svg>

      <span class="btn__text" [class.minus]="type === 'forFolder'">
        <ng-container *ngIf="type === 'standard' && actionsInfo.foldersCount">
          {{ actionsInfo.foldersCount }}
        </ng-container>
      </span>
    </div>

    <div class="candidate-action">
      <app-download-profile-c-v
        class="download-cv-btn"
        [profile]="candidate"
        [image]="picture"
        [isFullProfile]="false"
        [forSearchPage]="type === 'standard'">
      </app-download-profile-c-v>
    </div>

    <div
      class="btn btn--icon candidate-action add-comment-action"
      (click)="addComment()">
      <svg
        class="icon btn__icon"
        width="16"
        height="16">
        <use xlink:href="#comments">
        </use>
      </svg>

      <span class="btn__text">
        <ng-container *ngIf="actionsInfo.commentCount">
          {{ actionsInfo.commentCount }}
        </ng-container>
      </span>
    </div>

    <div
      class="btn btn--icon candidate-action add-tag-action"
      (click)="addTag()">
      <svg
        class="icon btn__icon"
        width="16"
        height="16">
        <use xlink:href="#tags">
        </use>
      </svg>

      <span class="btn__text">
        <ng-container *ngIf="actionsInfo.tagCount">
          {{ actionsInfo.tagCount }}
        </ng-container>
      </span>
    </div>
  </div>
</div>

<span
  *ngIf="duration"
  class="time {{ extraClass }}">
  <span
    *ngIf="duration.durationYear"
    class="inline-block">
    {{ duration.durationYear }}
    {{ duration.durationYear | currentNumeric: 'YEAR' | translate }}
  </span>

  <span
  *ngIf="duration.durationMonth; else lessThanMonth"
  class="inline-block">
    <span *ngIf="duration.durationYear">,</span>
    {{ duration.durationMonth }}
    {{ duration.durationMonth | currentNumeric: 'MONTH' | translate }}
  </span>

  <ng-template #lessThanMonth>
    <span
      *ngIf="duration.durationDays; else lessThanDay"
      class="inline-block">
      {{ duration.durationDays }}
      {{ duration.durationDays | currentNumeric: 'DAY' | translate }}
    </span>
  </ng-template>

  <ng-template #lessThanDay>
    <span
      *ngIf="duration.durationHours"
      class="inline-block">
      {{ duration.durationHours }}
      {{ duration.durationHours | currentNumeric: 'HOUR' | translate }}
    </span>
  </ng-template>
</span>


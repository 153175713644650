import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RestService } from './rest.service';
import {
  ApiResponseMultiple,
  ApiResponseSingle,
  BaseApiResponse,
  Candidate,
  Comment,
  ContactUserDto,
  FacetResponseData,
  LinkDto,
  SearchResponse
} from '../../models';
import { RequestService } from './request.service';
import { SystemColors } from '../../types';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(private restService: RestService) {}

  private currentCandidateSubject = new BehaviorSubject<Candidate>(null);

  currentCandidate$: Observable<Candidate> = this.currentCandidateSubject.asObservable();

  // profile api:

  getCandidate(id: string): Observable<Candidate> {
    return this.restService
      .Profile()
      .getCandidate(id)
      .pipe(map((data: ApiResponseSingle<Candidate>) => RequestService.getData(data)));
  }

  follow(id: string, follow: boolean): Observable<Candidate> {
    return this.restService
      .Profile()
      .follow(id, follow)
      .pipe(map((response: ApiResponseSingle<Candidate>) => RequestService.getData(response)));
  }

  getComments(docId: string): Observable<ApiResponseMultiple<Comment>> {
    return this.restService.Profile().getComments(docId);
  }

  addComment(comment: Comment): Observable<ApiResponseSingle<Comment>> {
    return this.restService.Profile().addComment(comment);
  }

  removeComment(commentId: string, docId: string): Observable<BaseApiResponse> {
    return this.restService.Profile().removeComment(commentId, docId);
  }

  editComment(commentId: string, docId: string, comment: string): Observable<Comment> {
    return this.restService
      .Profile()
      .editComment(commentId, docId, comment)
      .pipe(map((data: ApiResponseSingle<Comment>) => RequestService.getData(data)));
  }

  search(queryString: string): Observable<ApiResponseSingle<SearchResponse>> {
    return this.restService.Profile().search(queryString);
  }

  getSearchResult(queryString: string): Observable<SearchResponse> {
    return this.search(queryString).pipe(
      map((data: ApiResponseSingle<SearchResponse>) => RequestService.getData(data))
    );
  }

  filters(queryString: string): Observable<FacetResponseData> {
    return this.restService
      .Profile()
      .filters(queryString)
      .pipe(map((data: ApiResponseSingle<FacetResponseData>) => RequestService.getData(data)));
  }

  getSubscriptions(queryString: string): Observable<ApiResponseSingle<SearchResponse>> {
    return this.restService.Profile().getSubscriptions(queryString);
  }

  getProfileContacts(profileId: string = null, url: string = null): Observable<ContactUserDto[]> {
    return this.restService
      .Profile()
      .getProfileContacts(profileId, url)
      .pipe(
        map((response: ApiResponseSingle<ContactUserDto[]>) => RequestService.getData(response))
      );
  }

  openProfileContacts(profileId: string = null, url: string = null): Observable<ContactUserDto[]> {
    return this.restService
      .Profile()
      .openProfileContacts(profileId, url)
      .pipe(
        map((response: ApiResponseSingle<ContactUserDto[]>) => RequestService.getData(response))
      );
  }

  searchProfileContacts(
    profileId: string = null,
    url: string = null
  ): Observable<ContactUserDto[]> {
    return this.restService
      .Profile()
      .searchProfileContacts(profileId, url)
      .pipe(
        map((response: ApiResponseSingle<ContactUserDto[]>) => RequestService.getData(response))
      );
  }

  getImage(location: string): Observable<Blob> {
    return this.restService.Profile().getImage(location);
  }

  getProfileLinks(docId: string): Observable<LinkDto[]> {
    return this.restService
      .Profile()
      .getProfileLinks(docId)
      .pipe(map((response: ApiResponseSingle<LinkDto[]>) => RequestService.getData(response)));
  }

  addContact(dto: ContactUserDto): Observable<ContactUserDto> {
    return this.restService
      .Profile()
      .addContact(dto)
      .pipe(map((response: ApiResponseSingle<ContactUserDto>) => RequestService.getData(response)));
  }

  deleteContact(dto: ContactUserDto): Observable<ContactUserDto> {
    return this.restService
      .Profile()
      .deleteContact(dto)
      .pipe(map((response: ApiResponseSingle<ContactUserDto>) => RequestService.getData(response)));
  }

  updateContact(dto: ContactUserDto): Observable<ContactUserDto> {
    return this.restService
      .Profile()
      .updateContact(dto)
      .pipe(map((response: ApiResponseSingle<ContactUserDto>) => RequestService.getData(response)));
  }

  flagContact(dto: ContactUserDto): Observable<ContactUserDto> {
    return this.restService
      .Profile()
      .flagContact(dto)
      .pipe(map((response: ApiResponseSingle<ContactUserDto>) => RequestService.getData(response)));
  }

  // other methods:

  shareCurrentCandidate(candidate: Candidate): void {
    this.currentCandidateSubject.next(candidate);
  }

  getCandidateDefaultColorByIndex(index: number): SystemColors {
    let resultColor: SystemColors;

    index++;

    if (index % 4 === 0) {
      resultColor = 'anger';
    } else if ((index - 3) % 4 === 0) {
      resultColor = 'feathers';
    } else if ((index - 2) % 4 === 0) {
      resultColor = 'wings';
    } else if ((index - 1) % 4 === 0) {
      resultColor = 'beaks';
    }

    return resultColor;
  }
}

import { Component } from '@angular/core';

import { environment } from 'src/environments/environment';
import { ExtensionService } from '../../services/extension.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-extension-block',
  templateUrl: './extension-block.component.html',
  styleUrls: ['./extension-block.component.scss']
})
export class ExtensionBlockComponent {
  show$: Observable<boolean> = this.extension.showInstallInfo();

  constructor(private extension: ExtensionService) {}

  extensionLink = environment.extensionLink;

  close(): void {
    this.extension.hideInfoTemporarily();
  }
}

import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap } from 'rxjs/operators';

import { AuthService, ErrorService, NotificationService, UserService } from '../services';
import { NotificationTypeEnum } from '../enums';
import { ApiError, User } from '../models';
import { TokenUtilities } from './token.utilities';

interface InterceptorData {
  request: HttpRequest<any>;
  next: HttpHandler;
  requestToken?: string;
  currentToken?: string;
  error?: HttpErrorResponse;
}

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private notificationService: NotificationService,
    private errorService: ErrorService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (TokenUtilities.isItRefreshingRequest(request)) {
      return this.interceptRefreshingRequest(request, next);
    }

    if (TokenUtilities.isItRequestWithoutAuthHeader(request)) {
      return this.interceptRequestWithoutAuthHeader(request, next);
    }

    return this.sendRequest(request, next);
  }

  private sendRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestToken: string = this.authService.getAccessToken();
    const requestClone: HttpRequest<any> = TokenUtilities.getRequestWithAuthHeader(
      requestToken,
      request
    );

    if (requestClone) {
      return next.handle(requestClone).pipe(
        catchError((error: HttpErrorResponse) => {
          return this.catchErrorOnSendRequest({
            request,
            next,
            requestToken,
            error
          });
        })
      );
    }

    return of(null);
  }

  private catchErrorOnSendRequest(data: InterceptorData): Observable<HttpEvent<any>> {
    const { request, next, requestToken, error } = data;
    const is401or403Error: boolean = TokenUtilities.is401or403Error(error);

    if (is401or403Error) {
      return this.handleAuthRequestError({ request, next, requestToken });
    } else if (!TokenUtilities.isItRequestListWithoutErrorMessages(request.url, error.status)) {
      this.handleNoAuthError(request.url, error);
    }

    return throwError(error);
  }

  private handleAuthRequestError(data: InterceptorData): Observable<HttpEvent<any>> {
    const currentToken = this.authService.getAccessToken();

    if (currentToken) {
      return this.handleAuthRequestErrorIfTokenExist({ ...data, currentToken });
    }

    return this.doLogout();
  }

  private handleAuthRequestErrorIfTokenExist(data: InterceptorData): Observable<HttpEvent<any>> {
    const { request, next, requestToken, currentToken } = data;

    if (requestToken === currentToken) {
      if (this.authService.getIsAccessTokenRefreshing()) {
        return this.holdRequest(request, next);
      }

      return this.doRefresh({ request, next, requestToken: currentToken });
    }

    return this.handleRequestOnFinish(request, next);
  }

  private handleRequestOnFinish(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token: string = this.authService.getAccessToken();
    const requestClone: HttpRequest<any> = TokenUtilities.getRequestWithAuthHeader(token, request);

    return next
      .handle(requestClone)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.handleRequestOnFinishError(request.url, error)
        )
      );
  }

  private handleRequestOnFinishError(
    requestUrl: string,
    error: HttpErrorResponse
  ): Observable<HttpEvent<any>> {
    const is401or403Error: boolean = TokenUtilities.is401or403Error(error);

    if (is401or403Error) {
      return this.doLogout();
    } else if (TokenUtilities.isItRequestListWithoutErrorMessages(requestUrl, error.status)) {
      return throwError(error);
    }

    this.handleNoAuthError(requestUrl, error);

    return throwError(error);
  }

  private doRefresh(data: InterceptorData): Observable<HttpEvent<any>> {
    const { request, next, requestToken } = data;

    this.authService.startTokenRefreshing();

    return this.userService.refresh().pipe(
      catchError(() => {
        this.authService.stopTokenRefreshing();

        if (requestToken === this.authService.getAccessToken()) {
          return this.doLogout();
        }

        return of(null);
      }),
      switchMap((refreshData: HttpResponse<User>) => {
        if (refreshData) {
          this.authService.handleDataAfterRefresh(refreshData);
          this.authService.stopTokenRefreshing();
        }

        return this.handleRequestOnFinish(request, next);
      }),
      finalize(() => this.getRefreshFinalize())
    );
  }

  private getRefreshFinalize(): Observable<HttpEvent<any>> {
    this.authService.stopTokenRefreshing();
    this.authService.handleHeldRequests();

    return of(null);
  }

  private holdRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const subject = new BehaviorSubject<boolean>(false);
    const subject$ = subject.asObservable();

    this.authService.pushItemAtRequestsQueue(subject);

    return subject$.pipe(
      filter((handle: boolean) => !!handle),
      switchMap(() => {
        const token: string = this.authService.getAccessToken();
        const requestClone: HttpRequest<any> = TokenUtilities.getRequestWithAuthHeader(
          token,
          request
        );

        return next.handle(requestClone);
      })
    );
  }

  private interceptRequestWithoutAuthHeader(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.handleNoAuthError(request.url, error);

        return throwError(error);
      })
    );
  }

  private doLogout(): Observable<never> {
    this.clearRefreshingProcess();
    this.authService.fullUserLogout(true);

    return EMPTY;
  }

  private clearRefreshingProcess(): void {
    this.authService.stopTokenRefreshing();
    this.authService.resetRequestsQueue();
  }

  // After this throError is returned
  private handleNoAuthError(requestUrl: string, error: HttpErrorResponse): void {
    if (!this.authService.isOnline()) {
      this.notificationService.notify(
        NotificationTypeEnum.ERROR,
        'NOTIFICATION.NO_INTERNET_CONNECTION'
      );

      return;
    }

    const handledError: HttpErrorResponse = TokenUtilities.getHandledHttpError(error);
    const errorMessage = handledError?.error?.message || 'NOTIFICATION.TRY_AGAIN_LATER';
    const showError: boolean = this.getShowErrorMessageCondition(
      requestUrl,
      errorMessage,
      error.error?.httpStatusCode
    );

    if (showError) {
      this.notificationService.notify(NotificationTypeEnum.ERROR, errorMessage);
      this.errorService.setLastErrorMessage(errorMessage);
    }
  }

  private interceptRefreshingRequest(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const refreshToken: string = this.authService.getRefreshToken();

    if (refreshToken) {
      const requestClone: HttpRequest<any> = TokenUtilities.getRequestWithAuthHeader(
        refreshToken,
        request
      );

      return next.handle(requestClone);
    } else {
      return this.doLogout();
    }
  }

  private getShowErrorMessageCondition(
    requestUrl: string,
    errorMessage: string,
    httpStatusCode: number
  ): boolean {
    const lastErrorMessage: string = this.errorService.getLastErrorMessage();
    const notShowMessage: boolean =
      TokenUtilities.notShowErrorRequestList().some((url: string) => requestUrl.includes(url)) ||
      TokenUtilities.getNotShowMessageInfoList().some(
        (info: ApiError) => errorMessage === info.message && httpStatusCode === info.httpStatusCode
      );

    return (
      !notShowMessage &&
      !(lastErrorMessage === 'NOTIFICATION.TRY_AGAIN_LATER' && lastErrorMessage === errorMessage)
    );
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RestService } from './rest.service';
import { SearchSort } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {
  constructor(private restService: RestService) {}

  getSorting(): Observable<SearchSort[]> {
    return this.restService.Catalog().getSorting();
  }
}

import { CompanySizeFilterItem } from '../../../../../shared/models/candidate/companySize';
import { SpecificFacetType } from '../../../../../shared/models';

export class CompanySizeFilterData {
  private static CompanySizeRangeList: string[] = [
    '0-1',
    '2-10',
    '11-50',
    '51-200',
    '201-500',
    '501-1000',
    '1001-5000',
    '5001-10000',
    '10001'
  ];

  static labelSeparator = '-';
  static facetFilterTypePrefix = 'size_';

  static getFilterList(): CompanySizeFilterItem[] {
    return CompanySizeFilterData.CompanySizeRangeList.map((range: string, index: number) => {
      const value: string = range.split(CompanySizeFilterData.labelSeparator)[0];
      const cssClass: string = CompanySizeFilterData.getCompanyCssClass(value);
      const facetFilterType: SpecificFacetType =
        `${CompanySizeFilterData.facetFilterTypePrefix}${value}` as SpecificFacetType;
      const labelFull: string = range.split('-').join(' - ');

      return {
        value,
        label: range,
        class: cssClass,
        id: index,
        facetType: 'companySize',
        specificFacetType: facetFilterType,
        checked: false,
        labelFull
      };
    });
  }

  private static getCompanyCssClass(company: string): string {
    const cssClassPrefix = 'company';
    const separator = '-';

    return `${cssClassPrefix}${separator}${company}`;
  }
}

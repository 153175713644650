import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { CandidateSourcesService } from '../../../../../../shared/services';
import { ConditionInfo, Link, LinkFull } from '../../../../../../shared/models';

@Component({
  selector: 'app-candidate-card-links',
  templateUrl: './candidate-card-links.component.html',
  styleUrls: ['./candidate-card-links.component.scss']
})
export class CandidateCardLinksComponent implements OnInit, OnDestroy {
  constructor(private sourcesService: CandidateSourcesService) {}

  @Input() candidateSources: Link[];
  @Input() revealed = false;

  visibleRowNumber = 1;
  gap = 3;

  fullCandidateSources: LinkFull[] = [];
  revealedCardInfo: ConditionInfo = {
    condition: false
  };

  ngOnInit(): void {
    this.setRevealedInfo();
    this.setFabIcons();
  }

  private setRevealedInfo(): void {
    this.revealedCardInfo = {
      condition: this.revealed
    };
  }

  private setFabIcons(): void {
    if (this.candidateSources) {
      this.candidateSources.forEach((link: Link) => {
        const particularLinkInfo: LinkFull = this.sourcesService.getParticularLinkInfo(link);
        const domainName: string = this.sourcesService.getDomainName(link.domain);

        this.sourcesService.handleSourceListByLink(link);

        if (particularLinkInfo) {
          this.fullCandidateSources.push({
            ...link,
            ...particularLinkInfo,
            domainName
          });
        } else {
          this.fullCandidateSources.push({ ...link, domainName });
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.fullCandidateSources = null;
  }
}

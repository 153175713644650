<label
  class="radio-buttons__item"
  [class.checked-item]="checked"
  [class.disabled]="disabled"
  [class.break-word]="breakWord">
  <span class="radio-buttons__text">
    {{ text | translate }}

    <ng-content></ng-content>
  </span>

  <input
    class="sidebar-saved-searches-link"
    type="radio"
    [checked]="checked"
    [disabled]="disabled"
    (change)="onValueChanged()">
</label>

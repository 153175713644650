import { Component, Input } from '@angular/core';

import { ContactsInfoMap } from '../../../../../../shared/models';

@Component({
  selector: 'app-hidden-candidate-contacts',
  templateUrl: './hidden-candidate-contacts.component.html',
  styleUrls: ['./hidden-candidate-contacts.component.scss']
})
export class HiddenCandidateContactsComponent {
  @Input() contactsData: ContactsInfoMap;
}

import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';

import { NotificationTypeEnum } from '../../enums';
import { LocalizationService } from '../localization';
import { NotificationExtraData } from '../../models';
import { NotifierNotificationOptions } from 'angular-notifier/lib/models/notifier-notification.model';

type Notification = {
  id: string;
  type: string;
  message: string;
  showUntil: number;
};

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private notifierService: NotifierService,
    private localization: LocalizationService
  ) {}

  private readonly autoHide = 3000;
  private trackedNotifications = new Set<Notification>();

  notify(
    type: NotificationTypeEnum,
    message: string,
    interpolateParams: Object = {},
    extraData: NotificationExtraData = {}
  ): void {
    const translatedMessage: string = this.localization.translateInstant(
      message,
      interpolateParams
    );

    if (translatedMessage) {
      const { template = null, autoHide = this.autoHide } = extraData;
      const id = extraData?.id || Math.random().toString();

      const notificationOptions: NotifierNotificationOptions = {
        type,
        message: translatedMessage,
        id,
        template
      };

      if (autoHide) {
        this.showAndAutoHide(notificationOptions, autoHide);
      } else {
        this.show(notificationOptions);
      }
    }
  }

  private show(notificationOptions: NotifierNotificationOptions) {
    this.notifierService.show(notificationOptions);
  }

  private showAndAutoHide(notificationOptions: NotifierNotificationOptions, delayMs: number): void {
    const { type, id = '', message } = notificationOptions;

    const alreadyShownNotification = this.getTrackedNotification({ message: message });
    if (alreadyShownNotification) {
      alreadyShownNotification.showUntil = Date.now() + delayMs;
      return;
    }

    this.notifierService.show(notificationOptions);

    if (notificationOptions.type === NotificationTypeEnum.ERROR) {
      this.trackedNotifications.add({ id, type, message, showUntil: 0 });
    }

    this.autoHideNotification(id, delayMs);
  }

  private autoHideNotification(id: string, delayMs: number): void {
    if (!id) {
      return;
    }

    setTimeout(() => {
      const trackedNotification = this.getTrackedNotification({ id });
      if (!trackedNotification) {
        this.notifierService.hide(id);
        return;
      }

      const timeoutElapsed = trackedNotification.showUntil < Date.now();
      if (timeoutElapsed) {
        this.notifierService.hide(id);
        this.trackedNotifications.delete(trackedNotification);
      } else {
        this.autoHideNotification(id, trackedNotification.showUntil - Date.now());
      }
    }, delayMs);
  }

  private getTrackedNotification(selector: { id?: string; message?: string }): Notification {
    const { id, message } = selector;
    const trackedNotifications = Array.from(this.trackedNotifications.values());

    return trackedNotifications.find((notification: Notification) => {
      const idPass = id === undefined || notification.id === id;
      const messagePass = message === undefined || notification.message === message;

      return idPass && messagePass;
    });
  }
}

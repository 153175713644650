import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Account, LimitationModalData, ModalInfo } from '../../../../shared/models';
import { SystemPages } from '../../../../shared/enums/routerPaths.enum';
import { AccountService, AuthService } from '../../../../shared/services';
import { filter, map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';

@Component({
  selector: 'app-limitation-modal',
  templateUrl: './limitation-modal.component.html',
  styleUrls: ['./limitation-modal.component.scss']
})
export class LimitationModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<LimitationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LimitationModalData,
    private authService: AuthService,
    private accountService: AccountService
  ) {}

  actionText = 'LIMITATION_MODAL.GO_TO_PAYMENT';
  actionRouterLink = SystemPages.BILLING;

  info: ModalInfo = {
    type: 'success-modal',
    closeModalBtnId: 'close-plan-limitation-m',
    header: {
      title: 'LIMITATION_MODAL.TITLE'
    }
  };

  ready$: Observable<boolean>;

  private limitAccess: boolean;

  ngOnInit(): void {
    this.ready$ = combineLatest([
      this.authService.isAdmin$,
      this.accountService.currentAccountInfo$.pipe(
        filter((account: Account) => !!account && this.limitAccess === undefined)
      )
    ]).pipe(
      map(([isAdmin, account]) => {
        this.info.disableClose = this.data.disableClose;

        this.limitAccess = this.accountService.getIsAccountBlocked(account);
        const freeTariff: boolean = this.accountService.getIsFreeTariff(account);
        const notAllowedAccess: boolean = this.limitAccess || freeTariff;
        const paymentAction: boolean = isAdmin && notAllowedAccess;

        switch (this.data.type) {
          case 'daily':
            this.setTextsForDailyType(notAllowedAccess, isAdmin, paymentAction);
            break;
          case 'plan':
            this.setTextsForPlanType(notAllowedAccess, isAdmin, paymentAction);
            break;
        }

        return true;
      })
    );
  }

  closeModal(condition: boolean = false): void {
    if (condition || !this.data.disableClose) {
      this.dialogRef.close(condition);
    }
  }

  private setTextsForPlanType(
    notAllowedAccess: boolean,
    isAdmin: boolean,
    paymentAction: boolean
  ): void {
    if (notAllowedAccess || this.data.shortenText) {
      this.info.header.text = isAdmin
        ? 'LIMITATION_MODAL.PLAN_SHORTEN.TEXT_FOR_ADMIN'
        : 'LIMITATION_MODAL.PLAN_SHORTEN.TEXT_FOR_USER';
    } else {
      this.info.header.text = isAdmin
        ? 'LIMITATION_MODAL.PLAN.TEXT'
        : 'LIMITATION_MODAL.PLAN.TEXT_FOR_USER';
    }

    if (this.data.withAction || paymentAction) {
      this.actionText = 'LIMITATION_MODAL.GO_TO_PAYMENT';
      this.actionRouterLink = SystemPages.BILLING;
      this.info.actions = {
        submitWithClothing: { content: true }
      };
    }
  }

  private setTextsForDailyType(
    notAllowedAccess: boolean,
    isAdmin: boolean,
    paymentAction: boolean
  ): void {
    if (notAllowedAccess) {
      this.info.header.text = isAdmin
        ? 'LIMITATION_MODAL.PLAN_SHORTEN.TEXT_FOR_ADMIN'
        : 'LIMITATION_MODAL.PLAN_SHORTEN.TEXT_FOR_USER';
    } else {
      this.info.header.text = 'LIMITATION_MODAL.DAILY.TEXT';
    }

    if (this.data.withAction || paymentAction) {
      this.actionText = paymentAction
        ? 'LIMITATION_MODAL.GO_TO_PAYMENT'
        : 'LIMITATION_MODAL.GO_TO_SEARCH';
      this.actionRouterLink = paymentAction ? SystemPages.BILLING : SystemPages.SEARCH;

      this.info.actions = {
        submitWithClothing: { content: true }
      };
    }
  }
}

<form
  #dropReference
  class="tags-form {{ sizeType }} redesign">
  <fieldset
    class="field without-wrapper">
    <label class="flex">
      <input
        #input
        [size]="control.value ? control.value.length : 1"
        [formControl]="control"
        [minlength]="1"
        [maxlength]="tagNameMaxLength"
        class="field__input field__input--sm add"
        [ngClass]="systemLocale$ | async"
        type="text"
        (click)="clickInput($event)"
        (keydown)="onInputKeydown($event)"
        (input)="onInput()">
    </label>

    <app-custom-dropdown
      [reference]="dropReference"
      #dropdownComp>
      <app-user-tags-dropdown-content
        [filteredAutocompleteList$]="filteredAutocompleteList$"
        [showDropdownInfo]="showDropdownInfo"
        [sizeType]="sizeType"
        [holdDownSiblingsClass]="holdDownSiblingsClass"
        [holdDownSiblingsSelector]="holdDownSiblingsSelector"
        (hideDropdownOnClickOutside)="hideDropdownOnClickOutside()"
        (setItem)="setItem($event)">
      </app-user-tags-dropdown-content>
    </app-custom-dropdown>

    <div
      class="add-item-block"
      [class.disabled]="disabledCreateBtn">
      <button
        class="btn"
        (click)="createItem()"
        [disabled]="disabledCreateBtn">
        <svg
          width="14"
          height="14"
          class="icon">
          <use xlink:href="#add-icon">
          </use>
        </svg>
      </button>
    </div>
  </fieldset>
</form>


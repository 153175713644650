export enum FolderPage {
  DEFAULT_PAGE = 1,
  PROFILES_PAGE_SIZE = 30,
  URL = '/folders'
}

export enum FolderPageQueries {
  PREVIEW = 'preview',
  STATE = 'state',
  ACCESS = 'access'
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tag } from '../../../../../shared/models';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent {
  @Input() tag: Tag;
  @Input() viewOnly = false;
  @Input() firstItem = false;
  @Input() editing = false;
  @Input() size: 'standard' | 'large' = 'large';
  @Input() rounded = false;

  @Output() remove = new EventEmitter<null>();
  @Output() edit = new EventEmitter<null>();

  onRemove(): void {
    this.remove.emit(null);
  }

  onEdit(): void {
    this.edit.emit(null);
  }
}

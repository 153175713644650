import { Directive, ElementRef, Input } from '@angular/core';
import { FolderStatusComponentType } from '../../../../../../shared/types';
import { FolderStatusesService } from '../../../../services';

@Directive({
  selector: '[appFolderStatusCircle]'
})
export class FolderStatusCircleDirective {
  constructor(
    private element: ElementRef,
    private statusesService: FolderStatusesService
  ) {}

  private _color: string;
  private _active: boolean;

  @Input() type: FolderStatusComponentType;

  @Input() set color(color: string) {
    this._color = color;

    this.element.nativeElement.style.borderColor = this.statusesService.getStatusColorValue(
      this._color
    );
  }

  @Input() set active(active: boolean) {
    this._active = active;

    this.setCircleColorOnActiveChanged(this._active);
  }

  private setCircleColorOnActiveChanged(active: boolean): void {
    const colorValue = this.statusesService.getStatusColorValue(this._color);

    if (this.type === 'text' || active) {
      this.element.nativeElement.style.backgroundColor = colorValue;
    }
  }
}

import { Inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, of, Subject, Subscription } from 'rxjs';
import { delay, filter, map, take } from 'rxjs/operators';

import { RoleType, User } from '../models';
import { HeaderTypeEnum, NotificationTypeEnum } from '../enums';
import { LoggerService, NotificationService } from './helpers';
import { UserService } from './requests';
import { CookiesService, StorageService } from './global';
import { ScrollHandlerService } from './scroll';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    @Inject('Navigator') private navigator: Navigator,
    private notificationService: NotificationService,
    private router: Router,
    private userService: UserService,
    private storageService: StorageService,
    private scrollHandler: ScrollHandlerService,
    private logger: LoggerService,
    private cookie: CookiesService
  ) {
    this.accessToken = this.storageService.getAccessToken();
    this.refreshToken = this.storageService.getRefreshToken();
  }

  private accessToken: string;
  private refreshToken: string;
  private jwtService: JwtHelperService = new JwtHelperService();
  private isTokenRefreshing: boolean;

  private requestQueue: BehaviorSubject<boolean>[] = [];
  private onLogoutSubject = new Subject();
  private isThatTabDoRefreshing: boolean;

  onLogout$ = this.onLogoutSubject.asObservable();

  private logout$: Subscription;
  private logoutDelay$: Subscription;

  isAdmin$ = this.userService.currentUser$.pipe(
    filter((user: User) => !!user),
    map((user: User) => user.role === 'ROLE_ADMIN')
  );
  isSuperAdmin$ = new BehaviorSubject<boolean>(false);

  // simple get utils:

  getAccessToken(): string {
    this.loadActualAccessToken();

    return this.accessToken;
  }

  getRefreshToken(): string {
    this.loadActualRefreshToken();

    return this.refreshToken;
  }

  getUserId(): string {
    this.loadActualAccessToken();

    return this.jwtService.decodeToken(this.accessToken)?.userId;
  }

  getIsUserLoggedIn(): boolean {
    this.loadActualAccessToken();

    return !!this.accessToken;
  }

  getIsThatTabDoRefreshing(): boolean {
    return this.isThatTabDoRefreshing;
  }

  getIsAccessTokenRefreshing(): boolean {
    return this.isTokenRefreshing;
  }

  getIsAdmin(): boolean {
    let isAdmin = false;

    const user = this.userService.getUser();
    if (user) {
      const role: RoleType = user.role;
      isAdmin = role === 'ROLE_ADMIN';
    }

    return isAdmin;
  }

  getIsSuperAdmin(): boolean {
    let isSuperAdmin = false;

    if (this.accessToken) {
      const decodedToken = this.jwtService.decodeToken(this.accessToken);
      isSuperAdmin = decodedToken?.sa;
    }

    return isSuperAdmin;
  }

  isOnline(): boolean {
    if (this.navigator?.onLine !== undefined) {
      return this.navigator.onLine;
    }

    return true;
  }

  // simple void utils:

  setAccessToken(accessToken: string): void {
    this.accessToken = accessToken;

    this.storageService.setAccessToken(accessToken);
  }

  saveRefreshToken(refreshToken: string): void {
    this.refreshToken = refreshToken;

    this.storageService.setRefreshToken(refreshToken);
  }

  loadActualRefreshToken(): void {
    this.refreshToken = this.storageService.getRefreshToken();
  }

  loadActualAccessToken(): void {
    this.accessToken = this.storageService.getAccessToken();

    this.launchRolesAtToken();
  }

  startTokenRefreshing(): void {
    this.isTokenRefreshing = true;
    this.isThatTabDoRefreshing = true;
  }

  stopTokenRefreshing(): void {
    this.isTokenRefreshing = false;
  }

  resetRequestsQueue(): void {
    if (this.requestQueue?.length) {
      this.requestQueue = [];
    }
  }

  pushItemAtRequestsQueue(subject: BehaviorSubject<boolean>): void {
    if (subject) {
      this.requestQueue.push(subject);
    }
  }

  // handlers:

  handleDataAfterRefresh(data: HttpResponse<User>, withUser: boolean = true): void {
    const newAccessToken: string = data.headers?.get(HeaderTypeEnum.JWT_TOKEN);
    const newRefreshToken: string = data.headers?.get(HeaderTypeEnum.JWT_REFRESH_TOKEN);
    const newUser: User = withUser ? data.body : null;

    if (newAccessToken && newRefreshToken) {
      this.saveDataAfterRefresh(newAccessToken, newRefreshToken, newUser);
    }
  }

  fullUserLogout(
    withMessage: boolean = true,
    errorMessage: string = 'NOTIFICATION.YOU_NEED_TO_LOGIN_AGAIN'
  ): void {
    this.loadActualAccessToken();
    const isNotAlreadyLogout: boolean = !!this.accessToken;

    if (isNotAlreadyLogout) {
      this.logout$ = this.userService.logout().pipe(take(1)).subscribe();
    }

    this.accessToken = null;
    this.storageService.clear();
    this.cookie.clearCookieBeforeLogout();

    this.scrollHandler.scrollTo(0, 'auto');
    this.logoutWithNavigate(isNotAlreadyLogout, withMessage, errorMessage);
  }

  saveDataAfterRefresh(accessToken: string, refreshToken: string, userNew: User): void {
    this.setAccessToken(accessToken);
    this.saveRefreshToken(refreshToken);

    if (userNew) {
      this.userService.updateUser(userNew);
    }
  }

  handleRefreshingOnDestroy(): void {
    const isThatTabDoRefreshing: boolean = this.getIsThatTabDoRefreshing();
    const isAccessTokenRefreshing: boolean = this.getIsAccessTokenRefreshing();

    if (isThatTabDoRefreshing && isAccessTokenRefreshing) {
      this.stopTokenRefreshing();
    }
  }

  handleHeldRequests(): void {
    if (this.getAccessToken()) {
      while (this.requestQueue?.length) {
        this.requestQueue.shift().next(true);
      }
    }
  }

  sendOnLogout(): void {
    this.onLogoutSubject.next(null);
  }

  unsubscribe(): void {
    if (this.logout$) {
      this.logout$.unsubscribe();
    }

    if (this.logoutDelay$) {
      this.logoutDelay$.unsubscribe();
    }
  }

  private logoutWithNavigate(
    isNotAlreadyLogout: boolean,
    withMessage: boolean,
    errorMessage: string
  ): void {
    this.router.navigateByUrl('/').then(() => {
      this.sendOnLogout();

      if (isNotAlreadyLogout && withMessage) {
        const logoutDelay = 200;

        this.logoutDelay$ = of(null)
          .pipe(delay(logoutDelay), take(1))
          .subscribe(() => {
            this.notificationService.notify(NotificationTypeEnum.ERROR, errorMessage);
          });
      }
    });
  }

  private launchRolesAtToken(): void {
    let isSuperAdmin = this.getIsSuperAdmin();

    this.isSuperAdmin$.next(isSuperAdmin);
  }
}

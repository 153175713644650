import { FolderStatusColorType } from '../../types';
import { FolderStatus } from '../../models';

export class FolderStatusConstructor {
  constructor(
    public name: string,
    public archived?: boolean,
    public defaultStatus?: boolean,
    public systemStatus?: boolean,
    public color?: FolderStatusColorType,
    public userId?: string,
    public id?: string,
    public orgId?: string,
    public position?: number
  ) {}

  static toInstance(data: FolderStatus): FolderStatus {
    if (data) {
      return new FolderStatusConstructor(
        data.name,
        data.archived,
        data.defaultStatus,
        data.systemStatus,
        data.color,
        data.userId,
        data.id,
        data.orgId,
        data.position
      );
    }

    return data;
  }
}

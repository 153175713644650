import { Injectable } from '@angular/core';

import { CodesVariableNamesEnum } from '../../enums';
import { AdminPanelFilterType, Locale } from '../../models';
import { ReviewedFilterInfo } from '../../classes';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() {}

  // ********************** Setters **********************

  static setReviewedFilterInfo(data: ReviewedFilterInfo): void {
    localStorage.setItem(CodesVariableNamesEnum.reviewedFilterInfo, JSON.stringify(data));
  }

  setAccessToken(accessToken: string): void {
    localStorage.setItem(CodesVariableNamesEnum.accessToken, accessToken);
  }

  setRefreshToken(refreshToken: string): void {
    localStorage.setItem(CodesVariableNamesEnum.refreshToken, refreshToken);
  }

  setIsTokenRefreshing(isTokenRefreshing: boolean = false): void {
    localStorage.setItem(CodesVariableNamesEnum.isTokenRefreshing, isTokenRefreshing.toString());
  }

  setLocale(locale: Locale): void {
    localStorage.setItem(CodesVariableNamesEnum.locale, locale);
  }

  setAdminPanelFilter(filtersData: AdminPanelFilterType[]): void {
    localStorage.setItem(CodesVariableNamesEnum.adminFilterList, JSON.stringify(filtersData));
  }

  setUTMSource(label: string): void {
    localStorage.setItem(CodesVariableNamesEnum.UTMSource, label);
  }

  setExtensionAdCloseDate(date: Date): void {
    localStorage.setItem(CodesVariableNamesEnum.extensionAdCloseDate, String(date.getTime()));
  }

  // ********************** Getters **********************

  static getReviewedFilterInfo(): ReviewedFilterInfo {
    const data: string = localStorage.getItem(CodesVariableNamesEnum.reviewedFilterInfo);

    return data ? JSON.parse(data) : null;
  }

  getAccessToken(): string {
    return localStorage.getItem(CodesVariableNamesEnum.accessToken);
  }

  getRefreshToken(): string {
    return localStorage.getItem(CodesVariableNamesEnum.refreshToken);
  }

  getIsTokenRefreshing(): boolean {
    const isTokenRefreshing: string = localStorage.getItem(
      CodesVariableNamesEnum.isTokenRefreshing
    );

    return (isTokenRefreshing && JSON.parse(isTokenRefreshing)) || false;
  }

  getLocale(): Locale {
    return localStorage.getItem(CodesVariableNamesEnum.locale) as Locale;
  }

  getAdminPanelFilter(): AdminPanelFilterType[] {
    const info: string = localStorage.getItem(CodesVariableNamesEnum.adminFilterList);

    return info && JSON.parse(info);
  }

  getUTMSource(): string {
    return localStorage.getItem(CodesVariableNamesEnum.UTMSource);
  }

  getExtensionAdCloseDate(): Date {
    const timestamp = localStorage.getItem(CodesVariableNamesEnum.extensionAdCloseDate);
    return new Date(parseInt(timestamp) || 0);
  }

  // ********************** Clear **********************

  clear(): void {
    localStorage.removeItem(CodesVariableNamesEnum.accessToken);
    localStorage.removeItem(CodesVariableNamesEnum.refreshToken);
    localStorage.removeItem(CodesVariableNamesEnum.isTokenRefreshing);
    localStorage.removeItem(CodesVariableNamesEnum.users);
    localStorage.removeItem(CodesVariableNamesEnum.categorySwitchersState);
    localStorage.removeItem(CodesVariableNamesEnum.adminFilterList);
    localStorage.removeItem(CodesVariableNamesEnum.reviewedFilterInfo);
    localStorage.removeItem(CodesVariableNamesEnum.UTMSource);
  }

  clearUTMSource(): void {
    localStorage.removeItem(CodesVariableNamesEnum.UTMSource);
  }
}

export enum FilterParam {
  byCompany = 'byCompany',
  byCompanyDateRange = 'byCompanyDateRange',
  withContacts = 'withContacts',
  byExperience = 'byExperience',
  byLanguage = 'byLanguage',
  byLevel = 'byLevel',
  byLastExperience = 'byLastExperience',
  order = 'order',
  notViewed = 'notViewed',
  notViewedLastDays = 'notViewedLastDays',
  notViewedByAccount = 'notViewedByAccount',
  notViewedByAccountLastDays = 'notViewedByAccountLastDays',
  byCompanySize = 'byCompanySize',
  byIndustry = 'byIndustry',
  byOpenToWork = 'byOpenToWork'
}

import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SafeUrl } from '@angular/platform-browser';

import { Candidate } from '../../../../../shared/models';
import {
  CandidateCardHandlerService,
  CandidateImageHandlerService
} from '../../../../../shared/services';
import { CandidateProfileHandlerService } from '../../../services/candidates/candidate-profile-handler.service';

@Component({
  selector: 'app-candidate-card',
  templateUrl: './candidate-card.component.html',
  styleUrls: ['./candidate-card.component.scss'],
  providers: [CandidateCardHandlerService, CandidateProfileHandlerService]
})
export class CandidateCardComponent implements OnInit, AfterViewInit {
  constructor(
    private imageHandler: CandidateImageHandlerService,
    private handlerService: CandidateCardHandlerService
  ) {}

  @Input() type: 'standard' | 'forFolder' = 'standard';
  @Input() candidate: Candidate;
  @Input() index = 0;

  // forFolder type only:
  @Output() removeFromFolder = new EventEmitter<null>();
  @Output() changeFolderStatus = new EventEmitter<null>();

  @Output() selectCandidate = new EventEmitter<boolean>();

  private updatePicture = new BehaviorSubject<SafeUrl>(null);
  private unsubscribe$ = new Subject<void>();

  revealed = false;

  picture$: Observable<SafeUrl> = this.updatePicture.asObservable();

  ngOnInit(): void {
    this.handlerService.setCandidateInfo(this.candidate);
  }

  ngAfterViewInit(): void {
    if (this.candidate?.pictureUrl) {
      this.updatePicture.next(this.candidate.pictureUrl);

      return;
    }

    this.imageHandler
      .getCandidateImageUrl(this.candidate)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((pictureUrl: SafeUrl) => {
        this.candidate.pictureUrl = pictureUrl as string;

        this.updatePicture.next(pictureUrl);
      });
  }

  toggleRevealCard(): void {
    this.revealed = !this.revealed;

    this.handlerService.updateRevealedCardInfo(this.revealed);
  }

  onPictureError(): void {
    this.candidate.pictureUrl = '';

    this.updatePicture.next('');
  }

  // *************** forFolder type only: ***************

  onChangeFolderStatus(): void {
    if (this.type === 'forFolder') {
      this.changeFolderStatus.emit();
    }
  }

  onSelectCandidate(): void {
    this.candidate.isSelected = !this.candidate.isSelected;

    this.selectCandidate.emit(this.candidate.isSelected);
  }

  onRemoveFromFolder(): void {
    if (this.type == 'forFolder') {
      this.removeFromFolder.emit(null);
    }
  }
}

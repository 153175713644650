import { Autocomplete, GooglePlace, Locale } from '../../models';
import { Localization } from '../localization';
import { SearchCategoryAutocomplete } from '../../models/search/searchCategory';

export class LocationAutocomplete implements SearchCategoryAutocomplete {
  static getLocationsAutocompleteResult(
    autocompleteList: GooglePlace[],
    systemLocale: Locale
  ): Autocomplete[] {
    return (autocompleteList as GooglePlace[]).map((googlePlace: GooglePlace) =>
      this.getLocationAutocompleteResult(googlePlace, systemLocale)
    );
  }

  static getLocationAutocompleteResult(
    googlePlace: GooglePlace,
    systemLocale: Locale
  ): Autocomplete {
    const name: string = Localization.getGooglePlaceName(googlePlace, systemLocale);

    return {
      name,
      id: googlePlace.googlePlaceId
    };
  }
}

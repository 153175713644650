import { Autocomplete, Company } from '../../models';
import {
  CategoryAutocompletes,
  SearchCategoryAutocomplete
} from '../../models/search/searchCategory';

export class CompanyAutocomplete implements SearchCategoryAutocomplete {
  constructor() {}

  static getAutocomplete(item: Company): Autocomplete {
    const id: string = item?.companyId?.toString() || item?.name;

    return {
      name: item?.name,
      id
    };
  }

  static getAutocompleteResult(autocompleteList: CategoryAutocompletes): Autocomplete[] {
    return (autocompleteList as Company[]).map((company: Company) => {
      const id: string = company?.companyId?.toString() || company?.name;

      return {
        name: company?.name,
        id
      };
    });
  }
}

import { AccountStatus } from './account';
import { TariffName } from './tariffInfo';
import { SortOrderType } from '../../types';
import { AutocompleteResponse } from '../search';

export interface AdminDataDto {
  block: AccountStatus;
  companyName: string;
  contactsLeft: number;
  contactsOpen: number;
  email: string;
  firstName: string;
  folderProfilesCount: number;
  foldersCount: number;
  jainDate: string;
  lastAction: string;
  lastLoginDate: string;
  lastName: string;
  notLocked?: boolean;
  openLinks: number;
  paidTill: string;
  profileOpen: number;
  searchPagesOpen: number;
  tariff: TariffName;
  trialEnd: string;
  userId: string;
  havePlugin: boolean;

  intercomUserUrl?: string;
  linkedinUrl?: string;
}

export interface AdminDataItemInfo {
  sort: AdminPanelSortType;
  title?: string;
  value?: string | number;

  otherValues?: AdminDataOtherValues;
  hasOtherValues?: boolean;
  links?: AdminDataLinks;
  hasLinks?: boolean;
  disabled?: boolean;

  viewType?: 'row' | 'column';
  cellType?: 'big' | 'medium' | 'small';

  isSortActive?: boolean;

  getValue(data: AdminDataDto, index?: number): void;
  getOtherValues?(data: AdminDataDto): void;
  getLinks?(data: AdminDataDto): void;
}

export interface AdminDataLinks {
  intercomUserUrl?: string;
  linkedinUrl?: string;
}

export interface AdminDataOtherValues {
  email?: string;
  status?: AccountStatus;
  notLocked?: boolean;
}

export type AdminPanelSortType =
  | 'firstName'
  | 'companyName'
  | 'jainDate'
  | 'lastAction'
  | 'lastLoginDate'
  | 'trialEnd'
  | 'block'
  | 'searchPagesOpen'
  | 'paidTill'
  | 'profileOpen'
  | 'openLinks'
  | 'contactsOpen'
  | 'contactsLeft'
  | 'tariff'
  | 'folderProfilesCount'
  | 'foldersCount'
  | 'havePlugin';

export interface AdminPanelFilter {
  filter: AdminPanelFilterType;
  label: string;
  value: boolean;
  applicationType: AdminFilterApplicationType;
}

export interface AdminFiltersData {
  mainFilter: AdminPanelFilter[];
  filterBySearch: AdminPanelFilter[];
}

export type AdminPanelFilterType =
  | 'search'
  | 'openContact'
  | 'openLink'
  | 'openProfile'
  | 'haveActive'
  | 'haveBlacked'
  | 'haveNotPaid'
  | 'havePaid'
  | 'haveActiveUsers'
  | 'haveNotActiveUsers';

export type AdminFilterApplicationType = 'bySearch' | 'main';

export type AdminPanelFilters = {
  [filter in AdminPanelFilterType]: AdminPanelFilter;
};

export interface AdminSearchDto {
  haveActive?: boolean;
  haveBlacked?: boolean;
  haveActiveUsers?: boolean;
  haveNotActiveUsers?: boolean;
  haveNotPaid?: boolean;
  havePaid?: boolean;
  companyList?: string[];
  name?: string;
  order?: SortOrderType;
  sort?: AdminPanelSortType;
}

export interface AdminLogRequestBody {
  filers: AdminPanelFilterType[];
  userId: string;
  size: number;
}

export interface AdminLogDto {
  autocompleteDto: AutocompleteResponse;
  dc: string;
  linkValue: string;
  profileId: string;
  query: string;
  type: AdminPanelFilterType;
}

export class AdminSearchDtoFilters {
  constructor() {}

  static toInstance(data: AdminSearchDto): AdminSearchDto {
    return {
      haveActive: data.haveActive,
      haveBlacked: data.haveBlacked,
      haveActiveUsers: data.haveActiveUsers,
      haveNotActiveUsers: data.haveNotActiveUsers,
      haveNotPaid: data.haveNotPaid,
      havePaid: data.havePaid
    };
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RestService } from './rest.service';
import { SearchQueryFromHistory, SearchQueryRequestInfo } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class QueryService {
  constructor(private restService: RestService) {}

  // query api:

  add(searchQuery: SearchQueryRequestInfo): Observable<null> {
    return this.restService.Query().add(searchQuery);
  }

  getSavedSearches(
    withAutocompleteDto: boolean = false,
    pageNumber: number = null
  ): Observable<SearchQueryRequestInfo[]> {
    return this.restService.Query().getList(withAutocompleteDto, pageNumber);
  }

  removeSavedSearchQuery(id: number): Observable<null> {
    return this.restService.Query().removeSavedSearchQuery(id);
  }

  renameSavedSearchQuery(id: number, newName: string): Observable<null> {
    return this.restService.Query().renameSavedSearchQuery(id, newName);
  }

  getSearchHistory(pageNumber: number): Observable<SearchQueryFromHistory[]> {
    return this.restService.Query().getSearchHistory(pageNumber);
  }

  getCountSearchesHistory(): Observable<number> {
    return this.restService
      .Query()
      .getCountSearchesHistory()
      .pipe(map((count: string = '') => (count ? +count : 0)));
  }

  getCountSavedSearches(): Observable<number> {
    return this.restService
      .Query()
      .getCountSavedSearches()
      .pipe(map((count: string = '') => (count ? +count : 0)));
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalInfo } from '../../../../../shared/models';

@Component({
  selector: 'app-remove-candidates-from-folder-modal',
  templateUrl: './remove-candidates-from-folder-modal.component.html',
  styleUrls: ['./remove-candidates-from-folder-modal.component.scss']
})
export class RemoveCandidatesFromFolderModalComponent {
  constructor(
    public dialogRef: MatDialogRef<RemoveCandidatesFromFolderModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { name?: string; multipleInfo?: string }
  ) {}

  info: ModalInfo = {
    type: 'alert-modal',
    closeModalBtnId: 'close-remove-candidates-from-folder-m',
    header: {
      titleContent: true
    },
    actions: {
      cancel: {
        id: 'm-cancel-remove-candidates-from-folder--btn'
      },
      submitWithClothing: {
        id: 'm-remove-candidates-from-folder--btn',
        extraClass: 'alert',
        type: 'delete'
      }
    }
  };

  closeModal(condition: boolean = false): void {
    this.dialogRef.close(condition);
  }
}

import {
  NextTariffInfoInitialData,
  TariffDto,
  TariffName,
  TariffsByMonthsAsset
} from '../../../models';
import { TariffInfo } from '../../../enums';

interface ParsingInfo {
  regExp: RegExp;
  sign: string;
}

interface ParsingInfoList {
  [key: string]: ParsingInfo;
}

export class TariffsHandlerUtilities {
  constructor() {}

  private static tariffInfoForParsing: ParsingInfoList = {
    users: {
      regExp: /u[0-9]+/,
      sign: 'u'
    },
    month: {
      regExp: /m[0-9]+/,
      sign: 'm'
    },
    grains: {
      regExp: /c[0-9]+/,
      sign: 'c'
    },
    price: {
      regExp: /p[0-9]+/,
      sign: 'p'
    },
    exportCredits: {
      regExp: /e[0-9]+/,
      sign: 'e'
    }
  };

  static getTariffByName(name: TariffName): TariffDto {
    // paid tariff name: uX_mXX_cXXX_pXXX. X - users number, XX - month number, XXX - grains, XXXX - price
    const fields: string[] = name?.split('_');
    const tariff: TariffDto = { name, discount: 0 };

    TariffsHandlerUtilities.setTariffByParsedFields(fields, tariff);

    tariff.monthUserPrice = TariffsHandlerUtilities.getMonthUserPrice(tariff);

    return tariff;
  }

  static getNameByTariff(tariff: TariffDto): string {
    if (!tariff) return '';

    const { users, month, grains, price } = tariff;

    return `u${users}_m${month}_c${grains}_p${price}`;
  }

  static getNextTariffMatchInfo(data: NextTariffInfoInitialData): TariffDto {
    const { tariffs, nextTariffParsedInfo, getApproximateTariff = false, nextTariffName } = data;
    let tariff: TariffDto;

    if (getApproximateTariff) {
      tariff = TariffsHandlerUtilities.getApproximateTariff(data);
    }

    if (!tariff) {
      tariff = TariffsHandlerUtilities.getNextTariffByList(
        tariffs,
        nextTariffParsedInfo,
        nextTariffName
      );
    }

    return tariff;
  }

  static isMatchedTariffValid(matchedTariff: TariffDto): boolean {
    return matchedTariff ? matchedTariff.users >= 0 && matchedTariff.month >= 0 : false;
  }

  static getApproximateTariff(data: NextTariffInfoInitialData): TariffDto {
    const { pricingAsset, nextTariffParsedInfo } = data;
    const { month, users } = nextTariffParsedInfo;
    const approximateMonth: number = TariffsHandlerUtilities.getApproximateMonth(
      month,
      pricingAsset
    );
    const approximateTariff: TariffDto = pricingAsset[approximateMonth][users - 1];
    const name: string = TariffsHandlerUtilities.getNameByTariff(approximateTariff);

    return { ...approximateTariff, name };
  }

  static isTariffNotChangeable(currentTariff: TariffDto, nextTariffName: string = ''): boolean {
    const nextTariff: TariffDto = nextTariffName
      ? TariffsHandlerUtilities.getTariffByName(nextTariffName)
      : null;
    const tariffList: TariffDto[] = [currentTariff, nextTariff];

    return tariffList.some((tariff: TariffDto) => {
      return tariff ? TariffsHandlerUtilities.isTariffEnterprise(tariff) : false;
    });
  }

  static isTariffEnterprise(tariff: TariffDto): boolean {
    return tariff.users > TariffInfo.maxUsers || tariff.month > TariffInfo.maxMonth;
  }

  static isTariffFree(tariffName: string): boolean {
    return tariffName === 'free';
  }

  private static getApproximateMonth(month: number, pricingAsset: TariffsByMonthsAsset): number {
    return Object.keys(pricingAsset).reduce((acc: number, monthKey: string) => {
      const itemMonth: number = +monthKey;

      if (!acc && month <= itemMonth) return itemMonth;

      return acc;
    }, 0);
  }

  private static getTariffByMonth(tariffs: TariffDto[], month: number): TariffDto {
    if (!tariffs.length) {
      return null;
    }

    return tariffs.find((tariff: TariffDto) => tariff.month === month);
  }

  private static getNextTariffByList(
    tariffs: TariffDto[],
    parsedTariffInfo: TariffDto,
    nextTariffName: string
  ): TariffDto {
    const tariffFromList: TariffDto = TariffsHandlerUtilities.getTariffByMonth(
      tariffs,
      parsedTariffInfo.month
    );

    if (!tariffFromList) {
      return null;
    }

    const { price, grains, monthUserPrice } = parsedTariffInfo;
    const notShowDiscount: boolean = tariffFromList.price !== parsedTariffInfo.price;
    const discount: number = notShowDiscount ? null : tariffFromList.discount;

    return {
      ...tariffFromList,
      price,
      grains,
      monthUserPrice,
      discount,
      notShowDiscount,
      name: nextTariffName
    };
  }

  private static getMonthUserPrice(tariff: TariffDto): number {
    return tariff.price && tariff.month && tariff.users
      ? Number((tariff.price / tariff.month / tariff.users).toFixed(1))
      : 0;
  }

  private static setTariffByParsedFields(fields: string[], tariff: TariffDto): void {
    const { users, month, grains, price, exportCredits } =
      TariffsHandlerUtilities.tariffInfoForParsing;

    fields.forEach((field: string) => {
      if (field.match(users.regExp)) {
        tariff.users = parseFloat(field.replace(users.sign, '')) || 0;
      } else if (field.match(month.regExp)) {
        tariff.month = parseFloat(field.replace(month.sign, '')) || 0;
      } else if (field.match(grains.regExp)) {
        tariff.grains = parseFloat(field.replace(grains.sign, '')) || 0;
      } else if (field.match(price.regExp)) {
        tariff.price = parseFloat(field.replace(price.sign, '')) || 0;
      } else if (field.match(exportCredits.regExp)) {
        tariff.exportCredits = parseFloat(field.replace(exportCredits.sign, '')) || 0;
      }
    });
  }
}

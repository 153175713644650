export enum CategoryQueryParamEnum {
  byWord = 'byWord',
  bySkill = 'bySkill',
  byEducation = 'byEducation',
  byPosition = 'byPosition',
  byCurrentPosition = 'byCurrentPosition',
  byLocation = 'byLocation',
  byCurrentLocation = 'byCurrentLocation',
  byCompany = 'byCompany',
  byCurrentCompany = 'byCurrentCompany',
  byCertificate = 'byCertificate',
  byName = 'byName',
  byTag = 'byTag',
  byComment = 'byComment'
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Candidate, ContactInfoItem, LinkDto } from '../../../../../shared/models';
import {
  FontsInfo,
  PagesInfoForPdfGeneration
} from '../../../../../shared/models/candidate/candidateCVGenerator';
import { CandidateCVGeneratorService } from '../../../services';

@Component({
  selector: 'app-download-profile-cv-content',
  templateUrl: './download-profile-c-v-content.component.html',
  styleUrls: ['./download-profile-c-v-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CandidateCVGeneratorService]
})
export class DownloadProfileCVContentComponent implements OnInit, OnDestroy {
  constructor(private candidateCVGeneratorService: CandidateCVGeneratorService) {}

  @Input() image: SafeResourceUrl | string;
  @Input() contacts: ReadonlyMap<string, ContactInfoItem>;
  @Input() links: LinkDto[];
  @Input() full = false;
  @Input() startDownloadTime: number;
  @Input() fontsInfo: FontsInfo;

  @Input() set profile(profile: Candidate) {
    this._profile = profile;
  }

  @Output() downloadFinished = new EventEmitter<FontsInfo>();

  @ViewChild('wrapper', { static: false })
  public dataToExportWrapper: ElementRef;
  @ViewChild('dataToExport', { static: false }) public dataToExport: ElementRef;

  private unsubscribe$ = new Subject<void>();

  _profile: Candidate;
  pageNumber = 1;

  ngOnInit(): void {
    this.handleStartGeneratePDF();
  }

  private handleStartGeneratePDF(): void {
    this.candidateCVGeneratorService.startGeneratePDF$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((pageNumber: number) => {
        this.initPdfDownload(pageNumber);
      });
  }

  private initPdfDownload(pageNumber: number): void {
    const downloadPdfDelay = 100;

    setTimeout(() => {
      const info: PagesInfoForPdfGeneration = {
        element: this.dataToExport.nativeElement,
        wrapper: this.dataToExportWrapper.nativeElement,
        pageNumber,
        profileFullName: this._profile.fullName,
        fontsInfo: this.fontsInfo
      };

      this.candidateCVGeneratorService.generatePDF(info).then((fontsInfo: FontsInfo) => {
        this.downloadFinished.emit(fontsInfo);
      });
    }, downloadPdfDelay);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

<div *ngIf="profile" class="profile__header">
  <div
    *ngIf="image && !imageLoadFailed; else noPicture"
    class="profile__media">
    <img
      alt=""
      width="66"
      height="66"
      [src]="image"
      (error)="onError()">

    <img
      alt=""
      width="66"
      height="66"
      class="profile__media-hover"
      [src]="image">
  </div>

  <ng-template #noPicture>
    <div class="profile__media">
      <div class="media-placeholder medium">
        <span class="media-placeholder__text">
          <app-candidate-name
            [name]="profile.fullName"
            [getNameAbbreviation]="true">
          </app-candidate-name>
        </span>
      </div>
    </div>
  </ng-template>

  <div class="candidate-intro">
    <h1 class="main-title">
      {{ profile.fullName | emojiCleaner }}
    </h1>

    <span class="candidate-position">
      {{ profile.headLine | emojiCleaner }}
    </span>

    <span class="secondary-title candidate-location">
      {{ profile.location | location }}
    </span>
  </div>
</div>

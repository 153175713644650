import { ContactExtendedType, ContactType, ItemActionType } from '../../types';
import { CheckBoxData } from '../common';
import { FacetType, FilterCheckboxItem, SpecificFacetType } from '../search';

export interface ContactUserDto {
  contactId?: string;
  contactType: ContactExtendedType;
  contactUserId?: string;
  origin?: string;
  deleted?: boolean;
  dm?: string;
  docId: string;
  notAccurate?: boolean;
  orgId?: string;
  userId?: string;
  value: string;
  hide?: boolean;
}

export interface ContactTypeInfo {
  type: ContactExtendedType;
  label: string;
  active?: boolean;
}

export interface UpdateContactInfo {
  actionType: ItemActionType;
  contact?: ContactUserDto;
  docId?: string;
}

export interface ContactBaseInfo {
  contactType: ContactExtendedType;
  value: string;
}

export interface ContactInfoItem {
  contactType: ContactType;
  values?: ContactUserDto[];
  contactTitle?: string;
  contactUniversalTitle?: string;
}

export interface ProfileContactsQueryParams {
  profileId?: string;
  url?: string;
}

export interface ContactInfo {
  [contact: string]: ContactInfoItem;
}

export type ContactsInfoMap = ReadonlyMap<string, ContactInfoItem>;

export interface ContactFilterItem extends CheckBoxData {
  contactsTypeList?: string[];
  email?: FilterCheckboxItem;
  phone?: FilterCheckboxItem;
  valueForParsing?: string;
  facetType?: FacetType;
  specificFacetType?: SpecificFacetType;
  facet?: number;
}

export type ContactTranslateName =
  | 'CONTACTS.EMAIL'
  | 'CONTACTS.PHONE'
  | 'CONTACTS.TELEGRAM'
  | 'CONTACTS.ICQ'
  | 'CONTACTS.GTALK'
  | 'CONTACTS.SKYPE'
  | 'CONTACTS.WECHAT';

export type ContactFilterType = 'withContacts' | 'noContacts' | 'allContacts';

export type WithContactsFilterType = 'email' | 'phone';

export enum ContactEnum {
  EMAIL = 'email',
  PHONE = 'phone'
}

export enum ContactFilterEnum {
  WITH_CONTACTS = 'withContacts',
  NO_CONTACTS = 'noContacts',
  ALL_CONTACTS = 'allContacts'
}

export enum KeyboardEventCode {
  space = 'Space',
  enter = 'Enter',
  backspace = 'Backspace',
  arrowUp = 'ArrowUp',
  arrowDown = 'ArrowDown',
  arrowRight = 'ArrowRight',
  arrowLeft = 'ArrowLeft',
  delete = 'Delete',
  quote = 'Quote',
  keyZ = 'KeyZ',
  keyV = 'KeyV',
  capsLock = 'CapsLock'
}

export enum KeyboardEventKey {
  shift = 'Shift',
  control = 'Control'
}

import { Component, Input } from '@angular/core';

import { Duration } from '../../../../shared/models';
import { FillingType } from '../../../../shared/types';

@Component({
  selector: 'app-period-range',
  templateUrl: './period-range.component.html',
  styleUrls: ['./period-range.component.scss']
})
export class PeriodRangeComponent {
  constructor() {}

  @Input() duration: Duration;
  @Input() durationType: 'full' | 'short' = 'full';
  @Input() styleType: 'bold' | 'simple' = 'simple';
}

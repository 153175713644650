import { Component, Input } from '@angular/core';

import { ContactInfoItem, LinkDto } from '../../../../../../shared/models';

@Component({
  selector: 'app-download-profile-c-v-sidebar',
  templateUrl: './download-profile-c-v-sidebar.component.html',
  styleUrls: ['./download-profile-c-v-sidebar.component.scss']
})
export class DownloadProfileCVSidebarComponent {
  constructor() {}

  @Input() contactsInfoMap: ReadonlyMap<string, ContactInfoItem>;
  @Input() links: LinkDto[];
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError, mergeMap, takeUntil } from 'rxjs/operators';

import { CandidateSourcesService, WindowService } from '../../shared/services';
import { RestRequestsService } from '../../shared/services/requests';
import { LimitationService } from '../system-shared/services/limitation.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-redirect-link',
  templateUrl: './redirect-link.component.html',
  styleUrls: ['./redirect-link.component.scss']
})
export class RedirectLinkComponent implements OnInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private windowService: WindowService,
    private restRequestsService: RestRequestsService,
    private sourceService: CandidateSourcesService,
    private limitationService: LimitationService
  ) {}

  private unsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    this.handleLink();
  }

  private handleLink(): void {
    this.route.params
      .pipe(
        mergeMap((params: Params) => this.getLink(params)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((link: string) => {
        if (link) {
          const handledHttpLink: string = this.sourceService.getHandledLinkValueWithoutHttp(
            link
          ) as string;

          this.windowService.setLocationHref(handledHttpLink);
        }
      });
  }

  private getLink(params: Params): Observable<string> {
    const linkId: string = params?.link;
    const redirectLink: string = linkId ? `/redirect/link/${linkId}` : '';

    if (redirectLink) {
      return this.restRequestsService
        .sendLink(redirectLink)
        .pipe(
          catchError((error: HttpErrorResponse) =>
            this.limitationService.onRequestLimitCatchError(error, false, true, true)
          )
        );
    }

    return of(null);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

<div class="candidate-tag"
  [class.first-item]="firstItem"
  [class.view-only]="viewOnly"
  [class.rounded]="rounded"
  [class.long]="editing">
  <div class="candidate-tag__content">
    <span class="candidate-tag__value">
      {{ tag?.name }}
    </span>
  </div>

  <div
    *ngIf="!viewOnly"
    class="candidate-tag__action {{ size }}"
    [class.double]="editing">
    <div *ngIf="editing" class="candidate-tag__action-content">
      <svg
        width="12"
        height="12"
        class="edit-folder-status-btn icon"
        (click)="onEdit()">
        <use xlink:href="#edit-pencil"></use>
      </svg>
    </div>

    <div (click)="onRemove()" class="candidate-tag__action-content">
      <svg
        width="10"
        height="10"
        class="icon">
        <use xlink:href="#remove-icon"></use>
      </svg>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class RestRequestsService {
  constructor(private restService: RestService) {}

  sendLink(link: string): Observable<string> {
    return this.restService.Rest().sendLink(link);
  }

  sendImage(link: string): Observable<Blob> {
    return this.restService.Rest().sendImage(link);
  }
}

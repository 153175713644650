<div
  #wrapper
  *ngIf="_profile"
  class="candidate-cv__wrapper"
  appCandidateCvPagesHandler
  [profile]="_profile">
  <div
    #dataToExport
    class="candidate-cv">
    <div class="candidate-cv__content">
      <div class="candidate-cv__content-wrapper">
        <app-download-profile-c-v-header
          class="candidate-header"
          [profile]="_profile"
          [image]="image">
        </app-download-profile-c-v-header>

        <app-download-profile-c-v-main-content
          class="candidate-main-info"
          [profile]="_profile">
        </app-download-profile-c-v-main-content>
      </div>

      <div class="footer footer__content">
        <span class="footer__text"></span>
      </div>
    </div>

    <app-download-profile-c-v-sidebar
      class="candidate-cv__sidebar"
      [contactsInfoMap]="contacts"
      [links]="links">
    </app-download-profile-c-v-sidebar>
  </div>
</div>

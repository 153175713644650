import { SearchRequestInfo, SearchSortType } from '../../../../shared/models';
import { DefaultSearch } from '../../../../shared/enums';
import { SortOrderType } from '../../../../shared/types';

export interface SearchData extends SearchQueriesInfo {
  pageNumber?: number;
  sort?: SearchSortType;
  order?: SortOrderType;
}

export interface SearchQueriesInfo {
  queryList?: string[];
  filterList?: string[];
}

export class SearchUtilities {
  static getSearchRequestInfo(data: SearchData = {}): SearchRequestInfo {
    let { pageNumber, sort, order, queryList, filterList } = data;

    if (!pageNumber) {
      pageNumber = DefaultSearch?.PAGE_NUMBER;
    }

    if (!sort) {
      sort = DefaultSearch.SORT;
    }

    if (!order) {
      order = DefaultSearch.ORDER;
    }

    const info: SearchRequestInfo = { pageNumber, sort, order };

    if (queryList?.length) {
      info.q = queryList;
    }

    if (filterList?.length) {
      info.f = filterList;
    }

    return info;
  }

  static requestInfoForApi(
    requestInfo: SearchRequestInfo = {},
    queriesInfo: SearchQueriesInfo
  ): SearchRequestInfo {
    const searchDataForApi: SearchData = SearchUtilities.getSearchDataForApi(
      requestInfo,
      queriesInfo
    );

    return SearchUtilities.getSearchRequestInfo(searchDataForApi);
  }

  private static getSearchDataForApi(
    requestInfo: SearchRequestInfo = {},
    queriesInfo: SearchQueriesInfo
  ): SearchData {
    let {
      pageNumber = DefaultSearch?.PAGE_NUMBER,
      sort = DefaultSearch.SORT,
      order = DefaultSearch.ORDER
    } = requestInfo;

    return {
      pageNumber,
      sort,
      order,
      ...queriesInfo
    };
  }
}

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { RoutesHandlerService } from '../shared/services';
import { ConditionInfo } from '../shared/models';

@Component({
  selector: 'app-scrollbar',
  templateUrl: './scrollbar.component.html',
  styleUrls: ['./scrollbar.component.scss']
})
export class ScrollbarComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(ElementRef) readonly element: ElementRef<HTMLElement>,
    private routesHandler: RoutesHandlerService,
    private cd: ChangeDetectorRef
  ) {}

  @Input() vertical = true;
  @Input() horizontal = false;

  @Input() size: 'large' | 'standard' | 'small' = 'standard';
  @Input() visibility: 'native' | 'hover' | 'active' = 'native';
  @Input() theme: 'default' | 'light' = 'default';
  @Input() disabled: boolean = false;

  private readonly scrollTrackDebounce = 400;
  private readonly minVerticalThumbHeight = 24;

  private scrolling = false;

  private unsubscribe$ = new Subject<void>();
  private updateScrollingInfo$ = new BehaviorSubject<ConditionInfo>({
    condition: this.scrolling
  });
  private updateTrackScrollingInfo$ = new Subject<null>();

  scrollingInfo$: Observable<ConditionInfo> = this.updateScrollingInfo$.asObservable();
  trackScrollingInfo$: Observable<ConditionInfo> = this.updateTrackScrollingInfo$.asObservable();

  get verticalScrolled(): number {
    const { scrollTop, scrollHeight, clientHeight } = this.element.nativeElement;

    return scrollTop / (scrollHeight - clientHeight);
  }

  get horizontalScrolled(): number {
    const { scrollLeft, scrollWidth, clientWidth } = this.element.nativeElement;

    return scrollLeft / (scrollWidth - clientWidth);
  }

  get verticalPosition(): number {
    return this.verticalScrolled * (100 - this.verticalSize);
  }

  get horizontalPosition(): number {
    return this.horizontalScrolled * (100 - this.horizontalSize);
  }

  get verticalSize(): number {
    const { clientHeight, scrollHeight } = this.element.nativeElement;

    const minThumbHeight = (this.minVerticalThumbHeight / clientHeight) * 100;
    const thumbHeight = Math.ceil((clientHeight / scrollHeight) * 100);
    return Math.max(minThumbHeight, thumbHeight);
  }

  get horizontalSize(): number {
    const { clientWidth, scrollWidth } = this.element.nativeElement;

    return Math.ceil((clientWidth / scrollWidth) * 100);
  }

  get hasVerticalBar(): boolean {
    return this.vertical && !this.disabled && this.verticalSize < 100;
  }

  get hasHorizontalBar(): boolean {
    return this.horizontal && !this.disabled && this.horizontalSize < 100;
  }

  @HostListener('window:resize') onResize(): void {
    this.onScroll();
  }

  @HostListener('scroll') onScroll() {
    this.cd.detectChanges();

    if (this.visibility === 'active') {
      this.updateTrackScrollingInfo$.next(null);
    }
  }

  ngOnInit() {
    this.trackScrollingInfo();
    this.handleRoutesData();
  }

  private trackScrollingInfo(): void {
    if (this.visibility === 'active') {
      this.trackScrollingInfo$
        .pipe(
          tap(() => this.updateScrollingInfo(true)),
          debounceTime(this.scrollTrackDebounce),
          takeUntil(this.unsubscribe$)
        )
        .subscribe(() => {
          this.updateScrollingInfo(false);
        });
    }
  }

  onVertical(scrollTop: number) {
    this.element.nativeElement.scrollTop = scrollTop;
  }

  onHorizontal(scrollLeft: number) {
    this.element.nativeElement.scrollLeft = scrollLeft;
  }

  private handleRoutesData(): void {
    this.routesHandler
      .getRouterEventsAtTheEnd()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.cd.detectChanges();
      });
  }

  private updateScrollingInfo(scrolling: boolean = false): void {
    if (this.scrolling !== scrolling) {
      this.scrolling = scrolling;

      this.updateScrollingInfo$.next({ condition: scrolling });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

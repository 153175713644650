import { Injectable } from '@angular/core';

import { Candidate, LanguageDto } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor() {}

  private readonly translateKey = 'LEVELS';

  setCandidateLangLevels(candidate: Candidate): void {
    if (candidate?.languages?.length) {
      candidate.languages.forEach((lang: LanguageDto, index: number) => {
        if (lang.level) {
          candidate.languages[index].levelLabel = `${this.translateKey}.${lang.level}`;
        }
      });
    }
  }
}
